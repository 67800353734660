import React, { useState, useRef } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import ShareYourDetail from "./CreateProfile2";
import { useDispatch } from "react-redux";
import {
  checkProfileByName,
  createUserProfile,
} from "../Redux/Actions/userProfileAction";
import SimpleReactValidator from "simple-react-validator";
import AddContactDetail from "./CreateProfile3";
import { CircularProgress, InputAdornment, Container } from "@mui/material";

const CreateProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    profile_name: "",
    profile_picture: "",
    first_name: "",
    last_name: "",
    company_name: "",
    position: "",
    industry: "",
    bio_details: "",
  });
  const [step, setStep] = useState(0);
  const validator = useRef(new SimpleReactValidator());
  const [value, setValue] = useState(0);
  const [addressDetails, setaddressDetails] = useState([
    {
      address_type: "",
      street_line_one: "",
      street_line_two: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
    },
  ]);
  const [showImg, setShowImg] = useState("");
  const host = window.location.origin;
  const [btnLoder, setBtnLoader] = useState({ loader: false, err: "" });
  const [emailDetails, setEmailDetails] = useState([
    {
      email: "",
    },
  ]);
  const [websitesDetails, setwebsitesDetails] = useState([
    {
      website: "",
    },
  ]);
  const [phoneDetails, setphoneDetails] = useState([
    {
      country_code: "",
      phone_type: "",
      contact_number: "",
    },
  ]);

  const forceUpdate = () => {
    setValue(value + 1);
  };

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
    setBtnLoader({ ...btnLoder, err: "" });
  };
  const handleNextPage = () => {
    if (
      validator.current.allValid() &&
      (btnLoder.err === "" || !btnLoder.err)
    ) {
      dispatch(
        checkProfileByName(
          formData?.profile_name,
          (err) => setBtnLoader({ ...btnLoder, loader: false, err: err }),
          () => setStep(1)
        )
      );
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const createProfile = (loder) => {
    let fd = new FormData();
    for (let key in formData) {
      fd.append(key, formData[key]);
    }
    if (step === 2) {
      dispatch(
        createUserProfile({
          payload: fd,
          loader: loder,
          callback: () => navigate("/manage-profile"),
        })
      );
    }
  };

  return (
    <div className="">
      <Container>
        {step === 0 && (
          <div className="view-card-area mt-10">
            <div className="create-profile-progress mb-2"></div>
            <div className="">
              <h2 className="profile-card-heading d-flex align-items-center py-4">
                <MdKeyboardBackspace
                  className="me-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/manage-profile")}
                />
                Set your profile link
              </h2>
              <div className="mt-2">
                <div className="mb-4">
                  <TextField
                    className="lkdsfjk"
                    fullWidth
                    id="outlined-required"
                    label="Your profile URL"
                    onChange={(e) =>
                      handleChange("profile_name", e.target.value)
                    }
                    value={formData?.profile_name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment className="me-0" position="start">
                          {host}/
                        </InputAdornment>
                      ),
                    }}
                  />
                  {validator.current &&
                    validator.current.message(
                      "Profile URL",
                      formData?.profile_name,
                      "required|max:50",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                  <span className="text-danger simple-react-validator-errors text-left text-capitalize">
                    {btnLoder.err}
                  </span>
                </div>
              </div>

              <button
                className="primary-btn w-100"
                onClick={() => {
                  btnLoder.err === "" &&
                    validator.current.allValid() &&
                    setBtnLoader({ ...btnLoder, loader: true });
                  handleNextPage();
                }}
              >
                {btnLoder.loader ? (
                  <CircularProgress
                    thickness={6.6}
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "white",
                    }}
                  />
                ) : (
                  "Next"
                )}{" "}
              </button>
            </div>
          </div>
        )}{" "}
        {step === 1 && (
          <ShareYourDetail
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            showImg={showImg}
            setShowImg={setShowImg}
          />
        )}
        {step === 2 && (
          <AddContactDetail
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            createProfile={createProfile}
            addressDetails={addressDetails}
            setaddressDetails={setaddressDetails}
            emailDetails={emailDetails}
            setEmailDetails={setEmailDetails}
            websitesDetails={websitesDetails}
            setwebsitesDetails={setwebsitesDetails}
            phoneDetails={phoneDetails}
            setphoneDetails={setphoneDetails}
          />
        )}
      </Container>
    </div>
  );
};

export default CreateProfile;
