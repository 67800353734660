import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaPencilAlt } from "react-icons/fa";
import {
  MdOutlineAdsClick,
  MdOutlineLocationCity,
  MdOutlineMailOutline,
  MdOutlinePhone,
} from "react-icons/md";
import { CheckWebsiteUrl } from "../../common/functions/checkWebSiteUrl";
import UpDownIcon from "./UpDownIcon";

const EditContactInfo = ({
  handleShowSocialNetworkEditModal,
  activeUserData,
}) => {
  return (
    <div className="d-flex align-items-center jsutify-content-center">
      <UpDownIcon />

      <div className="card mt-4 w-100">
        <div className="d-flex align-items-center justify-content-between mb-5 px-md-3">
          <h2 className="card-heading me-2">Contact Information</h2>
          <button
            className="edit-button"
            onClick={() => handleShowSocialNetworkEditModal("contact")}
          >
            <FaPencilAlt className="me-2" />
            Edit
          </button>
        </div>
        <div className="contact-detail">
          <Row>
            <Col xs={6} sm={3}>
              <div className="text-center mb-3">
                {activeUserData?.phone?.length > 0 ? (
                  <a
                    className="contact-icon-text"
                    href={`tel:${activeUserData?.phone[0]?.country_code}${activeUserData?.phone[0]?.contact_number}`}
                  >
                    <MdOutlinePhone className="contact-icon" />
                    <h5 className="contact-icon-text">Phone</h5>
                  </a>
                ) : (
                  <>
                    <MdOutlinePhone className="contact-icon" />
                    <h5 className="contact-icon-text">Phone</h5>
                  </>
                )}
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="text-center mb-3">
                {activeUserData?.email?.length > 0 ? (
                  <a
                    className="contact-icon-text"
                    href={`mailto:${activeUserData?.email[0]}`}
                  >
                    <MdOutlineMailOutline className="contact-icon" />
                    <h5 className="contact-icon-text">E-mail</h5>
                  </a>
                ) : (
                  <>
                    <MdOutlineMailOutline className="contact-icon" />
                    <h5 className="contact-icon-text">E-mail</h5>
                  </>
                )}
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div
                className="text-center mb-3"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  activeUserData?.website?.length > 0
                    ? window.open(CheckWebsiteUrl(activeUserData?.website[0]))
                    : false
                }
              >
                <MdOutlineAdsClick className="contact-icon" />
                <h5 className="contact-icon-text">Website</h5>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div
                className="text-center mb-3"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  activeUserData?.address?.length > 0
                    ? window.open(
                        `https://www.google.com/maps/place?q=${activeUserData?.address[0].street_line_one} ${activeUserData?.address[0].street_line_two} ${activeUserData?.address[0].city} ${activeUserData?.address[0].state} ${activeUserData?.address[0].postal_code} ${activeUserData?.address[0].country}`
                      )
                    : false
                }
              >
                <MdOutlineLocationCity className="contact-icon" />
                <h5 className="contact-icon-text">Address</h5>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default EditContactInfo;
