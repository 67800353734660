import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import vCard from "vcards-js";

const VCardFileCreator = forwardRef(({ profileData }, ref) => {
  const [phone, setPhone] = useState({
    work: [],
    personal: [],
    other: [],
  });
  const [socialLink, setSocialLinks] = useState([]);
  const [img, setImage] = useState("");
  const [address, setAddress] = useState({
    home: {},
    office: {},
  });
  var vcf = vCard();
  useEffect(() => {
    let work = [];
    let personal = [];
    let other = [];
    let homeAddress = profileData?.address.find(
      (add) => add.address_type === "Home"
    );
    let officeAddress = profileData?.address.find(
      (add) => add.address_type === "Office"
    );
    setAddress({
      home: homeAddress,
      office: officeAddress,
    });
    if (profileData?.phone.length > 0) {
      profileData?.phone.map((ph) => {
        if (ph.phone_type === "work") {
          work = [...work, `${ph.country_code}${ph.contact_number}`];
        } else if (ph.phone_type === "other") {
          other = [...other, `${ph.country_code}${ph.contact_number}`];
        } else {
          personal = [...personal, `${ph.country_code}${ph.contact_number}`];
        }
      });
      setPhone({
        work: work,
        personal: personal,
        other: other,
      });
    }
    if (profileData?.links_set.length > 0) {
      const linkValue = profileData.links_set.filter(
        (lnk) => lnk.types === "social"
      );
      setSocialLinks(linkValue);
    }
    getBase64Image(profileData?.profile_picture, (vl) => {
      setImage(vl);
    });
  }, [profileData]);

  function getBase64Image(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  
  useImperativeHandle(ref, () => ({
    generateVCFFile() {
      //create a new vcf
      //set properties
      vcf.firstName = profileData?.first_name;
      vcf.lastName = profileData?.last_name;
      vcf.organization = profileData?.company_name;
      vcf.photo.url = img?.split(",")[1];
      vcf.photo.base64 = true;
      vcf.photo.mediaType = "jpg";
      vcf.cellPhone = phone.personal;
      vcf.workPhone = phone.work;
      vcf.otherPhone = phone.other;
      vcf.email = profileData?.email;
      vcf.title = profileData?.position;
      vcf.note = profileData?.bio_details.replace(/\s+/g, " ").trim();

      if (address.home && Object.keys(address.home).length > 0) {
        vcf.homeAddress.label = address.home?.address_type;
        vcf.homeAddress.street = address.home?.street_line_one;
        vcf.homeAddress.city = address.home?.city;
        vcf.homeAddress.stateProvince = address.home?.state;
        vcf.homeAddress.postalCode = address.home?.postal_code;
        vcf.homeAddress.countryRegion = address.home?.country;
      }

      if (address.office && Object.keys(address.office).length > 1) {
        vcf.workAddress.label = address.office?.address_type;
        vcf.workAddress.street = address.office?.street_line_one;
        vcf.workAddress.city = address.office?.city;
        vcf.workAddress.stateProvince = address.office?.state;
        vcf.workAddress.postalCode = address.office?.postal_code;
        vcf.workAddress.countryRegion = address.office?.country;
      }
      socialLink.map((lnk) => (vcf.socialUrls[lnk.provider.title] = lnk.url));
      let vCardString = vcf.getFormattedString();
      vCardString = vCardString.replace(
        /SOCIALPROFILE;CHARSET=UTF-8;/gm,
        "SOCIALPROFILE;"
      );
      vcf.version = "3.0";

      const linkElement = document.createElement("a");
      linkElement.setAttribute("href", `data:,${vCardString}`);
      linkElement.setAttribute(
        "download",
        `${profileData?.first_name}${profileData?.last_name}12.vcf`
      );
      linkElement.style.display = "none";
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);
    },
  }));
});
export default VCardFileCreator;
