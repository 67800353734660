import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CardWithImage from "../cardView/CardWithImage";
import CardWithoutImage from "../cardView/CardWithoutImage";
import { useDispatch } from "react-redux";
import {
  getActiveUserProfile,
  updateLinksPosition,
} from "../../Redux/Actions/userProfileAction";

export default function DragNDrop({
  selectedLinks,
  setEditLinkValue,
  title,
  setShowModal,
  setLoading,
}) {
  const dispatch = useDispatch();
  const [linkList, setLinkList] = useState([]);
  useEffect(() => {
    setLinkList(selectedLinks);
  }, [selectedLinks]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(linkList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let payload = {
      link1: +result.draggableId,
      link2: linkList[result.destination.index].id,
      link_type: title,
    };
    if (payload.link1 !== payload.link2) {
      setLoading(true);
      dispatch(
        updateLinksPosition({
          payload,
          loader: () => {
            setLoading(false);
          },
          callback: () => {
            dispatch(
              getActiveUserProfile(() => {
                setLoading(false);
              })
            );
          },
        })
      );
    }
    setLinkList(items);
  }

  return (
    <>
      {!selectedLinks || selectedLinks?.length === 0 ? (
        <div className="me-2 nodata-found">No Links Found</div>
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="channels">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {linkList?.map((link, index) => {
                  return (
                    <Draggable
                      key={link.id}
                      draggableId={`${link.id}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="card-2 mb-3 "
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {title !== "website" ? (
                            <CardWithImage
                              link={link}
                              setEditLinkValue={setEditLinkValue}
                              setShowModal={setShowModal}
                            />
                          ) : (
                            <CardWithoutImage
                              link={link}
                              index={index}
                              editWebsiteLink={setEditLinkValue}
                              setShowModal={setShowModal}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
}
