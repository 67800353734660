import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

function DeleteModal({ show, setShow, handleDeleteProfile, modalBtnLoader, setModalBtnLoader }) {
  return (
    <>
    <div className="mx-3">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={show}
        // onClose={() => setShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Box className="delete-modal-box">
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Are you sure.
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Do you really want to delete this record? This process cannot be
              undone.
            </Typography>
            <div className="text-center">
              <div className="d-flex align-items-center justify-content-center mt-3">
                <button
                  className="btn btn-info rounded-pill px-4 m-3 confirm-btn"
                  type="submit"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger rounded-pill px-4 confirm-btn "
                  type="submit"
                  disabled={modalBtnLoader}
                  onClick={()=>{
                    setModalBtnLoader(true)
                    handleDeleteProfile()}}
                >
                  {modalBtnLoader ? (
                    <CircularProgress
                      thickness={6.6}
                      style={{
                        width: "14px",
                        height: "14px",
                        color: "white",
                      }}
                    />
                  ) : 
                    'Delete'
                  }
                  {/* {" "}
                  Delete */}
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
    </>
  );
}

export default DeleteModal;
