import React, { useRef, useState } from "react";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { API } from "../config";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { Link, useLocation } from "react-router-dom";
import { PASSWORD_REGEX } from "../regexValidation/RegexValidation";
import { errorMessageAutoCloseOff } from "../common/showToasterMessage";

const ResetPassword = () => {
  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        regex:
          "A minimum 8 characters password contains a combination of uppercase, lowercase letter, special character and number",
      },
    })
  );
  const [resetForm, SetResetForm] = useState({
    password: "",
    confirm_password: "",
  });

  const [errMsg, setErrMsg] = useState(false);
  const [value, setValue] = useState(0);
  const location = useLocation();
  const [btnLoader, setBtnLoader] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [conPassword, setConPassword] = React.useState(false);

  const handleClickConPassword = () => setConPassword((show) => !show);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  let token = location.search.split("=");
  token = token[token.length - 1];
  const forceUpdate = () => {
    setValue(value + 1);
  };

  const loginUser = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      if (resetForm.password !== resetForm.confirm_password) {
        setErrMsg(true);
        return;
      }
      setErrMsg(false);
      let formData = {
        password: resetForm.confirm_password,
        token: token,
      };
      setBtnLoader(true);
      toast.dismiss();
      API.post("forget-password/confirm/", formData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Password reset successfully.");
            setTimeout(() => {
              window.location.href = "/login";
              setBtnLoader(false);
            }, 1500);
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            errorMessageAutoCloseOff(err.response.data.password[0]);
          } else if (err.response.status === 404) {
            errorMessageAutoCloseOff("Invalid token.");
          } else {
            toast.error("Something went wrong.");
          }
          setBtnLoader(false);
        });
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "confirm_password") {
      e.target.value === resetForm.password
        ? setErrMsg(false)
        : setErrMsg(true);
    }
    if (e.target.name === "password" && resetForm.confirm_password !== "") {
      e.target.value === resetForm.confirm_password
        ? setErrMsg(false)
        : setErrMsg(true);
    }
    SetResetForm({
      ...resetForm,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="login-wrap px-3">
      <div className="login-page">
        <div className="text-center">
          <img
            className="site-logo-main mb-5"
            src="/images/Logo.svg"
            alt="logo"
          />
          <h1 className="login-title py-5">Reset Password</h1>
        </div>
        <form onSubmit={loginUser} className="">
          <div className="mb-4">
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                onChange={handleChange}
                label="Password"
                name="password"
                value={resetForm.password}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {/* <MdVisibility /> */}
                      {showPassword ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {validator.current &&
              validator.current.message(
                "password",
                resetForm.password,
                [
                  "required",
                  {
                    regex: PASSWORD_REGEX,
                  },
                ],
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )}
          </div>
          <div className="mb-4">
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="con">Confirm Password</InputLabel>
              <OutlinedInput
                id="con"
                onChange={handleChange}
                label="Confirm Password"
                name="confirm_password"
                type={conPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickConPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {/* <MdVisibility /> */}
                      {conPassword ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {/* {validator.current &&
              validator.current.message(
                "confirm_password",
                resetForm.password,
                [
                  "required",
                  {
                    regex:
                      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/g,
                  },
                ],
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )} */}
            {errMsg && (
              <span className="text-danger simple-react-validator-errors text-left">
                Password and confirm password does not match
              </span>
            )}
          </div>
          <p className="forgotlink">
            <Link to="/login">Back to login</Link>
          </p>
          <button disabled={btnLoader} type="submit" className="login-btn mt-4">
            {btnLoader ? (
              <CircularProgress
                thickness={6.6}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "white",
                }}
              />
            ) : (
              "Reset"
            )}
          </button>
        </form>
        {/* <div className="text-center mt-5">
            <p>
              No account?
              <a className="ms-2" href="">
                <strong>Get Your Card</strong>
              </a>
            </p>
          </div> */}
      </div>
    </div>
  );
};

export default ResetPassword;
