import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { MdKeyboardBackspace } from "react-icons/md";
import { Backdrop, CircularProgress, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotification,
  updateNotification,
} from "../Redux/Actions/notificationAction";
import ProfileViewProfessional from "./ProfileViewProfessional";
import ProfileViewClassic from "./ProfileViewClassic";
import { getActiveUserProfile } from "../Redux/Actions/userProfileAction";

const AccountSettingPersonalise = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [changed, setChanged] = useState(false);

  const activeUserProfile = useSelector(
    (state) => state.userProfileReducer.activeUserProfile
  );

  let userId = JSON.parse(localStorage.getItem("settingId"));
  const [formValues, setFormValues] = useState({
    
  });

  const currencies = [
    {
      value: "professional",
      label: "Professional",
    },
    {
      value: "classic",
      label: "Classic",
    },
  ];
  const [isinput, setIsinput] = useState(false);

  const handleSubmit = () => {
    if (!isinput) {
      return;
    }
    setBtnLoader(true);
    dispatch(
      updateNotification(formValues, userId, () => {setBtnLoader(false); navigate("/settings")})
    );
  };

  const handleChange = (key, value) => {
    setChanged(true);
    setFormValues({ ...formValues, [key]: value });
    setIsinput(true);
  };

  useEffect(() => {
    dispatch(
      getNotification(
        userId,
        (e) => {
         
          setFormValues({
            ...formValues,
            theme: e.theme,
            theme_color: e.theme_color,
          });
        },
        () => setLoading(false)
      )
    );
    dispatch(getActiveUserProfile(() => setLoading(false)));
  }, [dispatch]);

  let colors = [
    { bgColor: "bg-3F3F3F", value: "#023458" },
    { bgColor: "bg-3B55E6", value: "#3B55E6" },
    { bgColor: "bg-FF5280", value: "#FF5280" },
    { bgColor: "bg-92CAC8-3E9A97", value: "#92CAC8" },
    { bgColor: "bg-710D6B", value: "#710D6B" },
    { bgColor: "bg-F2DAF4-D4F0FB", value: "#F2DAF4" },
    { bgColor: "bg-338A3D", value: "#338A3D" },
    { bgColor: "bg-10AFE1", value: "#10AFE1" },
    { bgColor: "bg-FC6D26", value: "#FC6D26" },
    { bgColor: "bg-A033FF", value: "#A033FF" },
    { bgColor: "bg-FFFC00", value: "#FFFC00" },
    { bgColor: "bg-E70023", value: "#E70023" },
  ];

  return (
    <>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className="mt-10">
          <Container>
            <Row>
              {/* Cards Section */}
              <Col md={6}>
                <div className="mx-md-5">
                  <h2 className="profile-card-heading d-flex align-items-center">
                    <MdKeyboardBackspace
                      className="me-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/settings")}
                    />
                    Personalization
                  </h2>
                  <div className="change-color-card mt-3">
                    <div className="mb-4">
                      <TextField
                        id="outlined-select-currency"
                        select
                        fullWidth
                        name="theme"
                        label="Select Theme"
                        value={formValues.theme}
                        onChange={(event) =>
                          handleChange(event.target.name, event.target.value)
                        }
                      >
                        {currencies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>

                    {formValues.theme === "professional" ? (
                      <ProfileViewProfessional
                        profileData={activeUserProfile}
                        bgColor={formValues?.theme_color}
                        remove={true}
                        textColor={
                          formValues?.theme_color === "#92CAC8" ||
                          formValues?.theme_color === "#F2DAF4" ||
                          formValues?.theme_color === "#FFFC00"
                            ? "black"
                            : "white"
                        }
                      />
                    ) : (
                      <ProfileViewClassic
                        profileData={activeUserProfile}
                        bgColor={formValues?.theme_color}
                        remove={true}
                        textColor={
                          formValues?.theme_color === "#92CAC8" ||
                          formValues?.theme_color === "#F2DAF4" ||
                          formValues?.theme_color === "#FFFC00"
                            ? "black"
                            : "white"
                        }
                      />
                    )}
                  </div>
                </div>
              </Col>
              {/* Colors Section */}
              <Col md={6}>
                <div className="">
                  <h2 className="profile-card-heading d-flex align-items-center py-4">
                    Personalization
                  </h2>
                  <ul className="m-0 p-0">
                    {colors.map((item,i) => {
                      return (
                        <li
                          onClick={() =>
                            handleChange("theme_color", item.value)
                          }
                          className={`change-color cursor-pointer ${
                            item.bgColor
                          } ${
                            formValues?.theme_color === item.value && "active"
                          }`}
                          key={`${item.value}${i}`}
                        ></li>
                      );
                    })}
                  </ul>
                </div>
              </Col>
            </Row>
            <div className=" d-flex justify-content-center w-100  py-5">
              <button
                onClick={handleSubmit}
                disabled={!changed || btnLoader}
                className="edit-button text-center w-50 justify-content-center"
              >
                {btnLoader ? (
                  <CircularProgress
                    thickness={6.6}
                    style={{
                      width: "30px",
                      height: "29px",
                      color: "white",
                    }}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default AccountSettingPersonalise;
