import React from "react";
import LinkImage from "./LinkImage";

const ProfessionalViewLinkCard = ({
  reviewItem,
  website,
  color,
  textColor,
  heading,
}) => {
  return (
    <div className="">
      <div className="mt-4 w-100">
        <div className="mb-3">
          <h2 className="card-heading">{heading}</h2>
        </div>
        <div className="d-flex flex-wrap">
          {reviewItem?.map((item) => {
            return (
              <LinkImage
                item={item}
                website={website}
                color={color}
                textColor={textColor}
                key={item.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProfessionalViewLinkCard;
