import AccountSetting from "./Pages/AccountSetting";
import ChangeCredentials from "./Pages/ChangeCredentials";
import CreateProfile from "./Pages/CreateProfile1";
import ManageProfile from "./Pages/ManageProfile";
import Notifications from "./Pages/Notification";
import ProfileEditView from "./Pages/ProfileEditView";
import SimpleCollapse from "./Pages/SimpleCollapse";
import ContactDetails from "./Pages/ContactDetails";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import AccountSettingPersonalise from "./Pages/AccountSettingPersonalise";
import PageNotFound from "./Pages/PageNotFound";
import GetCard from "./Pages/GetCard";
import GetProfile from "./Pages/GetProfile";
import Signup from "./Pages/Signup";
import ResetPassword from "./Pages/ResetPassword";

export const route = [
  {
    path: "/profile-edit-view",
    element: <ProfileEditView />,
    private: true,
  },
  {
    path: "/:name",
    element: <GetProfile />,
    private: false,
    public: true,
  },
  {
    path: "/card/:id",
    element: <GetCard />,
    private: false,
    public: true,
  },
  {
    path: "/manage-profile",
    element: <ManageProfile />,
    private: true,
  },
  {
    path: "/create-profile",
    element: <CreateProfile />,
    private: true,
  },
  {
    path: "/connections",
    element: <ContactDetails />,
    private: true,
  },
  // {
  //   path: "/simple-collapse",
  //   element: <SimpleCollapse />,
  //   private: true,
  // },
  {
    path: "/settings",
    element: <AccountSetting />,
    private: true,
  },
  {
    path: "/change-credentials",
    element: <ChangeCredentials />,
    private: true,
  },
  {
    path: "/notification",
    element: <Notifications />,
    private: true,
  },
  {
    path: "/login",
    element: <Login />,
    private: false,
  },
  {
    path: "/",
    element: <Login />,
    private: false,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    private: false,
  },
  {
    path: "/personalise",
    element: <AccountSettingPersonalise />,
    private: true,
  },
  {
    path: "/signup/:id",
    element: <Signup />,
    private: false,
  },
  {
    path: "/auth/password-reset/",
    element: <ResetPassword/>,
    private: false
  },
  {
    path: "/404",
    element: <PageNotFound />,
    private: false,
    public: true,
  }
];
