import React, {
  cloneElement,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import copy from "copy-to-clipboard";
import { Container, Row, Col } from "react-bootstrap";

import { FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  MdOutlineRemoveRedEye,
  MdContacts,
  MdQrCode,
  MdOutlineShare,
  MdKeyboardBackspace,
} from "react-icons/md";
import MuiDrawer from "../common/ComonDrawer";
import MuiAboutDrawer from "../common/AboutEditModal";
import MuiSocialNetworkDrawer from "../common/SocialNetworkEditModal";
import {
  getQRCode,
  getActiveUserProfile,
  getUsers,
} from "../Redux/Actions/userProfileAction";
import { SlArrowLeftCircle, SlArrowRightCircle } from "react-icons/sl";
import {
  Backdrop,
  Box,
  CircularProgress,
  Modal,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import exportAsImage from "../common/ExportImage";
import EditAbout from "./editProfileView/EditAbout";
import EditSocialNetwork from "./editProfileView/EditSocialNetwork";
import EditReviewLinks from "./editProfileView/EditReviewLinks";
import EditWebsiteLinks from "./editProfileView/EditWebsiteLinks";
import EditVideo from "./editProfileView/EditVideo";
import EditContactInfo from "./editProfileView/EditContactInfo";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { authAxios } from "../config";
import { toast } from "react-toastify";

const ProfileEditView = () => {
  const exportRef = useRef();
  const navigate = useNavigate();
  const [showProfileEditModal, setShowProfileEditModal] = useState(false);
  const [showAboutEditModal, setShowAboutEditModal] = useState(false);
  const [showSocialNetworkEditModal, setShowSocialNetworkEditModal] =
    useState(false);
  const [activeModalName, setActiveModalName] = useState(undefined);
  const [activeUserData, setActiveUserData] = useState({});
  const [next, setNext] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sectionLoading, setSectionLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [aboutUs, setAboutUs] = useState();
  const [showAnalytics, setShowAnalytics] = useState("one_month_count");
  const host = window.location.origin;
  const [openSnickBar, setOpenSnickBar] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(
    (state) => state.userProfileReducer.activeUserProfile
  );
  const [editSection, setEditSection] = useState([]);
  const userProfile = useSelector((state) => state.userProfileReducer.user);

  const qr_code = useSelector((state) => state.userProfileReducer.qr_code);
  const filteredWebsite = activeUserData?.links_set?.filter((item) => {
    return item.types === "website";
  });
  const filteredNetworks = activeUserData?.links_set?.filter((item) => {
    return item.types === "social";
  });
  const filteredReviews = activeUserData?.links_set?.filter((item) => {
    return item.types === "review";
  });

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setActiveUserData(userData);
    }
  }, [userData]);
  const profileID = activeUserData?.id;

  useEffect(() => {
    if (profileID) {
      const sectionItem = [
        {
          id: "about me",
          com: (
            <EditAbout
              handleShowAboutEditModal={handleShowAboutEditModal}
              activeUserData={activeUserData}
              // changePosition={changePosition}
            />
          ),
        },
        {
          id: "social links",
          com: (
            <EditSocialNetwork
              handleShowSocialNetworkEditModal={
                handleShowSocialNetworkEditModal
              }
              filteredNetworks={filteredNetworks}
              activeUserData={activeUserData}
              // changePosition={changePosition}
            />
          ),
        },
        {
          id: "review links",
          com: (
            <EditReviewLinks
              handleShowSocialNetworkEditModal={
                handleShowSocialNetworkEditModal
              }
              filteredReviews={filteredReviews}
              // changePosition={changePosition}
            />
          ),
        },
        {
          id: "website links",
          com: (
            <EditWebsiteLinks
              filteredWebsite={filteredWebsite}
              handleShowSocialNetworkEditModal={
                handleShowSocialNetworkEditModal
              }
              activeUserData={activeUserData}
              // changePosition={changePosition}
            />
          ),
        },
        {
          id: "videos",
          com: (
            <EditVideo
              handleShowSocialNetworkEditModal={
                handleShowSocialNetworkEditModal
              }
              activeUserData={activeUserData}
              userData={userData}
              // changePosition={changePosition}
            />
          ),
        },
        {
          id: "contact information",
          com: (
            <EditContactInfo
              handleShowSocialNetworkEditModal={
                handleShowSocialNetworkEditModal
              }
              activeUserData={activeUserData}
              // changePosition={changePosition}
            />
          ),
        },
      ];
      let postionValue = [];
      authAxios
        .get(`/user/profile-cards-postion/${activeUserData?.id}/`)
        .then((res) => {
          postionValue = res.data.data;
          postionValue.sort((a, b) => a.position - b.position);
          const cloneSectionItem = [];
          postionValue.forEach((element) => {
            let i = sectionItem.findIndex((itm) => itm.id === element.name);
            cloneSectionItem.push(sectionItem[i]);
          });
          setEditSection(cloneSectionItem);
        })
        .catch((err) => {
          setEditSection(sectionItem);
        });
    }
  }, [activeUserData]);
  useEffect(() => {
    dispatch(
      getActiveUserProfile(() => dispatch(getUsers(() => setLoading(false))))
    );
  }, [dispatch]);

  const updateSectionPosition = (payload) => {
    setSectionLoading(true);
    authAxios
      .patch(`user/profile-cards-postion/${activeUserData?.id}/`, {
        data: payload,
      })
      .then((res) => {
        setSectionLoading(false);
      })
      .catch((err) => {
        setSectionLoading(false);
        toast.dismiss();
        // toast.error("Something went wrong");
      });
  };
  const handleShowProfileEditModal = () => {
    setShowProfileEditModal(!showProfileEditModal);
  };
  const handleShowAboutEditModal = () => {
    setShowAboutEditModal(!showAboutEditModal);
    setAboutUs(userData?.bio_details);
  };
  const handleShowSocialNetworkEditModal = (selectedModalName) => {
    setActiveModalName(selectedModalName);
    setShowSocialNetworkEditModal((prev) => !prev);
  };

  const [open, setOpen] = React.useState(false);

  const shareProfile = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openqr, setOpenQr] = useState(false);
  const downloadQRCode = () => {
    dispatch(getQRCode(activeUserData.id));
    setOpenQr(true);
  };
  const handleCloseQr = () => {
    setOpenQr(false);
    setNext(false);
  };

  const shareProfleLink = () => {
    copy(host + "/" + activeUserData?.profile_name);
    setOpenSnickBar(true);
  };

  const getActiveUserData = (timeFrame) => {
    setShowAnalytics(timeFrame);
    dispatch(getActiveUserProfile(() => dispatch(() => setLoading(false))));
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(editSection);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const positionId = [];
    const positionArray = [];
    if (result.draggableId !== editSection[result.destination.index].id) {
      items.forEach((element, i) => {
        positionId.push(element.id);
        positionArray.push({ name: element.id, position: i + 1 });
      });
      updateSectionPosition(positionArray);
      setEditSection(items);
    }
  }

  return (
    <>
      {sectionLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={sectionLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : activeUserData.is_active ? (
        <div className="py-4 mt-3">
          <Container>
            <Row>
              <Col className="mb-4" lg={6} md={12}>
                <div className="mx-md-4">
                  <div className="d-flex align-items-center jsutify-content-center">
                    <div className="me-5"></div>
                    <div className="edit-profile-pic w-100">
                      <div className="view-profile-pic">
                        {/* <img src="/images/Union.png" alt="profile-pic" /> */}
                        <div className="back-vpp"></div>
                        <img
                          src={
                            activeUserData?.profile_picture
                              ? activeUserData?.profile_picture
                              : "/images/dummyUser.jpg"
                          }
                          alt="profile-pic"
                        />
                      </div>
                      <div className="profile-name">
                        <h4 className="profile-user-name">
                          {activeUserData?.first_name}{" "}
                          {activeUserData?.last_name}
                        </h4>
                        <p className="profile-user-designation m-0">
                          {activeUserData?.position} at
                          <br /> {activeUserData?.company_name}
                        </p>
                      </div>
                      <button
                        className="edit-button vpc-btn"
                        onClick={handleShowProfileEditModal}
                      >
                        <FaPencilAlt className="me-2" />
                        Edit Profile
                      </button>
                    </div>
                  </div>
                  {/* For applying the drag and drop functionality in local comment out the strict mode  */}
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="channels">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {editSection?.map((edt, i) => (
                            <Draggable
                              key={edt.id}
                              draggableId={edt.id}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Fragment key={i}>
                                    {cloneElement(edt.com, {
                                      index: i,
                                      editSection: editSection,
                                    })}
                                  </Fragment>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </Col>
              <Col md={12} lg={6}>
                <div className="mb-1 fs-bold d-flex justify-content-between align-items-center time-frame-head">
                  <div>
                    <span>ANALYTICS</span>
                  </div>
                  <div>
                    <p>
                      <span
                        onClick={() => getActiveUserData("one_month_count")}
                        className={`cursor-pointer time-frame-btn ${
                          showAnalytics === "one_month_count" && "active"
                        }`}
                      >
                        1M
                      </span>
                      <span
                        onClick={() => getActiveUserData("six_month_count")}
                        className={`cursor-pointer time-frame-btn ${
                          showAnalytics === "six_month_count" && "active"
                        }`}
                      >
                        6M
                      </span>
                      <span
                        onClick={() => getActiveUserData("one_year_count")}
                        className={`cursor-pointer time-frame-btn ${
                          showAnalytics === "one_year_count" && "active"
                        }`}
                      >
                        1Y
                      </span>
                      <span
                        onClick={() => getActiveUserData("two_year_count")}
                        className={`cursor-pointer time-frame-btn ${
                          showAnalytics === "two_year_count" && "active"
                        }`}
                      >
                        2Y
                      </span>
                      <span
                        onClick={() => getActiveUserData("total_count")}
                        className={`cursor-pointer time-frame-btn ${
                          showAnalytics === "total_count" && "active"
                        }`}
                      >
                        All time
                      </span>
                    </p>
                  </div>
                </div>
                <div className="analytics-area">
                  <Row>
                    <Col className="mb-3" sm={12} md={6} lg={4}>
                      <div className="card h-100">
                        <div className="d-flex align-items-center">
                          <MdOutlineRemoveRedEye className="analytics-card-icon" />
                          <h6 className="ps-2 analtics-card-title">
                            Profile Views
                          </h6>
                        </div>
                        <p className="analytics-card-count mt-3">
                          {activeUserData?.analytics_set?.profile_views
                            ? activeUserData?.analytics_set?.profile_views[
                                showAnalytics
                              ]
                            : 0}
                        </p>
                      </div>
                    </Col>

                    <Col className="mb-3" sm={12} md={6} lg={4}>
                      <div className="card h-100">
                        <div className="d-flex align-items-center">
                          <img
                            className="analytics-card-icon"
                            src="/images/exchange.svg"
                            alt=""
                          />
                          <h6 className="ps-2 analtics-card-title">
                            Exchanged Contacts
                          </h6>
                        </div>
                        <p className="analytics-card-count mt-3">
                          {activeUserData?.analytics_set?.exchanged_contacts
                            ? activeUserData?.analytics_set?.exchanged_contacts[
                                showAnalytics
                              ]
                            : 0}
                        </p>
                      </div>
                    </Col>
                    <Col className="mb-3" sm={12} md={6} lg={4}>
                      <div
                        className="card h-100 cursor-pointer"
                        onClick={() => navigate("/connections")}
                      >
                        <div className="d-flex align-items-center">
                          <MdContacts className="analytics-card-icon" />
                          <h6 className="ps-2 analtics-card-title">
                            Saved Contacts
                          </h6>
                        </div>
                        <p className="analytics-card-count mt-3">
                          {activeUserData?.analytics_set?.saved_contacts
                            ? activeUserData?.analytics_set?.saved_contacts[
                                showAnalytics
                              ]
                            : 0}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-4">
                    <button
                      onClick={() =>
                        window.open(`/${activeUserData?.profile_name}`)
                      }
                      className="secondary-btn mx-1 mb-3"
                    >
                      Public View
                    </button>
                    <button className="primary-btn mx-1" onClick={shareProfile}>
                      Share Your Profile
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Modal
            // hideBackdrop
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box className="share-profile-modal">
              <h2 className="modal-title mb-3" id="modal-title">
                Share Your Profile
              </h2>
              <Snackbar
                message="Copied to clibboard"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={500}
                onClose={() => setOpenSnickBar(false)}
                open={openSnickBar}
              />
              <div className="" id="modal-description">
                <div className="card-2 mb-3 cursor-pointer">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    onClick={shareProfleLink}
                  >
                    <h4 className="social-n-title me-3">Share Profile Link</h4>
                    <span className="">
                      <MdOutlineShare className="m-share-icon" />
                    </span>
                  </div>
                </div>
                <div
                  className="card-2 mb-3 cursor-pointer"
                  onClick={downloadQRCode}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="social-n-title me-3">
                      Download Your QR Code
                    </h4>
                    <span className="">
                      <MdQrCode className="m-share-icon" />
                    </span>
                  </div>
                </div>
                <div className="card-2 mb-3 d-none cursor-pointer">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="social-n-title me-3">Email Signature</h4>
                    <span className="">
                      <img
                        className="m-share-icon"
                        src="/images/mark_email_read.svg"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              </div>
              <button
                className="secondary-btn w-100 mt-5"
                onClick={handleClose}
              >
                Close
              </button>
            </Box>
          </Modal>

          {/* qr modal */}
          <Modal
            // hideBackdrop
            open={openqr}
            onClose={handleCloseQr}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box className="share-profile-modal">
              <h2
                className="modal-title d-flex align-items-center mb-3"
                id="modal-title"
              >
                <MdKeyboardBackspace
                  className="me-3 cursor-pointer"
                  onClick={handleCloseQr}
                />
                QR Code
              </h2>
              <div className="" id="modal-description">
                <div className="qr-card">
                  {next ? (
                    <div ref={exportRef}>
                      <div className="qr-second-img p-1">
                        <img
                          className="qr-code-2 avatar_imgs"
                          src={`${activeUserData.profile_picture}`}
                          // src="/images/dummyUser.jpg"
                          alt={activeUserData?.name}
                          style={{ objectFit: "cover" }}
                        />
                      </div>

                      <div className="card p-2">
                        <div className="text-center py-2">
                          <h4 className="qr-card-name">
                            {activeUserData?.first_name}{" "}
                            {activeUserData?.last_name}
                          </h4>
                          <p className="qr-card-designation">
                            {activeUserData?.position}
                            <span> at </span> {activeUserData?.company_name}{" "}
                          </p>
                        </div>
                        <p className="scan-conect">Scan & Connect</p>
                        <img
                          className="qr-code"
                          src={`data:image/png;base64,${qr_code}`}
                          alt="qr-code"
                        />
                        <p className="pfofile-uid">
                          @{activeUserData?.profile_name}{" "}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="card p-2">
                      <p className="scan-conect">Scan & Connect</p>
                      <img
                        className="qr-code"
                        src={`data:image/png;base64,${qr_code}`}
                        alt=""
                      />
                      <p className="pfofile-uid">
                        @{activeUserData?.profile_name}{" "}
                      </p>
                    </div>
                  )}

                  <div className="qr-next-pre d-flex align-items-center justify-content-around my-4">
                    <SlArrowLeftCircle
                      onClick={() => setNext(false)}
                      className={`${next && "active"} cursor-pointer`}
                    />
                    <SlArrowRightCircle
                      onClick={() => setNext(true)}
                      className={`${!next && "active"} cursor-pointer`}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                {next ? (
                  <button
                    title="ImageName"
                    onClick={() => {
                      setBtnLoader(true);
                      exportAsImage(exportRef.current, "Card", () =>
                        setBtnLoader(false)
                      );
                    }}
                    disabled={btnLoader}
                    className="primary-btn w-100 text-light d-inline-block"
                  >
                    {btnLoader ? (
                      <CircularProgress
                        thickness={6.6}
                        style={{
                          marginTop: "3.8px",
                          width: "20px",
                          height: "20px",
                          color: "white",
                        }}
                      />
                    ) : (
                      "Download"
                    )}
                  </button>
                ) : (
                  <a
                    download={`${activeUserData?.first_name}.jpg`}
                    href={`data:image/png;base64,${qr_code}`}
                    title="ImageName"
                    className="primary-btn w-100 text-light d-inline-block"
                  >
                    Download
                  </a>
                )}
              </div>
            </Box>
          </Modal>

          {showProfileEditModal && (
            <MuiDrawer
              open={showProfileEditModal}
              activeModal={"about me"}
              handleShowProfileEditModal={handleShowProfileEditModal}
              setShowProfileEditModal={setShowProfileEditModal}
              userData={activeUserData}
            />
          )}
          <MuiAboutDrawer
            open={showAboutEditModal}
            handleShowAboutEditModal={handleShowAboutEditModal}
            userData={activeUserData}
            setAboutUs={setAboutUs}
            aboutUs={aboutUs}
          />
          {showSocialNetworkEditModal && (
            <MuiSocialNetworkDrawer
              open={showSocialNetworkEditModal}
              activeModalName={activeModalName}
              handleShowSocialNetworkEditModal={
                handleShowSocialNetworkEditModal
              }
              setShowSocialNetworkEditModal={setShowSocialNetworkEditModal}
              userData={activeUserData}
            />
          )}
        </div>
      ) : userProfile.length > 0 ? (
        navigate("/manage-profile")
      ) : (
        navigate("/create-profile")
      )}
    </>
  );
};

export default ProfileEditView;
