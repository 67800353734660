import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { MdContacts } from "react-icons/md";
import ShowMoreText from "react-show-more-text";

import ShareProfileModal from "../common/ShareProfileModal";
import axios from "axios";
import { axiosWithoutAuth, baseURL } from "../config";
import VCardFileCreator from "./CreateVcardFile";
import { toast } from "react-toastify";
import ProfessionalViewLinkCard from "../common/linkView/ProfessionalViewLinkCard";
import VideoContent from "../common/video";
import { GET_YOUR_CARD_LINK } from "../constants/constants";
import ProfessionalViewContactContainer from "./publicViewContainer/ProfessionalViewContactContainer";
import { useSelector } from "react-redux";

const ProfileViewProfessional = ({
  profileData,
  bgColor,
  textColor,
  remove,
}) => {
  const [opensharedetail, setOpenShaeDetail] = useState(false);
  const childRef = useRef();
  const [btnLoader, setBtnLoader] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [editSection, setEditSection] = useState([]);
  const shareDetail = () => {
    setOpenShaeDetail(true);
  };
  const color = bgColor ? bgColor : "#1C1B1F";
  const filteredWebsite = profileData?.links_set?.filter((item) => {
    return item.types === "website";
  });
  const filteredNetworks = profileData?.links_set?.filter((item) => {
    return item.types === "social";
  });
  const filteredReviews = profileData?.links_set?.filter((item) => {
    return item.types === "review";
  });
  const profileCardsPosition = useSelector(
    (state) => state.userProfileReducer.profileCardsPosition
  );

  useEffect(() => {
    axiosWithoutAuth
      .get("country-code")
      .then((res) => {
        let country_codes = res.data.map((item) => {
          return { label: item.country, value: item.country_code };
        });
        setCountryCodes(country_codes);
      })
      .catch((err) => {
        // toast.error("Something went wrong");
      });
  }, []);

  useEffect(() => {
    const sectionItem = [
      {
        id: "about me",
        com: (
          <div className="mt-4">
            <div className="mb-3">
              <h2 className="card-heading">About Me</h2>
            </div>
            <ShowMoreText
              /* Default options */
              lines={3}
              more={
                <span>
                  Show More<FaAngleDown className="ms-2"></FaAngleDown>
                </span>
              }
              less={
                <span>
                  Show Less<FaAngleUp className="ms-2"></FaAngleUp>
                </span>
              }
              className="content-css"
              anchorClass="show-more-less-clickable text-center d-block show-more-btn"
              expanded={false}
            >
              {profileData?.bio_details}
            </ShowMoreText>
          </div>
        ),
        show: true,
      },
      {
        id: "social links",
        com: (
          <ProfessionalViewLinkCard
            heading={"Social channels"}
            reviewItem={filteredNetworks}
          />
        ),
        show: filteredNetworks && filteredNetworks?.length > 0,
      },
      {
        id: "review links",
        com: (
          <ProfessionalViewLinkCard
            heading={"REVIEW LINKS"}
            reviewItem={filteredReviews}
          />
        ),
        show: filteredReviews && filteredReviews?.length > 0,
      },
      {
        id: "website links",
        com: (
          <ProfessionalViewLinkCard
            heading={"Website links"}
            reviewItem={filteredWebsite}
            website={true}
            color={color}
            textColor={textColor}
          />
        ),
        show: filteredWebsite && filteredWebsite?.length > 0,
      },
      {
        id: "videos",
        com: (
          <div className="">
            <div className="mt-4 w-100">
              <VideoContent
                url={profileData?.video?.video_url}
                description={profileData?.video?.video_description}
                type={profileData?.video?.video_source}
              />
            </div>
          </div>
        ),
        show: profileData?.video !== null,
      },
      {
        id: "contact information",
        com: (
          <ProfessionalViewContactContainer
            profileData={profileData}
            textColor={textColor}
            color={color}
          />
        ),
        show: true,
      },
    ];
    if (profileCardsPosition.length > 0) {
      let postionValue = [];
          postionValue = profileCardsPosition;
          postionValue.sort((a, b) => a.position - b.position);
          const cloneSectionItem = [];
          postionValue.forEach((element) => {
            let i = sectionItem.findIndex((itm) => itm.id === element.name);
            cloneSectionItem.push(sectionItem[i]);
          });
          setEditSection(cloneSectionItem);
    }
    else{
          setEditSection(sectionItem);
    }
  }, [profileCardsPosition]);

  const handleCloseShareDetail = () => {
    setOpenShaeDetail(false);
  };
  const saveContact = () => {
    let data = {
      event: "save_contact",
      profile: profileData?.id,
    };
    axios
      .post(`${baseURL}/user-profile/contact-save/`, data)
      .then((res) => {
        toast.success("Contact saved successfully.");
        childRef.current.generateVCFFile();
        setBtnLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Container>
        {!remove && (
          <VCardFileCreator profileData={profileData} ref={childRef} />
        )}
        <div className="view-card-area py-4 mt-3">
          {!remove && (
            <div className="bg-white p-3">
              <div className="d-flex align-items-center justify-content-between">
                <img className="site-logo" src="/images/Logo.svg" alt="logo" />
                <button
                  className="get-your-card"
                  onClick={() => (window.location.href = GET_YOUR_CARD_LINK)}
                >
                  Get Your Card
                </button>
              </div>
            </div>
          )}
          {/* profile pic view */}
          <div className="d-flex align-items-center jsutify-content-center">
            <div className="edit-profile-pic w-100">
              <div className="view-profile-pic">
                <div
                  className="back-vpp"
                  style={{ backgroundColor: color }}
                ></div>
                <img
                  src={
                    profileData?.profile_picture
                      ? `${profileData?.profile_picture}`
                      : "/images/dummyUser.jpg"
                  }
                  alt="profile-pic"
                />
              </div>
              <div className="profile-name">
                <h4 className="profile-user-name text-capitalize">
                  {profileData?.first_name} {profileData?.last_name}
                </h4>
                <p className="profile-user-designation  m-0">
                  <span className="text-capitalize">
                    {" "}
                    {profileData?.position}
                  </span>{" "}
                  at
                  <br />{" "}
                  <span className="text-capitalize">
                    {profileData?.company_name}
                  </span>
                </p>
              </div>
            </div>
          </div>
          {editSection.map((ele) => ele.show && <>{ele.com}</>)}
          {!remove && (
            <div className="view-profile-btn">
              <div className="text-center">
                <button
                  className="secondary-btn mx-1 mb-3"
                  disabled={btnLoader}
                  onClick={() => {
                    setBtnLoader(true);
                    saveContact();
                  }}
                >
                  <MdContacts className="sv-contact-icon me-2" />
                  Save Contact
                </button>
                <button className="primary-btn mx-1" onClick={shareDetail}>
                  <img
                    className="share-contact-icon me-2"
                    src="/images/exchange-white.svg"
                    alt=""
                  />
                  Share Your Details
                </button>
              </div>
            </div>
          )}
        </div>
      </Container>
      {opensharedetail && (
        <ShareProfileModal
          countryCodes={countryCodes}
          id={profileData?.id}
          opensharedetail={opensharedetail}
          handleCloseShareDetail={handleCloseShareDetail}
        />
      )}
    </>
  );
};

export default ProfileViewProfessional;
