import React from "react";
import { FaPencilAlt } from "react-icons/fa";
import Iframe from "../../common/video/Iframe";
import UpDownIcon from "./UpDownIcon";

const EditVideo = ({
  handleShowSocialNetworkEditModal,
  activeUserData,
  userData,
  // var urlSplit = "https://vimeo.com/791837360".split("/");
  index,
  editSection,
}) => {
  return (
    <div className="d-flex align-items-center jsutify-content-center">
      <UpDownIcon />
      <div className="card mt-4 w-100">
        <div className="d-flex align-items-center justify-content-between mb-5 px-md-3">
          <h2 className="card-heading me-2">Videos</h2>
          <button
            className="edit-button"
            onClick={() => handleShowSocialNetworkEditModal("video")}
          >
            <FaPencilAlt className="me-2" />
            Edit
          </button>
        </div>
        <div className="me-2">
          {activeUserData?.video === null ? (
            <div className="nodata-found">No videos here</div>
          ) : (
            <div className="mb-4">
              <Iframe
                url={activeUserData?.video?.video_url}
                type={activeUserData?.video?.video_source}
              />
              <p className="video-para">{userData?.video?.video_description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditVideo;
