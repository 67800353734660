import axios from "axios";
export const baseURL = `${process.env.REACT_APP_BASE_URL}`;

let token = localStorage.getItem("token")
  ? `Token ${localStorage.getItem("token")}`
  : "Token";
export const API = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/auth/`,
  headers: {
    "content-type": "application/json",
  },
});

export const authAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/user-profile/`,
  headers: {
    "content-type": "application/json",
    Authorization: token,
  },
});

export const axiosWithoutAuth = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/user-profile/`,
  headers: {
    "content-type": "application/json",
  },
});

authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
