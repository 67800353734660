import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCards } from "../Redux/Actions/userProfileAction";
import ProfileViewClassic from "./ProfileViewClassic";
import ProfileViewProfessional from "./ProfileViewProfessional";

const GetCard = () => {
  const id = useParams().id;
  let dispatch = useDispatch();
  const cardData = useSelector((state) => state.userProfileReducer.cardData);
  const [loading, setLoading] = useState(true);

  // useEffect
  useEffect(() => {
    dispatch(getCards(id, () => setLoading(false)));
  }, [dispatch, id]);

  return (
    <div>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          {cardData.settings?.theme === "professional" ? (
            <ProfileViewProfessional
              profileData={cardData}
              bgColor={cardData.settings?.theme_color}
              textColor={
                cardData.settings?.theme_color === "#92CAC8" ||
                cardData.settings?.theme_color === "#F2DAF4" ||
                cardData.settings?.theme_color === "#FFFC00"
                  ? "black"
                  : "white"
              }
              remove={false}
            />
          ) : (
            <ProfileViewClassic
              profileData={cardData}
              bgColor={cardData.settings?.theme_color}
              textColor={
                cardData.settings?.theme_color === "#92CAC8" ||
                cardData.settings?.theme_color === "#F2DAF4" ||
                cardData.settings?.theme_color === "#FFFC00"
                  ? "black"
                  : "white"
              }
              remove={false}
            />
          )}
        </>
      )}
    </div>
  );
};

export default GetCard;
