import React from "react";
import { FaAngleDown, FaAngleUp, FaPencilAlt } from "react-icons/fa";
import ShowMoreText from "react-show-more-text";
import UpDownIcon from "./UpDownIcon";

const EditAbout = ({ handleShowAboutEditModal, activeUserData }) => {
  return (
    <div className="d-flex align-items-center jsutify-content-center">
      <UpDownIcon />

      <div className="card mt-4 w-100">
        <div className="d-flex align-items-center justify-content-between mb-5 px-md-3">
          <h2 className="card-heading me-2">About Me</h2>
          <button className="edit-button" onClick={handleShowAboutEditModal}>
            <FaPencilAlt className="me-2" />
            Edit
          </button>
        </div>

        <ShowMoreText
          lines={3}
          more={
            <span>
              Show More
              <FaAngleDown className="ms-2"></FaAngleDown>
            </span>
          }
          less={
            <span>
              Show Less<FaAngleUp className="ms-2"></FaAngleUp>
            </span>
          }
          className="content-css w-100"
          anchorClass="show-more-less-clickable text-center d-block show-more-btn"
          expanded={false}
          // width={280}
          truncatedEndingComponent={"... "}
        >
          {activeUserData.bio_details}
        </ShowMoreText>
      </div>
    </div>
  );
};

export default EditAbout;
