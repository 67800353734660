import React, { useRef, useState } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch } from "react-redux";
import { changeCreditionals } from "../Redux/Actions/userProfileAction";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Container } from "react-bootstrap";
import { PASSWORD_REGEX } from "../regexValidation/RegexValidation";

const ChangeCredentials = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        regex:
          "A minimum 8 characters password contains a combination of uppercase, lowercase letter, special character and number",
      },
    })
  );
  const [value, setValue] = useState(0);
  let userId = JSON.parse(localStorage.getItem("user"));
  const [showPassword, setShowPassword] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickOldPassword = () => setOldPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [formValues, setFormvalues] = useState({
    old_password:'',
    new_password:''
  });
  const [btnLoader, setBtnLoader] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const forceUpdate = () => {
    setValue(value + 1);
  };
  const handleChange = (event) => {
    if(event.target.name === 'new_password') {
      event.target.value === formValues.old_password ? setErrMsg(true) : setErrMsg(false);
    }
    if (event.target.name === 'old_password' && formValues.new_password !== '') {
      event.target.value === formValues.new_password ? setErrMsg(true) : setErrMsg(false); 
    }
    setFormvalues({ ...formValues, [event.target.name]: event.target.value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid() && !errMsg) {
      setBtnLoader(true);
      dispatch(
        changeCreditionals(
          formValues,
          () => navigate("/settings"),
          () => {
            setBtnLoader(false);
          }
        )
      );
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };
  return (
    <>
      <div className="view-card-area mt-10">
        <Container>
          <form onSubmit={handleSubmit} className="">
            <h2 className="profile-card-heading d-flex align-items-center">
              <MdKeyboardBackspace
                className="me-3"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/settings")}
              />
              Change Credentials
            </h2>
            <div className="mt-4">
              <div className="mb-4">
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="Email Address"
                  name="email"
                  disabled
                  defaultValue={userId?.email}
                />
              </div>
              <div className="mb-4">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-old-password">
                    Old Password
                  </InputLabel>
                  <OutlinedInput
                    onChange={handleChange}
                    value={formValues?.old_password}
                    name="old_password"
                    id="outlined-adornment-old-password"
                    type={oldPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickOldPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {/* <MdVisibility /> */}
                          {oldPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Old Password"
                  />
                </FormControl>
                {validator.current &&
                  validator.current.message(
                    "Current Password",
                    formValues.old_password,
                    "required",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
              <div className="mb-4">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    autoComplete="off"
                    onChange={handleChange}
                    value={formValues?.new_password}
                    name="new_password"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {/* <MdVisibility /> */}
                          {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                  />
                </FormControl>
                {validator.current &&
                  validator.current.message(
                    "New Password ",
                    formValues.new_password,
                    [
                      "required",
                      {
                        regex: PASSWORD_REGEX,
                      },
                    ],
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
                  {errMsg && (
              <span className="text-danger simple-react-validator-errors text-left">
                Old and new password should not be same
              </span>
            )}
              </div>
            </div>

            <button
              disabled={btnLoader||errMsg}
              type="submit"
              className="primary-btn w-100"
            >
              {btnLoader ? (
                <CircularProgress
                  thickness={6.6}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "white",
                  }}
                />
              ) : (
                "Save"
              )}
            </button>
          </form>
        </Container>
      </div>
    </>
  );
};

export default ChangeCredentials;
