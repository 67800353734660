import { toast } from "react-toastify";
import { authAxios } from "../../config";

export const UpdateContactInfo =
  (payload, id, loader, callback) => (dispatch) => {
    authAxios
      .patch(`user/${id}/`, payload)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Contact information updated successfully");
          loader();
          callback();
        }
      })
      .catch((error) => {
        loader();
        toast.error("Something went wrong");
      });
  };

export const getProfileNameId = (callback) => (dispatch) => {
  authAxios
    .get("user/user-profile-list/")
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        callback();
        dispatch({
          type: "USER_ID_PROFILE",
          payload: res.data,
        });
      }
    })
    .catch((error) => {
      callback();
      toast.error("Something went wrong");
    });
};

export const getConnection = (id, callback) => (dispatch) => {
  authAxios
    .get(`connections/?profile=${id}`)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        dispatch({
          type: "CONNECTION",
          payload: res.data,
        });
      }
      callback();
    })
    .catch((error) => {
      toast.error("Something went wrong");
      callback();
    });
};

export const deleteConnection =
  ({ id, callback }) =>
  (dispatch) => {
    authAxios
      .delete(`connections/${id}`)
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          dispatch({
            type: "DELETE_CONNECTION",
            payload: id,
          });
          callback();
          toast.success("Connection deleted successfully");
        }
      })
      .catch((err) => {
        callback();
        toast.error("Something went wrong");
        console.log(err);
      });
  };

export const updateStatus =
  ({ loader, id, payload, is_active }) =>
  (dispatch) => {
    authAxios
      .patch(`user/${id}/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "UPDATE_USER_STATUS",
            payload: res.data,
          });
          toast.success("Profile update successfully");
          loader();
        }
      })
      .catch((err) => {
        loader();
        toast.error("Something went wrong");
      });
  };
