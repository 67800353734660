const FIRST_NAME_REGEX = /^[a-z ,.'-]+$/i;
const WEBSITE_REGEX =
  /^((https?|http):\/\/)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_~#]+)*\/?(\?[a-zA-Z0-9-_\/]+=[a-zA-Z0-9-+%/=&.,:#]+&?)?$/g;
// const PASSWORD_REGEX = /^(?=.*\d)(?=.*[!@#$%^><{}&~`[]()*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/g;
const PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,}$/g;
const PHONE_NO_WITH_COUNTRY_CODE = /^(\+)[0-9]{6,15}$/;
const CHECK_HTTP_REGEX = /^((http|https|ftp):\/\/)/;
const URL_REGEX = /([\w\-]+\.)+[\w\-]+(\/[\w\-\.]*)*\??([\w\-\.\+~%=&;]*)?/g;
// /^((https?|http):\/\/)?[a-z0-9]+(\.[a-z0-9]{2,}){1,3}(#?\/?[a-zA-Z0-9-_~#.]+)*\/?(\?[a-zA-Z0-9-_\/]+=[a-zA-Z0-9-+%\/=&.,:#]+&?)?$/g;
const DIGITS_ONLY_REGEX = /^\d+$/;

export {
  FIRST_NAME_REGEX,
  WEBSITE_REGEX,
  PASSWORD_REGEX,
  PHONE_NO_WITH_COUNTRY_CODE,
  CHECK_HTTP_REGEX,
  URL_REGEX,
  DIGITS_ONLY_REGEX,
};
