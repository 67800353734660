import React from "react";
import "../Layouts/header.css";
import { FaBars } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { Collapse, FormControlLabel, Box } from "@mui/material";
import { Container,  Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/images/Logo.svg";

const Header = () => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const headerItem = [
    { name: "Manage Profiles", routes: "/manage-profile" },
    { name: "Connections", routes: "/connections" },
    { name: "Settings", routes: "/settings" },
  ];

  const logout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <div className="d-flex align-tems-center py-2 justify-content-between w-100">
            <div></div>
            <Link to="/profile-edit-view" onClick={()=>setChecked(false)}>
              <img className="site-logo" src={Logo} alt="logo" />
            </Link>
            <div>
              <FormControlLabel
                control={
                  <div onClick={handleChange}>
                    {checked ? <MdClose /> : <FaBars />}
                  </div>
                }
                // label="Show"
              />
            </div>
          </div>
        </Container>
      </Navbar>
      <Box>
        <div>
          <Collapse in={checked}>
            <div className="expend-nav">
              <div className="inner-nav-expend">
                {headerItem.map((item) => (
                  <Link
                    className="cs-nav-link"
                    to={item.routes}
                    key={item.routes}
                    onClick={handleChange}
                  >
                    {item.name}
                  </Link>
                ))}
                {/* <Link className="cs-nav-link" to="/manage-profile">Manage Profiles</Link>
              <Link className="cs-nav-link" to="#">Teams</Link>
              <Link className="cs-nav-link" to="#">Connections</Link>
              <Link className="cs-nav-link" to="#">Settings</Link> */}
              </div>
              <button className="logout-btn" onClick={logout}>
                Logout{" "}
                <img src="/images/digital_out_of_home.svg" alt="logout" />
              </button>
            </div>
          </Collapse>
        </div>
      </Box>
    </>
  );
};

export default Header;
