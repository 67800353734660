import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { MdOutlineAdsClick, MdOutlineLocationCity, MdOutlineMailOutline, MdOutlinePhone } from 'react-icons/md';
import { CheckWebsiteUrl } from '../../common/functions/checkWebSiteUrl';

const ProfessionalViewContactContainer = ({profileData, color, textColor}) => {
    return (
        <div className="">
            <div className=" mt-4 w-100">
              <div className="mb-3">
                <h2 className="card-heading">Contact Information</h2>
              </div>
              <div className="contact-detail">
                <Row>
                  <Col xs={6} sm={3}>
                    <div className="mb-3">
                      {profileData?.phone && profileData?.phone?.length > 0 ? (
                        <a
                          className="contact-icon-text"
                          href={`tel:${profileData?.phone[0]?.country_code}${profileData?.phone[0]?.contact_number}`}
                        >
                          <MdOutlinePhone
                            style={{ backgroundColor: color, color: textColor }}
                            className="contact-icon"
                          />
                          <h5 className="contact-icon-text">Phone</h5>
                        </a>
                      ) : (
                        <>
                          <MdOutlinePhone
                            className="contact-icon"
                            style={{ backgroundColor: color, color: textColor }}
                          />
                          <h5 className="contact-icon-text">Phone</h5>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col xs={6} sm={3}>
                    <div className="mb-3">
                      {profileData?.email && profileData?.email?.length > 0 ? (
                        <a
                          className="contact-icon-text"
                          href={`mailto:${profileData?.email[0]}`}
                        >
                          <MdOutlineMailOutline
                            style={{ backgroundColor: color, color: textColor }}
                            className="contact-icon"
                          />
                          <h5 className="contact-icon-text">E-mail</h5>
                        </a>
                      ) : (
                        <>
                          <MdOutlineMailOutline
                            className="contact-icon"
                            style={{ backgroundColor: color, color: textColor }}
                          />
                          <h5 className="contact-icon-text">E-mail</h5>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col xs={6} sm={3}>
                    <div
                      className=" mb-3"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        profileData?.website && profileData?.website?.length > 0
                          ? window.open(
                              CheckWebsiteUrl(profileData?.website[0])
                            )
                          : false
                      }
                    >
                      <MdOutlineAdsClick
                        style={{
                          backgroundColor: color,
                          color: textColor,
                          cursor: "pointer",
                        }}
                        className="contact-icon"
                      />
                      <h5 className="contact-icon-text">Website</h5>
                    </div>
                  </Col>
                  <Col xs={6} sm={3}>
                    <div
                      className="mb-3"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        profileData?.address && profileData?.address?.length > 0
                          ? window.open(
                              `https://www.google.com/maps/place?q=${profileData?.address[0].street_line_one} ${profileData?.address[0].street_line_two} ${profileData?.address[0].city} ${profileData?.address[0].state} ${profileData?.address[0].postal_code} ${profileData?.address[0].country}`
                            )
                          : false
                      }
                    >
                      <MdOutlineLocationCity
                        style={{
                          backgroundColor: color,
                          color: textColor,
                          cursor: "pointer",
                        }}
                        className="contact-icon"
                      />
                      <h5 className="contact-icon-text">Address</h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
    );
}

export default ProfessionalViewContactContainer;
