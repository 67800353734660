import React, { useEffect, useState } from "react";
import CommanNetworkAccounts from "../CommanNetworkAccounts";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCountryCodes } from "../../Redux/Actions/userProfileAction";
import { mobileNoValidation } from "../../helper/Helper";
import DragNDrop from "./DragNDrop";

export default function SocialNetworkModal({
  modalStepCount,
  selectedLinks,
  setEditLinkValue,
  activeAccount,
  setActiveAccount,
  validator,
  setShowModal,
  countryCode,
  setCountryCode,
  error,
  setError,
  setLoading,
}) {
  const dispatch = useDispatch();

  const countryCodes = useSelector((state) =>
    state.userProfileReducer.country_code.map((item) => {
      return { label: item.country, value: item.country_code };
    })
  );

  useEffect(() => {
    dispatch(getCountryCodes());
  }, [dispatch]);

  useEffect(() => {
    setError({ ...error, show: false });
  }, [modalStepCount]);

  const handleChange = (event) => {
    const whatsappNo = activeAccount?.accUrl?.includes("wa.me/")
      ? activeAccount?.accUrl?.split("e/")[1]
      : activeAccount?.accUrl;
    if (event.target.name === "country_code") {
      if (!mobileNoValidation(event.target.value, activeAccount?.accUrl)) {
        setError({ ...error, show: true });
      } else {
        setError({ ...error, show: false });
      }
      setCountryCode(event.target.value);
    }
    if (event.target.name === "url") {
      if (!mobileNoValidation(countryCode, event.target.value)) {
        setError({ ...error, show: true });
      } else {
        setError({ ...error, show: false });
      }
      setActiveAccount({
        ...activeAccount,
        accUrl: event.target.value,
      });
    }
  };
  return (
    <>
      {modalStepCount === 0 && (
        <>
          <div className="px-md-5">
            <div className="my-5">
              <DragNDrop
                selectedLinks={selectedLinks?.social}
                setEditLinkValue={setEditLinkValue}
                setShowModal={setShowModal}
                setLoading={setLoading}
                title="social"
              />
            </div>
          </div>
          <div className="mb-4 px-md-5">
            <TextField
              fullWidth
              id="outlined-required"
              label="Company Name"
              value={activeAccount?.companyName}
              onChange={(e) =>
                setActiveAccount({
                  ...activeAccount,
                  companyName: e.target.value,
                })
              }
            />
          </div>
        </>
      )}

      {/* Social Network Edit Modal  */}
      {modalStepCount === 2 && (
        <div className="mb-4 px-md-5">
          <div className="d-flex align-items-center mb-4">
            <div className="me-3">
              <img
                className="social_icon mb-0"
                src={activeAccount?.imgUrl}
                alt="icon"
              />
            </div>
            <h4 className="social-n-title">{activeAccount?.title}</h4>
          </div>

          {activeAccount?.title !== "Whatsapp" ? (
            <div className="mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label={"Paste URL here"}
                value={activeAccount?.accUrl}
                onChange={(e) =>
                  setActiveAccount({
                    ...activeAccount,
                    accUrl: e.target.value,
                  })
                }
              />

              {validator.current &&
                validator.current.message(
                  "URL",
                  activeAccount.accUrl,
                  "required|webSiteURLRegex",
                  {
                    className:
                      "text-danger simple-react-validator-errors text-left",
                  }
                )}
            </div>
          ) : (
            <>
              <div className="mb-4">
                <TextField
                  id="outlined-select-currency"
                  select
                  fullWidth
                  label="Country Code"
                  value={countryCode}
                  name="country_code"
                  onChange={handleChange}
                >
                  {countryCodes.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {validator.current &&
                  validator.current.message(
                    "Country Code",
                    countryCode,
                    "required",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="Enter Whatsapp Number"
                  name="url"
                  value={activeAccount?.accUrl}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment className="me-0" position="start">
                        {countryCode}
                      </InputAdornment>
                    ),
                  }}
                />

                {validator.current &&
                  validator.current.message(
                    "Whatsapp Number",
                    activeAccount?.accUrl?.includes("wa.me/")
                      ? activeAccount?.accUrl?.split("e/")[1]
                      : activeAccount?.accUrl,
                    "required",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
                {error.show && (
                  <span className="text-danger simple-react-validator-errors text-left">
                    {error.msg}
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      )}

      {/* Social Networks Edit > Add Account */}
      {modalStepCount === 1 && (
        <CommanNetworkAccounts
          activeAccount={activeAccount}
          setActiveAccount={setActiveAccount}
          selectedLinks={selectedLinks?.social}
        />
      )}
    </>
  );
}
