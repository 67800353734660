import React, { useRef, useState, useEffect } from "react";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import { MdOutlineMail } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { RiUserLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { API } from "../config";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import Otpform from "./Otpform";
import {
  FIRST_NAME_REGEX,
  PASSWORD_REGEX,
} from "../regexValidation/RegexValidation";
import { UUIDv4 } from "uuid-v4-validator";
import {
  errorMessageAutoCloseOff,
  successMessageAutoCloseOff,
} from "../common/showToasterMessage";
import {
  CHECK_EMAIL_FOR_OTP,
  SOMETHING_WENT_WRONG,
} from "../common/constants/toastMessage";

const Signup = () => {
  const cardId = useParams().id;
  // const importedId = "s4F68hFDf-d3R5-4Rt6-dRgi-dEji85feY51s";

  const validator = useRef(
    new SimpleReactValidator({
      validators: {
        nameRegex: {
          message: "The :attribute only contain letter space comma and period.",
          rule: (val, value, validator) => {
            return validator.helpers.testRegex(val, FIRST_NAME_REGEX);
          },
          required: true,
        },
        regex: {
          message:
            "A minimum 8 characters password contains a combination of uppercase, lowercase letter, special character and number",
          rule: (val, value, validator) => {
            return validator.helpers.testRegex(val, PASSWORD_REGEX);
          },
          required: true,
        },
      },
    })
  );
  const [signupForm, SetSignupForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password2: "",
    username: "",
    card: cardId,
    otp: "",
  });
  const [value, setValue] = useState(0);
  const [btnLoader, setBtnLoader] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otpView, setOtpView] = useState(false);
  const [validId, setValidID] = useState(false);

  useEffect(() => {
    if (!UUIDv4.validate(cardId)) {
      toast.error("Invalid Card", {
        autoClose: false,
        onClose: () => {
          window.location.href = "/404";
        },
      });
      setValidID(true);
      setTimeout(() => {
        window.location.href = "/404";
      }, 7000);
    }
  }, []);

  const forceUpdate = () => {
    setValue(value + 1);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickOldPassword = () => setShowConfirmPassword((show) => !show);

  const signupUser = () => {
    if (validator.current.allValid()) {
      if (signupForm.password !== signupForm.password2) {
        setErrMsg(true);
        return;
      }
      setBtnLoader(true);
      setErrMsg(false);
      let payload = {
        first_name: signupForm.first_name,
        last_name: signupForm.last_name,
        email: signupForm.email,
        password: signupForm.password,
        username: signupForm.email,
        card: signupForm.card,
      };
      API.post("invitation-code/", payload)
        .then((res) => {
          successMessageAutoCloseOff(CHECK_EMAIL_FOR_OTP);
          setOtpView(true);
          setBtnLoader(false);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            if (err.response.data.password) {
              errorMessageAutoCloseOff(err.response.data.password[0]);
            } else {
              errorMessageAutoCloseOff(err.response.data.message);
            }
          } else {
            errorMessageAutoCloseOff(SOMETHING_WENT_WRONG);
          }
          setBtnLoader(false);
        });
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "password2") {
      e.target.value === signupForm.password
        ? setErrMsg(false)
        : setErrMsg(true);
    }
    if (e.target.name === "password" && signupForm.password2 !== "") {
      e.target.value === signupForm.password2
        ? setErrMsg(false)
        : setErrMsg(true);
    }
    SetSignupForm({
      ...signupForm,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      {otpView ? (
        <Otpform
          setOtpView={setOtpView}
          signupForm={signupForm}
          SetSignupForm={SetSignupForm}
        />
      ) : (
        <div className="login-wrap px-3">
          <div className="login-page">
            <div className="text-center">
              <img
                className="site-logo-main mb-5"
                src="/images/Logo.svg"
                alt="logo"
              />
              <h1 className="login-title py-5">Sign Up</h1>
            </div>
            <div className="">
              <div className="mb-4">
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="Enter Your First Name"
                  name="first_name"
                  onChange={handleChange}
                  value={signupForm.first_name}
                  InputProps={{
                    endAdornment: <RiUserLine position="end"></RiUserLine>,
                  }}
                />
                {validator.current &&
                  validator.current.message(
                    "First Name",
                    signupForm?.first_name,
                    "required|nameRegex|max:50|min:2",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="Enter Your Last Name"
                  name="last_name"
                  onChange={handleChange}
                  value={signupForm.last_name}
                  InputProps={{
                    endAdornment: <RiUserLine position="end"></RiUserLine>,
                  }}
                />
                {validator.current &&
                  validator.current.message(
                    "last name",
                    signupForm.last_name,
                    "required|max:50|min:2",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="Enter Your Email Address"
                  name="email"
                  onChange={handleChange}
                  value={signupForm.email}
                  InputProps={{
                    endAdornment: (
                      <MdOutlineMail position="end"></MdOutlineMail>
                    ),
                  }}
                />
                {validator.current &&
                  validator.current.message(
                    "Email Address",
                    signupForm.email,
                    "required|email",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  id="outlined-required"
                  label="Enter Your Password"
                  name="password"
                  onChange={handleChange}
                  value={signupForm.password}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <AiOutlineEyeInvisible />
                        ) : (
                          <AiOutlineEye />
                        )}
                      </IconButton>
                    ),
                  }}
                />
                {validator.current &&
                  validator.current.message(
                    "Password",
                    signupForm.password,
                    "required|regex",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  type={showConfirmPassword ? "text" : "password"}
                  id="outlined-required"
                  label="Verify Your Password"
                  name="password2"
                  onChange={handleChange}
                  value={signupForm.password2}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickOldPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <AiOutlineEyeInvisible />
                        ) : (
                          <AiOutlineEye />
                        )}
                      </IconButton>
                    ),
                  }}
                />
                {validator.current &&
                  validator.current.message(
                    "Confirm Password",
                    signupForm.password2,
                    "required",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
                {errMsg && (
                  <span className="text-danger simple-react-validator-errors text-left">
                    The password and confirmation do not match.
                  </span>
                )}
              </div>
              <button
                className="login-btn mt-4"
                disabled={btnLoader || validId}
                onClick={signupUser}
              >
                {btnLoader ? (
                  <CircularProgress
                    thickness={6.6}
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "white",
                    }}
                  />
                ) : (
                  "Sign Up"
                )}
              </button>
            </div>
            <div className="text-center mt-5">
              <p>
                Already have an account?
                <a className="ms-2" href="/">
                  <strong>Login</strong>
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
