import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "./Layouts/Header";

const PrivateRoute = () => {
  const token = localStorage.getItem("token");
  return (
    <>
      {token && <Header />}
      <div className="py-5">
        {token ? <Outlet /> : <Navigate to={"/login"} />}
      </div>
    </>
  );
};

export default PrivateRoute;
