import React from 'react';
import { MdOutlineAdsClick, MdOutlineLocationCity, MdOutlineMailOutline, MdOutlinePhone } from 'react-icons/md';
import { CheckWebsiteUrl } from '../../common/functions/checkWebSiteUrl';

const ClassicViewContactContainer = ({profileData, color, textColor}) => {
    return (
        <div className="">
              <div className="card mt-4 w-100">
                <div className=" mb-3">
                  <h2 className="card-heading">Contact Information</h2>
                </div>
                <div className="contact-detail">
                  {profileData?.phone && profileData?.phone?.length > 0 && (
                    <div
                      style={{ backgroundColor: color }}
                      className="website-link-list mb-2"
                    >
                      {" "}
                      <a
                        style={{ color: "#fff" }}
                        href={`tel:${profileData?.phone[0]?.country_code}${profileData?.phone[0]?.contact_number}`}
                      >
                        <div className="d-flex align-items-center websites_link">
                          <MdOutlinePhone className="glob-icon me-3" />
                          <p
                            style={{ color: textColor }}
                            className="wesite_name"
                          >
                            {profileData?.phone &&
                              profileData?.phone?.length > 0 &&
                              profileData?.phone[0]?.country_code}
                            {profileData?.phone &&
                              profileData?.phone?.length > 0 &&
                              profileData?.phone[0]?.contact_number}
                          </p>
                        </div>
                      </a>
                    </div>
                  )}
                  {profileData?.email && profileData?.email?.length > 0 && (
                    <div
                      style={{ backgroundColor: color }}
                      className="website-link-list mb-2"
                    >
                      <a
                        style={{ color: "#fff" }}
                        href={`mailto:${profileData?.email[0]}`}
                      >
                        <div className="d-flex align-items-center websites_link">
                          <MdOutlineMailOutline className="glob-icon me-3" />
                          <p
                            style={{ color: textColor }}
                            className="wesite_name"
                          >
                            {profileData?.email &&
                              profileData?.email?.length > 0 &&
                              profileData?.email[0]}
                          </p>
                        </div>
                      </a>
                    </div>
                  )}
                  {profileData?.website && profileData?.website?.length > 0 && (
                    <div
                      style={{ backgroundColor: color }}
                      className="website-link-list mb-2"
                      onClick={() =>
                        profileData?.website && profileData?.website?.length > 0
                          ? window.open(
                              CheckWebsiteUrl(profileData?.website[0])
                            )
                          : false
                      }
                    >
                      <div className="d-flex align-items-center websites_link cursor-pointer">
                        <MdOutlineAdsClick className="glob-icon me-3" />
                        <p style={{ color: textColor }} className="wesite_name">
                          {" "}
                          {profileData?.website &&
                            profileData?.website?.length > 0 &&
                            profileData?.website[0]}
                        </p>
                      </div>
                    </div>
                  )}

                  {profileData?.address && profileData?.address?.length > 0 && (
                    <div
                      style={{ backgroundColor: color }}
                      className="website-link-list mb-2"
                      onClick={() =>
                        profileData?.address && profileData?.address?.length > 0
                          ? window.open(
                              `https://www.google.com/maps/place?q=${profileData?.address[0].street_line_one} ${profileData?.address[0].street_line_two} ${profileData?.address[0].city} ${profileData?.address[0].state} ${profileData?.address[0].postal_code} ${profileData?.address[0].country}`
                            )
                          : false
                      }
                    >
                      <div className="d-flex align-items-center websites_link cursor-pointer">
                        <MdOutlineLocationCity className="glob-icon me-3" />
                        <p style={{ color: textColor }} className="wesite_name">
                          {profileData?.address &&
                            profileData?.address[0]?.street_line_one}{" "}
                          {profileData?.address &&
                            profileData?.address[0].street_line_two}{" "}
                          {profileData?.address && profileData?.address[0].city}{" "}
                          {profileData?.address &&
                            profileData?.address[0].state}{" "}
                          {profileData?.address &&
                            profileData?.address[0].postal_code}{" "}
                          {profileData?.address &&
                            profileData?.address[0].country}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
    );
}

export default ClassicViewContactContainer;
