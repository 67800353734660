import React from "react";
import CheckUrl from "../CheckUrl";

const Iframe = ({ url, type }) => {
  return (
    <div className="video-bg">
      <iframe
        src={CheckUrl(url, type)}
        title="YouTube video player"
        className="w-100 h-100"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Iframe;
