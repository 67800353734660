import { TextField } from "@mui/material";
import React from "react";
import DragNDrop from "./EditModal/DragNDrop";

const WebSiteLinkDetail = ({
  modalStepCount,
  setModalStepCount,
  setActiveAccount,
  selectedLinks,
  activeAccount,
  setIsEditURL,
  isEditURL,
  setShowModal,
  validator,
  changePosition,
  setEditActiveAccount,
  setLoading,
}) => {
  const editWebsiteLink = (i) => {
    setIsEditURL(true);
    setModalStepCount(1);
    setEditActiveAccount({
      id: selectedLinks[i].id,
      title: selectedLinks[i].meta.title,
      accUrl: selectedLinks[i].url,
    });
    setActiveAccount({
      id: selectedLinks[i].id,
      title: selectedLinks[i].meta.title,
      accUrl: selectedLinks[i].url,
    });
  };

  return (
    <>
      {modalStepCount === 1 && (
        <div className="mb-4 px-md-5">
          <div className="mb-4">
            <TextField
              fullWidth
              id="outlined-required"
              label="Title"
              InputLabelProps={{
                shrink: true,
              }}
              value={activeAccount.title}
              placeholder="Enter Title"
              onChange={(e) =>
                setActiveAccount({ ...activeAccount, title: e.target.value })
              }
            />
            {validator.current &&
              validator.current.message(
                "Title",
                activeAccount.title,
                "required",
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )}
          </div>
          <div className="mb-4">
            <TextField
              fullWidth
              id="outlined-required"
              label="Insert URL here"
              InputLabelProps={{
                shrink: true,
              }}
              value={activeAccount.accUrl}
              placeholder="Enter URL"
              onChange={(e) =>
                setActiveAccount({ ...activeAccount, accUrl: e.target.value })
              }
            />
            {validator.current &&
              validator.current.message(
                "URL",
                activeAccount.accUrl,
                "required|webSiteURLRegex",
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )}
          </div>
          {!isEditURL && (
            <div className="mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Company Name"
                value={activeAccount.companyName}
                onChange={(e) =>
                  setActiveAccount({
                    ...activeAccount,
                    companyName: e.target.value,
                  })
                }
              />
              {validator.current &&
                validator.current.message(
                  "Company name",
                  activeAccount.companyName,
                  "required|max:50",
                  {
                    className:
                      "text-danger simple-react-validator-errors text-left",
                  }
                )}
            </div>
          )}
        </div>
      )}
      {modalStepCount === 0 && (
        <div className="mb-4 px-md-5">
          {/* For applying the drag and drop functionality in local comment out the strict mode  */}
          <DragNDrop
            selectedLinks={selectedLinks}
            changePosition={changePosition}
            setEditLinkValue={editWebsiteLink}
            setShowModal={setShowModal}
            setLoading={setLoading}
            title="website"
          />
        </div>
      )}
    </>
  );
};

export default WebSiteLinkDetail;
