import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Drawer,
  MenuItem,
  TextField,
} from "@mui/material";
import CommanNetworkAccounts from "./CommanNetworkAccounts";
import { useDispatch } from "react-redux";
import {
  addUpdateLinks,
  createVideos,
  deleteLinks,
  getActiveUserProfile,
  updateVideos,
} from "../Redux/Actions/userProfileAction";
import WebSiteLinkDetail from "./WebSiteLinkDetail";
import DeleteModal from "./DeleteModal";
import { UpdateContactInfo } from "../Redux/Actions/contactAction";
import SimpleReactValidator from "simple-react-validator";
import ContactInfo from "./ContactInfo";
import {
  PHONE_NO_WITH_COUNTRY_CODE,
  URL_REGEX,
} from "../regexValidation/RegexValidation";
import TextCount from "./TextCount";
import SocialNetworkModal from "./EditModal/SocialNetworkModal";
import {
  getCountryCodeAndMobileNo,
  mobileNoValidation,
} from "../helper/Helper";
import DragNDrop from "./EditModal/DragNDrop";

const socialNetwokEditData = [
  {
    name: "social",
    modalName: "EDIT SOCIAL CHANNELS",
  },
  {
    name: "review",
    modalName: "EDIT REVIEW LINKS",
  },
  {
    name: "website",
    modalName: "EDIT WEBSITE LINKS",
  },
  {
    name: "video",
    modalName: "EDIT VIDEO LINKS",
  },
  {
    name: "contact",
    modalName: "EDIT CONTACT INFORMATION",
  },
];

export default function MuiSocialNetworkDrawer({
  open,
  activeModalName,
  handleShowSocialNetworkEditModal,
  setShowSocialNetworkEditModal,
  userData,
}) {
  const dispatch = useDispatch();
  const validator = useRef(
    new SimpleReactValidator({
      validators: {
        webSiteURLRegex: {
          message: "The :attribute format is incorrect.",
          rule: (val, value, validator) => {
            return validator.helpers.testRegex(val, URL_REGEX);
          },
          required: true,
        },
        numberRegex: {
          message:
            "Enter a valid international whatsapp number with the plus sign such as +15612284111",
          rule: (val, value, validator) => {
            return validator.helpers.testRegex(val, PHONE_NO_WITH_COUNTRY_CODE);
          },
          required: true,
        },
      },
    })
  );
  const [customError, setCustomError] = useState(false);
  const [modalStepCount, setModalStepCount] = useState(0);
  const [selectedModal, setSelectedModal] = useState(undefined);
  const [selectedButtonValue, setSelectedButtonValue] = useState();
  const [isEditURL, setIsEditURL] = useState(false);
  const [activeAccount, setActiveAccount] = useState({
    imgUrl: "",
    accUrl: "",
    id: "",
    title: "",
    companyName: "",
    provider: "",
  });
  const [selectedLinks, setSelectedLinks] = useState({});
  const [btnloader, setBtnLoader] = useState(false);
  const [showModal, setShowModal] = useState({ show: false, id: "" });
  const [value, setValue] = useState(0);
  const [editActiveAccount, setEditActiveAccount] = useState({
    imgUrl: "",
    accUrl: "",
    id: "",
    title: "",
    companyName: "",
    provider: "",
  });
  const [countryCode, setCountryCode] = useState("+1");
  const [editCountryCode, setEditCountryCode] = useState("+1");
  const [modalBtnLoader, setModalBtnLoader] = useState(false);
  const forceUpdate = () => {
    setValue(value + 1);
  };
  const [isvalid, setIsValid] = useState(false);
  const [videoFields, setVideoFields] = useState({
    video_description: userData?.video ? userData?.video.video_description : "",
    video_source: userData?.video ? userData?.video.video_source : "",
    video_url: userData?.video ? userData?.video.video_url : "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    show: false,
    msg: "Enter valid phone number",
  });

  let handleVideoChange = (event) => {
    if (event.target.name === "video_description") {
      if (event.target.value.length > 250) {
        return;
      }
      return setVideoFields({
        ...videoFields,
        [event.target.name]: event.target.value,
      });
    } else {
      setVideoFields({
        ...videoFields,
        [event.target.name]: event.target.value,
      });
      setCustomError(false);
    }
  };

  // Formdata
  const [addressDetails, setaddressDetails] = useState([]);
  const [emailDetails, setEmailDetails] = useState([]);
  const [websitesDetails, setwebsitesDetails] = useState([]);
  const [phoneDetails, setphoneDetails] = useState([]);

  useEffect(() => {
    if (userData?.phone?.length > 0) {
      let phoneVal = [];
      userData.phone.map((ph) => {
        phoneVal = [...phoneVal, ph];
      });
      setphoneDetails(phoneVal);
    } else {
      setphoneDetails([
        {
          country_code: "",
          phone_type: "",
          contact_number: "",
        },
      ]);
    }
    if (userData?.email?.length > 0) {
      let emailDetailVal = [];
      userData.email.map((em) => {
        emailDetailVal = [...emailDetailVal, { email: em }];
      });
      setEmailDetails(emailDetailVal);
    } else {
      setEmailDetails([
        {
          email: "",
        },
      ]);
    }
    if (userData?.website?.length > 0) {
      let webDetailsVal = [];
      userData.website.map(
        (web) => (webDetailsVal = [...webDetailsVal, { website: web }])
      );
      setwebsitesDetails(webDetailsVal);
    } else {
      setwebsitesDetails([
        {
          website: "",
        },
      ]);
    }
    if (userData?.address?.length > 0) {
      let addressField = [];
      userData.address.map((add) => (addressField = [...addressField, add]));
      setaddressDetails(addressField);
    } else {
      setaddressDetails([
        {
          address_type: "",
          street_line_one: "",
          street_line_two: "",
          city: "",
          state: "",
          postal_code: "",
          country: "",
        },
      ]);
    }
  }, [userData]);

  // Handle Change
  const addressHandleChange = (key, value, index) => {
    const items = [...addressDetails];
    items[index] = { ...items[index], [key]: value };
    setaddressDetails(items);
  };
  const websiteHandleChange = (key, value, index) => {
    const items = [...websitesDetails];
    items[index] = { ...items[index], [key]: value };
    setwebsitesDetails(items);
  };
  const emailHandleChange = (key, value, index) => {
    const items = [...emailDetails];
    items[index] = { ...items[index], [key]: value };
    setEmailDetails(items);
  };
  const phoneHandleChange = (key, value, index) => {
    if (key === "contact_number") {
      if (!mobileNoValidation(phoneDetails[index].country_code, value)) {
        setError({ ...error, show: true });
      } else {
        setError({ ...error, show: false });
      }
    }
    if (key === "country_code") {
      if (!mobileNoValidation(value, phoneDetails[index].contact_number)) {
        setError({ ...error, show: true });
      } else {
        setError({ ...error, show: false });
      }
    }
    const items = [...phoneDetails];
    items[index] = { ...items[index], [key]: value };
    setphoneDetails(items);
  };

  // Add & Remove Address Field
  const addAddressField = (index) => {
    if (
      validator.current.fieldValid("Address Type") &&
      validator.current.fieldValid("Street line one") &&
      validator.current.fieldValid("Street line two") &&
      validator.current.fieldValid("City") &&
      validator.current.fieldValid("State / Province") &&
      validator.current.fieldValid("Postal Code") &&
      validator.current.fieldValid("Country")
    ) {
      validator.current.hideMessageFor("Address Type");
      validator.current.hideMessageFor("Street line one");
      validator.current.hideMessageFor("Street line two");
      validator.current.hideMessageFor("City");
      validator.current.hideMessageFor("State / Province");
      validator.current.hideMessageFor("Country");
      validator.current.hideMessageFor("Postal Code");
      const arr = [...addressDetails];
      const addressField = {
        address_type: "",
        street_line_one: "",
        street_line_two: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      };
      arr.splice(index + 1, 0, addressField);
      setaddressDetails(arr);
    } else {
      validator.current.showMessageFor("Address Type");
      validator.current.showMessageFor("Street line one");
      validator.current.showMessageFor("Street line two");
      validator.current.showMessageFor("City");
      validator.current.showMessageFor("State / Province");
      validator.current.showMessageFor("Country");
      validator.current.showMessageFor("Postal Code");
      forceUpdate();
    }
  };
  const deleteAddressField = (index) => {
    const rows = [...addressDetails];
    rows.splice(index, 1);
    setaddressDetails(rows);
  };

  // Add & Remove Email Field
  const addEmailField = (index) => {
    if (validator.current.fieldValid("Email Address")) {
      validator.current.hideMessageFor("Email Address");
      forceUpdate();

      const arr = [...emailDetails];
      const emailField = {
        email: "",
      };
      arr.splice(index + 1, 0, emailField);
      setEmailDetails(arr);
      forceUpdate();
    } else {
      validator.current.showMessageFor("Email Address");
      forceUpdate();
    }
  };
  const deleteEmailField = (index) => {
    const rows = [...emailDetails];
    rows.splice(index, 1);
    setEmailDetails(rows);
  };

  // Add & Remove Phone Field
  const addPhoneField = (index) => {
    if (
      !mobileNoValidation(
        phoneDetails[index].country_code,
        phoneDetails[index].contact_number
      )
    ) {
      setError({ ...error, show: true });
      return;
    }
    if (
      validator.current.fieldValid("Phone Type") &&
      validator.current.fieldValid("Country Code") &&
      validator.current.fieldValid("Contact Number")
    ) {
      validator.current.hideMessageFor("Phone Type");
      validator.current.hideMessageFor("Country Code");
      validator.current.hideMessageFor("Contact Number");
      const arr = [...phoneDetails];
      const phoneField = {
        country_code: "",
        phone_type: "",
        contact_number: "",
      };
      arr.splice(index + 1, 0, phoneField);
      setphoneDetails(arr);
    } else {
      validator.current.showMessageFor("Phone Type");
      validator.current.showMessageFor("Country Code");
      validator.current.showMessageFor("Contact Number");
      forceUpdate();
    }
  };
  const deletePhoneField = (index) => {
    const rows = [...phoneDetails];
    rows.splice(index, 1);
    setphoneDetails(rows);
  };

  // Add & Remove Website Field
  const addWebsiteField = (index) => {
    if (validator.current.fieldValid("Website")) {
      const arr = [...websitesDetails];
      const websiteField = {
        website: "",
      };
      arr.splice(index + 1, 0, websiteField);
      setwebsitesDetails(arr);
      validator.current.hideMessageFor("Website");
    } else {
      validator.current.showMessageFor("Website");
      forceUpdate();
    }
  };
  const deleteWebsiteField = (index) => {
    const rows = [...websitesDetails];
    rows.splice(index, 1);
    setwebsitesDetails(rows);
  };

  useEffect(() => {
    let findSlectedModal = socialNetwokEditData.find(
      (dt) => dt.name === activeModalName
    );
    setSelectedModal(findSlectedModal);
  }, [activeModalName]);

  useEffect(() => {
    if (selectedModal && userData?.links_set?.length > 0) {
      const filterValue = userData?.links_set?.filter(
        (link) => link?.types === selectedModal.name
      );
      // setSelectedLinks([...filterValue]);
      setSelectedLinks({
        ...selectedLinks,
        [selectedModal.name]: [...filterValue],
      });
    } else {
      setSelectedLinks({});
    }
    setActiveAccount({
      ...activeAccount,
      companyName: userData.company_name,
    });
  }, [userData, selectedModal]);

  const incrementModalStep = (type) => {
    if (modalStepCount === 2 || (modalStepCount === 1 && type === "website")) {
      if (type === "social" || type === "review" || type === "website") {
        let payload;
        if (type === "website") {
          payload = {
            types: type,
            url: activeAccount.accUrl,
            meta: {
              title: activeAccount.title,
              companyName: activeAccount.companyName,
            },
            profile: userData.id,
          };
          if (isEditURL) {
            payload.id = activeAccount.id;
            delete payload.type;
          }
        } else {
          payload = {
            types: type,
            url:
              activeAccount.title !== "Whatsapp"
                ? activeAccount.accUrl
                : `https://wa.me/?cc=${countryCode}&mn=${activeAccount.accUrl}`,
            provider: activeAccount.provider,
            meta: {
              ...(type === "social" && {
                companyName: activeAccount.companyName,
              }),
            },
            profile: userData.id,
          };
          if (isEditURL) {
            payload.id = activeAccount.id;
            delete payload.type;
          }
        }
        if (type === "social" && activeAccount.title === "Whatsapp") {
          if (!mobileNoValidation(countryCode, activeAccount.accUrl)) {
            setError({ ...error, show: true });
            return;
          }
        }
        if (validator.current.allValid()) {
          if (
            ((type === "social" || type === "review") &&
              editActiveAccount?.accUrl === activeAccount?.accUrl &&
              activeAccount?.title !== "Whatsapp") ||
            (type === "social" &&
              activeAccount?.title === "Whatsapp" &&
              editActiveAccount?.accUrl === activeAccount?.accUrl &&
              countryCode === editCountryCode)
          ) {
            setActiveAccount({ companyName: userData.company_name });
            setModalStepCount(0);
            setIsEditURL(false);
            return;
          }
          if (
            editActiveAccount.accUrl === activeAccount.accUrl &&
            editActiveAccount.title === activeAccount.title &&
            type === "website"
          ) {
            setActiveAccount({ companyName: userData.company_name });
            setModalStepCount(0);
            setIsEditURL(false);
            return;
          }
          setBtnLoader(true);
          dispatch(
            addUpdateLinks({
              payload,
              loader: () => {
                setBtnLoader(false);
              },
              callback: () => {
                setModalStepCount(0);
                dispatch(
                  getActiveUserProfile(() => {
                    setIsValid(true);
                  })
                );
                setIsEditURL(false);
                setActiveAccount({ companyName: userData.company_name });
                setCountryCode("+1");
              },
            })
          );
        } else {
          validator.current.showMessages();
          forceUpdate();
        }
      } else {
        setModalStepCount(0);
      }
    } else if (type === "contact") {
      const emailArr = emailDetails.map((item) => item.email);
      const webArr = websitesDetails.map((item) => item.website);
      let payload = {};
      if (
        JSON.stringify(userData?.address) !== JSON.stringify(addressDetails)
      ) {
        payload = { ...payload, address: addressDetails };
      }
      if (JSON.stringify(userData?.email) !== JSON.stringify(emailArr)) {
        payload = { ...payload, email: emailArr };
      }
      if (JSON.stringify(userData?.website) !== JSON.stringify(webArr)) {
        payload = { ...payload, website: webArr };
      }
      if (JSON.stringify(userData?.phone) !== JSON.stringify(phoneDetails)) {
        payload = { ...payload, phone: phoneDetails };
      }
      if (error.show) {
        return;
      }
      if (validator.current.allValid()) {
        if (Object.keys(payload).length === 0) {
          setaddressDetails({ profile: userData.id });
          setShowSocialNetworkEditModal(!open);
          setActiveAccount({ companyName: userData.company_name });
          return;
        }
        setBtnLoader(true);
        dispatch(
          UpdateContactInfo(
            payload,
            userData.id,
            () => {
              setBtnLoader(false);
            },
            () => {
              setaddressDetails({ profile: userData.id });
              setShowSocialNetworkEditModal(!open);
              setActiveAccount({ companyName: userData.company_name });
              dispatch(
                getActiveUserProfile(() => {
                  setIsValid(true);
                })
              );
            }
          )
        );
      } else {
        validator.current.showMessages();
        forceUpdate();
      }
    } else if (type === "video") {
      if (validator.current.allValid()) {
        if (
          userData?.video !== null &&
          videoFields.video_description === userData?.video.video_description &&
          videoFields.video_source === userData?.video.video_source &&
          videoFields.video_url === userData?.video.video_url
        ) {
          setShowSocialNetworkEditModal(!open);
          return;
        }
        setBtnLoader(true);
        dispatch(
          userData?.video == null
            ? createVideos(
                { ...videoFields, profile: userData?.id },
                () => {
                  setBtnLoader(false);
                },
                () => {
                  setVideoFields();
                  setShowSocialNetworkEditModal(!open);
                  dispatch(getActiveUserProfile(() => setIsValid(true)));
                }
              )
            : updateVideos(
                videoFields,
                () => {
                  setBtnLoader(false);
                },
                () => {
                  setShowSocialNetworkEditModal(!open);
                  dispatch(getActiveUserProfile(() => setIsValid(true)));
                },
                userData?.video?.id
              )
        );
      } else {
        validator.current.showMessages();
        forceUpdate();
      }
    } else {
      if (
        (type === "social" || type === "review") &&
        !isEditURL &&
        !activeAccount.provider &&
        modalStepCount === 1
      ) {
        return;
      }
      setModalStepCount(modalStepCount + 1);
    }
  };

  useEffect(() => {
    if (activeModalName === "website") {
      if (modalStepCount === 0) {
        setSelectedButtonValue("Add Link");
      } else if (modalStepCount === 1) {
        setSelectedButtonValue("Save");
      } else {
        setModalStepCount(0);
      }
    } else if (activeModalName === "video" || activeModalName === "contact") {
      if (modalStepCount === 0) {
        setSelectedButtonValue("Save");
      } else {
        setShowSocialNetworkEditModal(!open);
        setModalStepCount(0);
      }
    } else {
      if (modalStepCount === 0) {
        setSelectedButtonValue("Add Account");
      } else if (modalStepCount === 1) {
        setSelectedButtonValue("Continue");
      } else {
        setSelectedButtonValue("Save");
      }
    }
  }, [modalStepCount, activeModalName]);

  const decrementModalStep = () => {
    validator.current.hideMessages();
    setError({ ...error, show: false });
    if (modalStepCount === 0) {
      setShowSocialNetworkEditModal(!open);
    }
    if (modalStepCount === 1) {
      setActiveAccount({ companyName: userData.company_name });
    }
    if (modalStepCount === 2) {
      setActiveAccount({
        companyName: userData.company_name,
        provider: activeAccount.provider,
        title: activeAccount.title,
        imgUrl: activeAccount.imgUrl,
      });
    }
    if (modalStepCount === 2 && isEditURL) {
      setModalStepCount(0);
      setIsEditURL(false);
      setActiveAccount({ companyName: userData.company_name });
    } else {
      setModalStepCount(modalStepCount - 1);
    }
  };

  // Handle Submit
  const handleSubmit = (modal) => {
    if (modal === "video") {
      if (videoFields.video_source === "vimeo") {
        if (!validator.current.allValid()) {
          validator.current.showMessages();
          forceUpdate();
          return;
        }
        if (!videoFields.video_url.includes("vimeo.com/")) {
          if (!validator.current.allValid()) {
            validator.current.showMessages();
            forceUpdate();
            return;
          }
          setCustomError(true);
        } else {
          if (!customError) {
            incrementModalStep(selectedModal.name);
            setCustomError(false);
          }
        }
      } else {
        if (
          videoFields.video_url.includes("youtu.be") ||
          videoFields.video_url.includes("youtube.com")
        ) {
          if (!customError) {
            incrementModalStep(selectedModal.name);
            setCustomError(false);
          }
        } else {
          if (!validator.current.allValid()) {
            validator.current.showMessages();
            forceUpdate();
            return;
          }
          setCustomError(true);
        }
      }
    } else {
      incrementModalStep(selectedModal.name);
    }
  };

  const setEditLinkValue = (icon, name, url, id, provider) => {
    let linkURl = url;
    if (name === "Whatsapp") {
      let { code, mobileNo } = getCountryCodeAndMobileNo(url);
      linkURl = mobileNo;
      setCountryCode(code);
      setEditCountryCode(code);
    }
    setModalStepCount(2);
    setIsEditURL(true);
    setEditActiveAccount({
      title: name,
      imgUrl: icon,
      accUrl: linkURl,
      id: id,
      provider: provider,
    });
    setActiveAccount({
      title: name,
      imgUrl: icon,
      accUrl: linkURl,
      id: id,
      provider: provider,
    });
  };

  const confirmDeleteLinks = () => {
    dispatch(
      deleteLinks({
        id: showModal.id,
        callback: () => {
          dispatch(
            getActiveUserProfile(() => {
              setActiveAccount({ companyName: userData.company_name });
              setEditActiveAccount({ ...editActiveAccount, accUrl: "" });
              setCountryCode("+1");
              setShowModal({ show: false });
              setModalBtnLoader(false);
            })
          );
        },
        loader: () => {
          setShowModal({ show: false });
          setModalBtnLoader(false);
        },
      })
    );
  };

  const reviewLinksModal = () => {
    return (
      <>
        {modalStepCount === 0 && (
          <div className="mb-4 px-md-5">
            {/* For applying the drag and drop functionality in local comment out the strict mode  */}
            <DragNDrop
              selectedLinks={selectedLinks?.review}
              setEditLinkValue={setEditLinkValue}
              setShowModal={setShowModal}
              setLoading={setLoading}
              title="review"
            />
          </div>
        )}
        {modalStepCount === 1 && (
          <CommanNetworkAccounts
            activeAccount={activeAccount}
            setActiveAccount={setActiveAccount}
            view={"reviewLinks"}
            selectedLinks={selectedLinks?.review}
          />
        )}
        {modalStepCount === 2 && (
          <div className="mb-4 px-md-5">
            <div className="d-flex align-items-center mb-4">
              <div className="me-3">
                <img
                  className="social_icon mb-0"
                  src={activeAccount.imgUrl}
                  alt="icon"
                />
              </div>
              <h4 className="social-n-title">{activeAccount.title}</h4>
            </div>

            <div className="mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Paste URL here"
                value={activeAccount.accUrl}
                onChange={(e) => {
                  setActiveAccount({
                    ...activeAccount,
                    accUrl: e.target.value,
                  });
                }}
              />
              {validator.current &&
                validator.current.message(
                  "URL",
                  activeAccount.accUrl,
                  "required|webSiteURLRegex",
                  {
                    className:
                      "text-danger simple-react-validator-errors text-left",
                  }
                )}
            </div>
          </div>
        )}
      </>
    );
  };

  let videoTypes = [
    {
      label: "YouTube",
      value: "youtube",
    },
    {
      label: "Vimeo",
      value: "vimeo",
    },
  ];

  const videoModal = () => {
    return (
      <>
        <div className="mb-4 px-md-5">
          <div className="mb-4">
            <TextField
              id="outlined-select-currency"
              select
              fullWidth
              label="Video Source"
              name="video_source"
              value={videoFields?.video_source}
              onChange={handleVideoChange}
            >
              {videoTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {validator.current &&
              validator.current.message(
                "Video Source",
                videoFields.video_source,
                "required|max:50",
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )}
          </div>

          <div className="mb-4">
            <TextField
              fullWidth
              id="outlined-required"
              label="Insert URL here"
              name="video_url"
              placeholder="Enter video url"
              value={videoFields?.video_url}
              onChange={handleVideoChange}
            />
            {customError && (
              <div style={{ fontSize: "14px" }} className="text-danger">
                Please enter url related to video source
              </div>
            )}
            {validator.current &&
              validator.current.message(
                "video url",
                videoFields.video_url,
                "required",
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )}
          </div>
          <div className="mb-4">
            <TextField
              fullWidth
              id="outlined-required"
              label="Video Description"
              name="video_description"
              placeholder="Enter video description"
              value={videoFields?.video_description}
              onChange={handleVideoChange}
            />
            <TextCount
              wordCount={videoFields?.video_description?.length}
              total={250}
            />
            {validator.current &&
              validator.current.message(
                "Video description",
                videoFields.video_description,
                "required|max:250|min:3",
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )}
          </div>
        </div>
      </>
    );
  };

  const contactLinkModal = () => {
    return (
      <>
        <ContactInfo
          addEmailField={addEmailField}
          addressDetails={addressDetails}
          phoneDetails={phoneDetails}
          websitesDetails={websitesDetails}
          emailDetails={emailDetails}
          phoneHandleChange={phoneHandleChange}
          emailHandleChange={emailHandleChange}
          addressHandleChange={addressHandleChange}
          websiteHandleChange={websiteHandleChange}
          addAddressField={addAddressField}
          deleteAddressField={deleteAddressField}
          deleteEmailField={deleteEmailField}
          addPhoneField={addPhoneField}
          addWebsiteField={addWebsiteField}
          deleteWebsiteField={deleteWebsiteField}
          deletePhoneField={deletePhoneField}
          validator={validator}
          error={error}
        />
      </>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleShowSocialNetworkEditModal}
      hideBackdrop
    >
      <div className="custom-modal">
        <h3 className="modal-heading py-4 px-md-5 px-3">
          {activeModalName === "website" && modalStepCount === 1
            ? `Edit Website Links > Add Link`
            : modalStepCount === 1 && activeModalName === "social"
            ? `Edit Social Channels > Add Account`
            : modalStepCount === 1 && activeModalName === "review"
            ? `Edit Review Links > Add Account`
            : selectedModal?.modalName}
        </h3>
        <div className="modal-inner px-md-5 px-3 py-3 modal-scroll">
          {/* Social Network Modal */}
          {loading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {/* {activeModalName === "social" && socialNetworkModal()} */}
          {activeModalName === "social" && (
            <SocialNetworkModal
              modalStepCount={modalStepCount}
              setActiveAccount={setActiveAccount}
              selectedLinks={selectedLinks}
              activeAccount={activeAccount}
              setShowModal={setShowModal}
              validator={validator}
              setEditLinkValue={setEditLinkValue}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              error={error}
              setError={setError}
              setLoading={setLoading}
            />
          )}

          {/* Social Networks Edit > Add Account */}

          {/* Website Links Edit > Add link */}
          {/* {activeModalName === "website" && websiteLinkModal()} */}
          {activeModalName === "website" && (
            <WebSiteLinkDetail
              modalStepCount={modalStepCount}
              setActiveAccount={setActiveAccount}
              selectedLinks={selectedLinks.website}
              activeAccount={activeAccount}
              setIsEditURL={setIsEditURL}
              isEditURL={isEditURL}
              setModalStepCount={setModalStepCount}
              setShowModal={setShowModal}
              validator={validator}
              setEditActiveAccount={setEditActiveAccount}
              setLoading={setLoading}
            />
          )}
          {/* Website Links Edit > Add link */}

          {/* video Links Edit */}
          {activeModalName === "video" && videoModal()}
          {/* video Links Edit */}

          {/* Review Links Edit */}
          {activeModalName === "review" && reviewLinksModal()}
          {/* Review Links Edit */}

          {/* Contact Information Edit */}
          {activeModalName === "contact" && contactLinkModal()}
          {/* Contact Information Edit */}
        </div>

        <div className="modal-button text-center mt-4">
          <button
            className="secondary-btn mx-1 mb-3"
            onClick={decrementModalStep}
          >
            {modalStepCount === 0 ? "Cancel" : "Back"}
          </button>
          <button
            className="primary-btn mx-1"
            disabled={btnloader}
            onClick={() => handleSubmit(selectedModal.name)}
            style={{
              cursor: `${
                selectedButtonValue === "Continue"
                  ? activeAccount.provider
                    ? "pointer"
                    : "default"
                  : "pointer"
              }`,
            }}
          >
            {btnloader ? (
              <CircularProgress
                thickness={6.6}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "white",
                }}
              />
            ) : (
              selectedButtonValue
            )}
          </button>
        </div>
      </div>
      {showModal.show && (
        <DeleteModal
          show={showModal.show}
          setShow={setShowModal}
          handleDeleteProfile={confirmDeleteLinks}
          modalBtnLoader={modalBtnLoader}
          setModalBtnLoader={setModalBtnLoader}
        />
      )}
    </Drawer>
  );
}
