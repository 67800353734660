const intialState = {
  user: [],
  singleUser: {},
  links: [],
  providers: [],
  country_code: [],
  connection: [],
  profileList: [],
  qr_code: "",
  profile_by_name: {},
  activeUserProfile: {},
  cardData: {},
  notification: {},
  profileCardsPosition: [],
};

const userProfileReducer = (state = intialState, action) => {
  switch (action.type) {
    case "USER":
      return {
        ...state,
        user: action.payload,
      };
    case "GET_NOTIFICATION":
      return {
        ...state,
        notification: action.payload,
      };
    case "GET_ACTIVE_PROFILE":
      return {
        ...state,
        activeUserProfile: action.payload,
      };

    case "GET_CARD":
      return {
        ...state,
        cardData: action.payload,
      };
    case "UPDATE_USER_PROFILE":
      const index = state.user.findIndex((usr) => usr.id === action.payload.id);
      const updatedValue = [...state.user];
      updatedValue[index] = action.payload;
      return {
        ...state,
        user: updatedValue,
      };

    case "DELETE_USER_PROFILE":
      const userProfileValue = state.user.filter(
        (use) => use.id !== action.payload
      );
      return {
        ...state,
        user: userProfileValue,
      };

    case "getCodes":
      return {
        ...state,
        country_code: action.payload,
      };

    case "getQRCode":
      return {
        ...state,
        qr_code: action.payload,
      };

    case "getProfileByName":
      return {
        ...state,
        profile_by_name: action.payload,
      };

    case "USER_BY_ID":
      return {
        ...state,
        singleUser: action.payload,
      };

    case "LINKS":
      return {
        ...state,
        links: action.payload,
      };

    case "ADD_EDIT_LINKS":
      const index1 = state.links.findIndex(
        (link) => link.id === action.payload.id
      );
      const updatedValue1 = [...state.links];
      if (index1 !== -1) {
        updatedValue1[index1] = action.payload;
      } else {
        updatedValue1.push(action.payload);
      }
      return {
        ...state,
        links: updatedValue1,
      };

    case "DELETE_LINK":
      const updatedLinks = state.links.filter(
        (link) => link.id !== action.payload
      );
      return {
        ...state,
        links: updatedLinks,
      };
    case "PROVIDER":
      return {
        ...state,
        providers: action.payload,
      };
    case "CONNECTION":
      return {
        ...state,
        connection: action.payload,
      };
    case "USER_ID_PROFILE":
      return {
        ...state,
        profileList: action.payload,
      };
    case "DELETE_CONNECTION":
      const updatedConnection = state.connection.filter(
        (conn) => conn.id !== action.payload
      );
      return {
        ...state,
        connection: updatedConnection,
      };
    case "UPDATE_USER_STATUS":
      const updatedStatusValue = state.user.map((usr) => {
        if (usr.id === action.payload.id) {
          return { ...usr, is_active: true };
        } else {
          return { ...usr, is_active: false };
        }
      });
      return {
        ...state,
        user: updatedStatusValue,
      };
    case "PROFILE_CARDS_POSITION":
      return {
        ...state,
        profileCardsPosition: action.payload,
      };
    default:
      return state;
  }
};

export default userProfileReducer;
