import React from "react";
import { TfiExchangeVertical } from "react-icons/tfi";
import { modifyWhatsappLink } from "../../helper/Helper";
import { MdOutlineModeEdit } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";

export default function CardWithImage({
  link,
  setEditLinkValue,
  setShowModal,
}) {
  return (
    <div className="d-flex align-items-center">
      <TfiExchangeVertical />
      <div className="social-n-card ms-2">
        <div className="">
          <img
            className="social_icon mb-0"
            src={link.provider.icon}
            alt="icon"
          />
        </div>
        <div className="sn-card-title">
          <div className="">
            <h4 className="social-n-title">{link.provider.title}</h4>
            <p className="social-n-link">
              {link.provider.title === "Whatsapp"
                ? modifyWhatsappLink(link.url)
                : link.url}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-center mx-2">
            <MdOutlineModeEdit
              className="ed-btn"
              onClick={() =>
                setEditLinkValue(
                  link.provider.icon,
                  link.provider.title,
                  link.url,
                  link.id,
                  link.provider.id
                )
              }
            />
            <FaRegTrashAlt
              className="ed-btn"
              onClick={() => setShowModal({ show: true, id: link.id })}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
