import { DIGITS_ONLY_REGEX } from "../regexValidation/RegexValidation";

function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;
  if (interval > 1) {
    return checkTheInterval(Math.floor(interval), "year");
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return checkTheInterval(Math.floor(interval), "month");
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return checkTheInterval(Math.floor(interval), "day");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return checkTheInterval(Math.floor(interval), "hour");
  }
  interval = seconds / 60;
  if (interval > 1) {
    return checkTheInterval(Math.floor(interval), "minute");
  }
  return checkTheInterval(Math.floor(interval), "second");
}

function checkTheInterval(period, timeEstimate) {
  return period === 1
    ? `${period} ${timeEstimate}`
    : `${period} ${timeEstimate}s`;
}

const mobileNoValidation = (countryCode, mobileNo) => {
  if (!countryCode || !mobileNo) {
    return true;
  }
  const dialCode = countryCode.replace(/\+/g, "");

  let isValid = false;

  if (mobileNo.length === 0) {
    return isValid;
  }
  if (!DIGITS_ONLY_REGEX.test(mobileNo)) {
    return isValid;
  }
  if (dialCode.length < 3) {
    // Countries with less than 3 digit dial code, 10 digits
    isValid = mobileNo.length === 10;
  } else {
    // Countries with 3 digit dial code, 9 digits
    isValid = mobileNo.length === 9;
  }
  return isValid;
};

const modifyWhatsappLink = (link) => {
  let newUrl = link.replace(/[^\d]/g, "");
  newUrl = "https://wa.me/+" + newUrl;
  return newUrl;
};

const getCountryCodeAndMobileNo = (link) => {
  let url = link;
  let cc = "cc=";
  let mn = "mn=";

  // extract the cc and mn values from the URL
  let ccValue = url.substring(
    url.indexOf(cc) + cc.length,
    url.indexOf("&", url.indexOf(cc))
  );
  let mnValue = url.substring(url.indexOf(mn) + mn.length);
  return { code: ccValue, mobileNo: mnValue };
};

export {
  timeSince,
  mobileNoValidation,
  modifyWhatsappLink,
  getCountryCodeAndMobileNo,
};
