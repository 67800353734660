import React, { useEffect, useState } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getNotification,
  updateNotification,
} from "../Redux/Actions/notificationAction";
import { Backdrop, CircularProgress, Container } from "@mui/material";

const Notifications = () => {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [toggle, setToggle] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [changed, setChanged] = useState(false);
  let userId = localStorage.getItem("settingId");
  const [loading, setLoading] = useState(true);

  const handleSubmit = () => {
    setBtnLoader(true);
    dispatch(
      updateNotification(
        { is_email_notifications_active: toggle },
        userId,
        () => {
          setBtnLoader(false);
          navigate("/settings");
        }
      )
    );
  };

  useEffect(() => {
    dispatch(
      getNotification(userId, (e) => {
        setToggle(e.is_email_notifications_active);
      },
      ()=>setLoading(false))
    );
  }, [dispatch,userId]);

  return (
    <>

      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className="view-card-area mt-10">
        <Container>
          <div className="vh-100">
            <h2 className="profile-card-heading d-flex align-items-center">
              <MdKeyboardBackspace
                className="me-3"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/settings")}
              />
              Notifications
            </h2>
            <div className="mt-4">
              <div className="card-2 mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="notification-taxt">Email Notifications</p>
                  <Switch
                    color="warning"
                    checked={toggle}
                    onChange={() => {
                      setChanged(true);
                      setToggle(!toggle);
                    }}
                  />
                </div>
              </div>
            </div>
            <button
              onClick={handleSubmit}
              disabled={!changed || btnLoader}
              className="primary-btn w-100"
            >
              {btnLoader ? (
                <CircularProgress
                  thickness={6.6}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "white",
                  }}
                />
              ) : (
                "Save"
              )}
            </button>
        </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default Notifications;
