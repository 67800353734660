import { CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { FiLock } from "react-icons/fi";
import { API } from "../config";
import { toast } from "react-toastify";
import { errorMessageAutoCloseOff } from "../common/showToasterMessage";
import {
  EMAIL_ALREADY_EXISTS,
  SOMETHING_WENT_WRONG,
} from "../common/constants/toastMessage";

const Otpform = ({ setOtpView, signupForm, SetSignupForm }) => {
  const [btnLoader, setBtnLoader] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const val = e.target.value;
    if (val.match("^[0-9]{1,4}$")) {
      SetSignupForm({ ...signupForm, otp: val });
      if (val.length === 4) {
        setError(false);
      }
    } else if (val.length > 4) {
      return;
    } else if (val.length === 0) {
      SetSignupForm({ ...signupForm, otp: val });
      setError(true);
    } else {
      setError(true);
    }
  };
  const checkOtp = () => {
    if (signupForm.otp.length < 4) {
      setError(true);
      return;
    }
    let payload = {
      first_name: signupForm.first_name,
      last_name: signupForm.last_name,
      email: signupForm.email,
      password: signupForm.password,
      username: signupForm.username,
      card: signupForm.card,
      invitation_code: signupForm.otp,
    };
    toast.dismiss();
    API.post("user/register/", payload)
      .then((res) => {
        setBtnLoader(false);
        if (res.status === 200) {
          setOtpView(true);
          setBtnLoader(false);
          window.location.href = "/";
        }
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 401) {
          if (err.response.data.email) {
            errorMessageAutoCloseOff(EMAIL_ALREADY_EXISTS);
          } else {
            errorMessageAutoCloseOff(err.response.data.message);
          }
        } else {
          errorMessageAutoCloseOff(SOMETHING_WENT_WRONG);
        }
        setBtnLoader(false);
      });
    setBtnLoader(true);
  };
  return (
    <div className="login-wrap px-3">
      <div className="login-page">
        <div className="text-center">
          <img
            className="site-logo-main mb-5 cursor-pointer"
            src="/images/Logo.svg"
            alt="logo"
            onClick={() => (window.location.href = "/")}
          />
          <h1 className="login-title py-5">Enter OTP</h1>
        </div>
        <div className="">
          <div className="mb-4">
            <TextField
              fullWidth
              id="outlined-required"
              label="Enter your OTP"
              name="otp"
              value={signupForm?.otp}
              onChange={(e) => {
                handleChange(e);
              }}
              InputProps={{
                endAdornment: <FiLock position="end"></FiLock>,
              }}
            />
            {error && (
              <span className="text-danger simple-react-validator-errors text-left">
                Enter 4 digits valid OTP.
              </span>
            )}
          </div>
          <button
            className="login-btn mt-4"
            disabled={btnLoader || error}
            onClick={checkOtp}
          >
            {btnLoader ? (
              <CircularProgress
                thickness={6.6}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "white",
                }}
              />
            ) : (
              "Verify"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Otpform;
