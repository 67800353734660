import axios from "axios";
import { toast } from "react-toastify";
import { API, authAxios, axiosWithoutAuth, baseURL } from "../../config";
import { errorMessageAutoCloseOff } from "../../common/showToasterMessage";

// get Cards
export const getCards = (id, callback) => (dispatch) => {
  axios
    .get(`${baseURL}/user-profile/card/${id}`)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        axiosWithoutAuth
          .get(`/user/profile-cards-postion/${res.data.id}/`)
          .then((res) => {
            dispatch({
              type: "PROFILE_CARDS_POSITION",
              payload: res.data.data,
            });
            callback();
          })
          .catch((err) => {
            // callback();
          });
        // callback();
        //for getting sorted links for showing in the same way as saved
        res.data?.links_set?.sort((a, b) => a.position - b.position);
        // filter the delted url for now backend sending all the urls with a flag is deleted
        const updatedURL = res.data.links_set.filter((re) => !re.is_deleted);
        res.data.links_set = updatedURL;
        dispatch({ type: "GET_CARD", payload: res.data });
      }
      if (res.status === 204) {
        // toast.error("Invalid Card");
        window.location.href = "/404";
        // setTimeout(() => {
        //   window.location.href = "/404";
        // }, 2400);
      }
    })
    .catch((err) => {
      if (err.response.status === 404) {
        // toast.error("Invalid Card");
        window.location.href = "/404";
        // setTimeout(() => {
        //   window.location.href = "/404";
        // }, 2400);
      }
      if (err.response.status === 403) {
        window.location.href = `/signup/${id}`;
      }
    });
};

export const getUsers = (callback) => (dispatch) => {
  authAxios
    .get("user/")
    .then((res) => {
      dispatch({
        type: "USER",
        payload: res.data,
      });
      callback();
    })
    .catch((err) => {
      // toast.error("Something went wrong");
      callback();
    });
};

export const getUserById = (id) => (dispatch) => {
  authAxios
    .get(`user/${id}/`)
    .then((res) => {
      dispatch({
        type: "USER_BY_ID",
        payload: res.data,
      });
    })
    .catch((err) => {});
};

export const getActiveUserProfile = (callback) => (dispatch) => {
  authAxios
    .get("user/active-user-profile/")
    .then((res) => {
      if (res.status === 200) {
        callback();
        //for getting sorted links for showing in the same way as saved
        res.data?.links_set?.sort((a, b) => a.position - b.position);
        // filter the delted url for now backend sending all the urls with a flag is deleted
        const updatedURL = res.data.links_set.filter((re) => !re.is_deleted);
        res.data.links_set = updatedURL;
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("settingId", res.data.settings.id);
        dispatch({
          type: "GET_ACTIVE_PROFILE",
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      callback();
      // toast.error("Something went wrong");
    });
};

export const createUserProfile =
  ({ payload, loader, callback }) =>
  (dispatch) => {
    authAxios
      .post(`user/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          toast.success("Profile added successfully");
          callback();
          loader();
        }
      })
      .catch((err) => {
        loader();
        toast.error("Something went wrong");
      });
  };

export const updateUser =
  ({ loader, id, payload, callback, is_active }) =>
  (dispatch) => {
    authAxios
      .patch(`user/${id}/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "UPDATE_USER_PROFILE",
            payload: res.data,
          });
          // dispatch(getUsers());
          toast.success("Profile update successfully");
          loader();
          callback();
        }
      })
      .catch((err) => {
        loader();
        if (is_active) callback();
        toast.error("Something went wrong");
      });
  };

export const deleteUserProfile =
  ({ id, callback }) =>
  (dispatch) => {
    authAxios
      .delete(`user/${id}/`)
      .then((res) => {
        if (res.status === 204 || res.status === 200) {
          toast.success("Profile delete successfully");
          callback();
          dispatch({
            type: "DELETE_USER_PROFILE",
            payload: id,
          });
        }
      })
      .catch((err) => {
        callback();
        toast.error("Something went wrong");
      });
  };

export const getLinksByID = (id) => (dispatch) => {
  authAxios
    .get(`links/?profile=${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: "LINKS",
          payload: res.data,
        });
      }
    })
    .catch((err) => {});
};

export const addUpdateLinks =
  ({ payload, loader, callback }) =>
  (dispatch) => {
    if (payload.id) {
      let id = payload.id;
      delete payload.id;
      authAxios
        .put(`links/${id}/`, payload)
        .then((res) => {
          loader();
          toast.success("Link updated successfully");
          dispatch({
            type: "ADD_EDIT_LINKS",
            payload: res.data,
          });
          callback();
        })
        .catch((err) => {
          loader();
          if (
            err?.response?.status === 400 &&
            err?.response?.data?.url.length > 0
          ) {
            toast.error(err.response.data.url[0]);
          } else {
            toast.error("Something went wrong");
          }
        });
    } else {
      authAxios
        .post("links/", payload)
        .then((res) => {
          loader();
          dispatch({
            type: "ADD_EDIT_LINKS",
            payload: res.data,
          });
          callback();
          toast.success("Link added successfully");
        })
        .catch((err) => {
          loader();
          if (
            err?.response?.status === 400 &&
            err?.response?.data?.url.length > 0
          ) {
            toast.error(err.response.data.url[0]);
          } else {
            toast.error("Something went wrong");
          }
        });
    }
  };

export const updateLinksPosition =
  ({ payload, callback, loader }) =>
  (dispatch) => {
    authAxios
      .post(`links/rearrange-links/`, payload)
      .then((res) => {
        callback();
      })
      .catch((err) => {
        loader();
        // toast.error("Something went wrong");
      });
  };

export const deleteLinks =
  ({ id, callback, loader }) =>
  (dispatch) => {
    authAxios
      .delete(`links/${id}/`)
      .then((res) => {
        if (res.status === 204 || res.status === 200) {
          toast.success("Link delete successfully");
          callback();
          // loader();
          dispatch({
            type: "DELETE_LINK",
            payload: id,
          });
        }
      })
      .catch((err) => {
        loader();
        toast.error("Something went wrong");
      });
  };

export const getProviders = (callback) => (dispatch) => {
  authAxios.get("providers/").then((res) => {
    if (res.status === 200 || res.status === 201) {
      dispatch({
        type: "PROVIDER",
        payload: res.data,
      });
      callback();
    }
  });
};

// Get Country Codes

export const getCountryCodes = () => (dispatch) => {
  authAxios
    .get("country-code")
    .then((res) => {
      dispatch({
        type: "getCodes",
        payload: res.data,
      });
    })
    .catch((err) => {
      // toast.error("Something went wrong");
    });
};

// Get QR Code

export const getQRCode = (id) => (dispatch) => {
  authAxios
    .get(`qr-code/${id}`)
    .then((res) => {
      dispatch({
        type: "getQRCode",
        payload: res.data.qr_code,
      });
    })
    .catch((err) => {
      // toast.error("Something went wrong");
    });
};

// Get Profile By Name

export const getProfile_by_name = (name, callback) => (dispatch) => {
  axios
    .get(`${baseURL}/user-profile/user/profile-name/${name}`)
    .then((res) => {
      // getProfilePosition(res.data.id);
      axiosWithoutAuth
        .get(`/user/profile-cards-postion/${res.data.id}/`)
        .then((res) => {
          dispatch({ type: "PROFILE_CARDS_POSITION", payload: res.data.data });
          callback();
        })
        .catch((err) => {
          // callback();
        });
      // callback();
      //for getting sorted links for showing in the same way as saved
      res.data?.links_set?.sort((a, b) => a.position - b.position);
      // filter the delted url for now backend sending all the urls with a flag is deleted
      const updatedURL = res.data.links_set.filter((re) => !re.is_deleted);
      res.data.links_set = updatedURL;
      dispatch({
        type: "getProfileByName",
        payload: res.data,
      });
    })
    .catch((err) => {
      window.location.href = "/404";
      callback();
    });
};

// Check Profile By Name
export const checkProfileByName = (name, loader, callback) => (dispatch) => {
  authAxios
    .get(`user/check-profile-name/${name}`)
    .then((res) => {
      if (res.data.message === "Available") {
        loader();
        callback();
      } else {
        loader("This profile name is " + res.data.message + "!");
        // toast.error("this profile name " + res.data.message + "!");
      }
    })
    .catch((err) => {
      if (err?.response?.status === 400) {
        loader("This profile name is " + err.response.data.message + "!");
      } else {
        loader();
        toast.error("Something went wrong");
      }
    });
};

// share your profile details
export const shareProfile = (formData, callback, loader) => (dispatch) => {
  axios
    .post(`${baseURL}/user-profile/connections/`, formData)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        toast.success("Successfully shared your detail");
        callback();
      }
    })
    .catch((err) => {
      if (err.response.status === 404) {
        toast.error(err.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
      loader();
    });
};

// create videos
export const createVideos = (formData, loader, callback) => (dispatch) => {
  authAxios
    .post(`video/`, formData)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        toast.success("Video created successfully");
        callback();
        loader();
      }
    })
    .catch((err) => {
      loader();
      toast.error("Something went wrong");
    });
};

// updateVideos
export const updateVideos =
  (formData, loader, callback, video_id) => (dispatch) => {
    authAxios
      .patch(`video/${video_id}/`, formData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Video updated successfully");
          callback();
          loader();
        }
      })
      .catch((err) => {
        loader();
        toast.error("Something went wrong");
      });
  };

// reset password
export const changeCreditionals =
  (formData, navigate, loader) => (dispatch) => {
    toast.dismiss();
    API.put(`reset-password/`, formData, {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data.message);
          loader();
          navigate();
        }
      })
      .catch((err) => {
        loader();
        if (err.response.status === 400) {
          errorMessageAutoCloseOff(err.response.data.password[0]);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

// forgot password
export const forgotPassword = (formData, loader) => (dispatch) => {
  API.post(`forget-password/`, formData)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        toast.success("Link has been sent to your email.");
        setTimeout(() => {
          window.location.href = "/login";
          loader();
        }, 1500);
      }
    })
    .catch((err) => {
      loader();
      if (err.response.status === 400) {
        toast.error(err.response.data.email[0]);
      } else {
        toast.error("Something went wrong");
      }
    });
};
