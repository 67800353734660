const SELECT_LIST_ITEM = [
  "Accounting",
  "Agriculture",
  "Art",
  "Auto",
  "B2B Services",
  "Construction",
  "Consulting",
  "Cosmetics",
  "E-Commerce",
  "Education",
  "Entertainment",
  "Finance",
  "Food",
  "Government",
  "Healthcare",
  "Home Services",
  "Hospitality",
  "Insurance",
  "Law",
  "Manufacturing",
  "Marketing",
  "Media",
  "Mining",
  "Other",
  "Real Estate",
  "Retail",
  "Science",
  "Tech",
  "Utilities",
  "Wholesale Trade",
];

const GET_YOUR_CARD_LINK = "https://www.oamiicards.com/pricing";


export {SELECT_LIST_ITEM, GET_YOUR_CARD_LINK}