import React, { useEffect, useState } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { FiCheckCircle, FiTrash2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserProfile,
  getUsers,
} from "../Redux/Actions/userProfileAction";
import { updateStatus } from "../Redux/Actions/contactAction";
import { useNavigate } from "react-router";
import DeleteModal from "../common/DeleteModal";
import { Backdrop, CircularProgress } from "@mui/material";
import { Container } from "@mui/material";
import ManageProfileAlert from "../alert/ManageProfileAlert";

const ManageProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userProfileReducer.user);
  const [modalShow, setModalShow] = useState({ show: false, id: "" });
  const [loader, setLoader] = useState({ loading: false, id: "" });
  const [loading, setLoading] = useState(true);
  const [modalBtnLoader, setModalBtnLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    dispatch(getUsers(() => setLoading(false)));
  }, [dispatch]);

  const handleDeleteProfile = () => {
    dispatch(
      deleteUserProfile({
        id: modalShow.id,
        callback: () => {
          setModalShow({ show: false });
          setModalBtnLoader(false);
        },
      })
    );
  };

  const updateUserProfileStatus = (id, status) => {
    setLoader({ loading: true, id: id });
    const fd = new FormData();
    fd.append("is_active", !status);
    dispatch(
      updateStatus({
        loader: () => {
          setLoader({ loading: false, id: "" });
        },
        id: id,
        payload: fd,
        is_active: true,
      })
    );
    // dispatch(updateUser({id}))
  };

  return loading ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <>
      <div className="view-card-area mt-10">
        {showAlert && userData?.length > 1 && (
          <ManageProfileAlert setShowAlert={setShowAlert} />
        )}
        <Container>
          <div className="vh-100">
            <h2 className="profile-card-heading d-flex align-items-center py-3">
              <MdKeyboardBackspace
                className="me-3"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/profile-edit-view")}
              />
              Manage Profile
            </h2>
            {userData.length === 0 && (
              <div className="me-2 nodata-found">No Profiles here</div>
            )}
            {userData.length > 0 &&
              userData.map((user) => (
                <div className="card-2 mb-3" key={user.id}>
                  <div className="social-n-card ms-2">
                    <div className="me-3">
                      <img
                        className="user-icon mb-0"
                        src={user.profile_picture}
                        alt="icon"
                      />
                    </div>
                    <div className="sn-card-title">
                      <div
                        className=""
                        style={{
                          cursor: `${user.is_active ? "pointer" : "default"}`,
                        }}
                        onClick={() =>
                          user.is_active
                            ? navigate("/profile-edit-view")
                            : false
                        }
                      >
                        <h4 className="social-n-title">
                          <span style={{ fontSize: "13px", fontWeight: "800" }}>
                            /
                          </span>
                          {user.profile_name}
                        </h4>
                        <p className="manage-profile-designation">
                          {/* President & CEO at Oamii Digital Marketing Agency */}
                          {user.first_name} {user.last_name}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center mx-2">
                        {user.is_active ? (
                          <>
                            <span
                              className="active-label"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Active
                            </span>
                          </>
                        ) : (
                          <>
                            {loader.loading && loader.id === user.id ? (
                              <span className="mx-2 mt-1">
                                <CircularProgress
                                  thickness={6.6}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    color: "rgb(245,138,31)",
                                  }}
                                />
                              </span>
                            ) : (
                              <FiCheckCircle
                                className="m-share-icon me-2"
                                onClick={() =>
                                  updateUserProfileStatus(
                                    user.id,
                                    user.is_active
                                  )
                                }
                              />
                            )}
                            <FiTrash2
                              className="m-share-icon"
                              onClick={() =>
                                setModalShow({ show: true, id: user.id })
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <button
              className="secondary-btn w-100"
              onClick={() => navigate("/create-profile")}
            >
              Create New Profile
            </button>
          </div>
        </Container>
      </div>
      {modalShow.show && (
        <DeleteModal
          show={modalShow.show}
          setShow={setModalShow}
          handleDeleteProfile={handleDeleteProfile}
          setModalBtnLoader={setModalBtnLoader}
          modalBtnLoader={modalBtnLoader}
        />
      )}
    </>
  );
};

export default ManageProfile;
