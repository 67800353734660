import React, { useEffect, useRef, useState } from "react";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import {
  getActiveUserProfile,
  updateUser,
} from "../Redux/Actions/userProfileAction";
import SimpleReactValidator from "simple-react-validator";
import { CircularProgress } from "@mui/material";
import { FIRST_NAME_REGEX } from "../regexValidation/RegexValidation";
import TextCount from "./TextCount";
import Compressor from "compressorjs";

export default function MuiDrawer({
  open,
  activeModal,
  handleShowProfileEditModal,
  userData,
}) {
  const validator = useRef(
    new SimpleReactValidator({
      validators: {
        nameRegex: {
          message: "The :attribute only contain letter space comma and period.",
          rule: (val, value, validator) => {
            return validator.helpers.testRegex(val, FIRST_NAME_REGEX);
          },
          required: true,
        },
      },
    })
  );
  const [value, setValue] = useState(0);
  const [editForm, setEditForm] = useState({
    profile_picture: "",
    first_name: "",
    last_name: "",
    position: "",
    company_name: "",
  });
  const [updateForm, setUpdateForm] = useState({});
  const [imgError, setImageError] = useState({ show: false, error: "" });

  const [profileImg, setProfileImg] = useState("");
  const dispatch = useDispatch();
  const [isInput, setIsinput] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  useEffect(() => {
    setEditForm({
      profile_picture: userData?.profile_picture,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      position: userData?.position,
      company_name: userData?.company_name,
    });
    setProfileImg(userData?.profile_picture);
  }, []);

  const forceUpdate = () => {
    setValue(value + 1);
  };

  const handleChange = (e, count) => {
    if (e.target.name === "profile_picture") {
      if (e.target.files[0]) {
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
          setImageError({
            show: true,
            error: "Please upload vaild format image(jpg,jpeg,png)",
          });
          setProfileImg("/images/dummyUser.jpg");
          // return;
        } else if (e.target.files[0].size / 1000 > 1.5e7) {
          setImageError({
            show: true,
            error: "Profile Picture should be less then 15mb",
          });
          setProfileImg("/images/dummyUser.jpg");
        } else {
          const image = e.target.files[0];
          console.log(e.target.files[0]);
          new Compressor(image, {
            size: 5, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 1500, // the max width of the output image, defaults to 1920px
            maxHeight: 1500, // the max height of the output image, defaults to 1920px
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
            rotate: false, // See the rotation section below
            success: (compressedResult) => {
              var file = new File([compressedResult], compressedResult.name, {
                type: compressedResult.type,
                lastModified: Date.now(),
              });
              setEditForm({ ...editForm, profile_picture: file });
              setProfileImg(URL.createObjectURL(file));
              setUpdateForm({ ...updateForm, profile_picture: file });
            },
          });
          setImageError({ show: false, error: "" });
        }
      }
    } else {
      if (editForm[e.target.name].length === 0 && e.target.value === " ") {
        return;
      }
      if (e.target.value.length > count) {
        return;
      }
      setEditForm({ ...editForm, [e.target.name]: e.target.value });
      setUpdateForm({ ...updateForm, [e.target.name]: e.target.value });
    }
  };
  const updateUserProfile = () => {
    if (validator.current.allValid() && !imgError.show) {
      if (Object.keys(updateForm).length === 0) {
        handleShowProfileEditModal();
        return;
      }
      let fd = new FormData();
      for (let key in updateForm) {
        fd.append(key, updateForm[key]);
      }
      setBtnLoader(true);
      dispatch(
        updateUser({
          loader: () => setBtnLoader(false),
          id: userData.id,
          payload: fd,
          callback: () => {
            // dispatch(getUsers());
            dispatch(getActiveUserProfile(() => setIsinput(true)));
            handleShowProfileEditModal();
          },
        })
      );
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  return (
    <Drawer
      hideBackdrop
      anchor="right"
      open={open}
      onClose={handleShowProfileEditModal}
    >
      {activeModal === "about me" && (
        <div className="custom-modal">
          <h3 className="modal-heading py-4 px-md-5 px-3">Edit Profile</h3>
          <div className="modal-inner px-md-5 px-3 py-3 modal-scroll">
            <div className="px-md-5">
              <div className="change-user-pic">
                {/* <img src="/images/Union.png" alt="profile-pic"></img> */}
                <img src={profileImg} alt="profile-pic"></img>
                <div className="change-img-btn">
                  <input
                    type="file"
                    id="upload"
                    name="profile_picture"
                    onChange={handleChange}
                    hidden
                  />
                  <label htmlFor="upload">Change Image</label>
                  {validator.current &&
                    validator.current.message(
                      "Image",
                      editForm.profile_picture,
                      "required",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                {imgError.show && (
                  <span className="text-danger simple-react-validator-errors text-left">
                    {imgError.error}
                  </span>
                )}
              </div>
              <div className="my-5">
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="First Name"
                    name="first_name"
                    // defaultValue="Jeck |"
                    value={editForm.first_name}
                    onChange={(e) => handleChange(e, 50)}
                  />
                  <TextCount
                    wordCount={editForm.first_name?.length}
                    total={50}
                  />
                  {validator.current &&
                    validator.current.message(
                      "First Name",
                      editForm.first_name.trim(),
                      "required|nameRegex|max:50|min:2",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="Last Name"
                    name="last_name"
                    // defaultValue="Martin"
                    onChange={(e) => handleChange(e, 50)}
                    value={editForm.last_name}
                  />
                  <TextCount
                    wordCount={editForm?.last_name?.length}
                    total={50}
                  />

                  {validator.current &&
                    validator.current.message(
                      "Last Name",
                      editForm.last_name.trim(),
                      "required|nameRegex|max:50|min:2",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="Position / position"
                    name="position"
                    // defaultValue="President & CEO"
                    onChange={(e) => handleChange(e, 20)}
                    value={editForm.position}
                  />
                  <TextCount wordCount={editForm.position?.length} total={20} />
                  {validator.current &&
                    validator.current.message(
                      "Position / role",
                      editForm.position,
                      "required|string|max:20|min:2",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="Company Name"
                    name="company_name"
                    // defaultValue="Oamii Digital Marketing Agency"
                    onChange={(e) => handleChange(e, 100)}
                    value={editForm.company_name}
                  />
                  <TextCount
                    wordCount={editForm.company_name?.length}
                    total={100}
                  />
                  {validator.current &&
                    validator.current.message(
                      "Company Name",
                      editForm.company_name,
                      "required|string|max:100|min:2",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="modal-button text-center mt-4">
            <button
              className="secondary-btn mx-1 mb-3"
              onClick={handleShowProfileEditModal}
            >
              Cancel
            </button>
            <button
              disabled={btnLoader}
              className="primary-btn mx-1"
              onClick={updateUserProfile}
            >
              {btnLoader ? (
                <CircularProgress
                  thickness={6.6}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "white",
                  }}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      )}
    </Drawer>
  );
}
