import React from "react";
import { FaGlobeAmericas, FaPencilAlt } from "react-icons/fa";
import { CheckWebsiteUrl } from "../../common/functions/checkWebSiteUrl";
import UpDownIcon from "./UpDownIcon";

const EditWebsiteLinks = ({
  filteredWebsite,
  handleShowSocialNetworkEditModal,
  activeUserData,
}) => {
  return (
    <div className="d-flex align-items-center jsutify-content-center">
      <UpDownIcon />

      <div className="card mt-4 w-100">
        <div className="d-flex align-items-center justify-content-between mb-5 px-md-3">
          <h2 className="card-heading me-2">WEBsite links</h2>
          <button
            className="edit-button"
            onClick={() => handleShowSocialNetworkEditModal("website")}
          >
            <FaPencilAlt className="me-2" />
            Edit
          </button>
        </div>
        {filteredWebsite?.length === 0 && (
          <div className="me-2 nodata-found">No website links</div>
        )}
        {activeUserData?.links_set &&
          filteredWebsite.map((link) => {
            return (
              <React.Fragment key={link.id}>
                <div
                  className="website-link-list mb-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open(CheckWebsiteUrl(link.url))}
                >
                  <div className="d-flex align-items-center websites_link">
                    <FaGlobeAmericas className="glob-icon me-3" />
                    <p className="wesite_name">{link.meta.title}</p>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default EditWebsiteLinks;
