import "./App.css";
import "./style.css";
import "./responsive.css";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./PrivateRoute";
import SessionRoute from "./SessionRoute";
import { route } from "./routing";
import "react-toastify/dist/ReactToastify.css";
import React from "react";

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        {route.map((rout, i) =>
          rout.private && !rout.public ? (
            <Route element={<PrivateRoute />} key={i}>
              <Route path={rout.path} element={rout.element} />
            </Route>
          ) : rout.public ? (
            <Route path={rout.path} element={rout.element} key={i} />
          ) : (
            <Route element={<SessionRoute />} key={i}>
              <Route path={rout.path} element={rout.element} />
            </Route>
          )
        )}
      </Routes>
    </>
  );
}

export default App;
