import React, { useState, useRef } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import TextField from "@mui/material/TextField";
import SimpleReactValidator from "simple-react-validator";
import { Container } from "react-bootstrap";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SELECT_LIST_ITEM } from "../constants/constants";
import TextCount from "../common/TextCount";
import { FIRST_NAME_REGEX } from "../regexValidation/RegexValidation";
import Compressor from "compressorjs";

const ShareYourDetail = ({
  setStep,
  formData,
  setFormData,
  setShowImg,
  showImg,
}) => {
  const [value, setValue] = useState(0);
  const validator = useRef(
    new SimpleReactValidator({
      validators: {
        nameRegex: {
          message: "The :attribute only contain letter space comma and period.",
          rule: (val, value, validator) => {
            return validator.helpers.testRegex(val, FIRST_NAME_REGEX);
          },
          required: true,
        },
      },
    })
  );
  const [imgError, setImageError] = useState({ show: false, error: "" });

  const forceUpdate = () => {
    setValue(value + 1);
  };
  const handleChange = (e, count) => {
    if (e.target.name === "profile_picture") {
      if (e.target.files[0]) {
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
          setImageError({
            show: true,
            error: "Please upload vaild format image(jpg,jpeg,png)",
          });
          setShowImg("/images/dummyUser.jpg");
          // return;
        } else if (e.target.files[0].size > 1.5e7) {
          setImageError({
            show: true,
            error: "Profile Picture should be less then 15mb",
          });
          setShowImg("/images/dummyUser.jpg");
        } else {
          const image = e.target.files[0];
          console.log(e.target.files[0])
          new Compressor(image, {
            size: 6, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 1920, // the max width of the output image, defaults to 1920px
            maxHeight: 1920, // the max height of the output image, defaults to 1920px
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
            rotate: false, // See the rotation section below
            success: (compressedResult) => {
              var file = new File([compressedResult], compressedResult.name, {type: compressedResult.type, lastModified: Date.now()});
              setShowImg(URL.createObjectURL(file));
              setFormData({ ...formData, profile_picture: file });
            },
          });
          setImageError({ show: false, error: "" });
        }
      }
    } else {
      if (formData[e.target.name].length === 0 && e.target.value === " ") {
        return;
      }
      if (e.target.value.length > count) {
        return;
      }
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleNextPage = () => {
    if (validator.current.allValid() && !imgError.show) {
      setStep(2);
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };
  return (
    <>
      <div className="view-card-area mt-10">
        <Container>
          <div className="create-profile-progress wp-50 mb-2"></div>
          <div className="">
            <h2 className="profile-card-heading d-flex align-items-center py-4">
              <MdKeyboardBackspace
                className="me-3"
                style={{ cursor: "pointer" }}
                onClick={() => setStep(0)}
              />
              Share your details 
            </h2>
            <div className="mt-4">
              <div className="mb-5">
                <div className="d-md-flex align-items-center">
                  <div className="upload-user-pic text-center">
                    <img
                      src={showImg !== "" ? showImg : "/images/dummyUser.jpg"}
                      alt="profile-pic"
                    ></img>
                  </div>
                  <div className="change-img-btn text-center w-100">
                    <input
                      type="file"
                      id="upload"
                      hidden
                      onChange={(e) => handleChange(e, 20)}
                      name="profile_picture"
                    />
                    <label htmlFor="upload">Upload Image</label>
                  </div>
                </div>
                {validator.current &&
                  validator.current.message(
                    "Profile Picture",
                    formData?.profile_picture,
                    "required",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
                {imgError.show && (
                  <span className="text-danger simple-react-validator-errors text-left">
                    {imgError.error}
                  </span>
                )}
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="First Name"
                  onChange={(e) => handleChange(e, 50)}
                  name="first_name"
                  value={formData?.first_name}
                />
                <TextCount
                  wordCount={formData?.first_name?.length}
                  total={50}
                />
                {validator.current &&
                  validator.current.message(
                    "First Name",
                    formData?.first_name.trim(),
                    "required|nameRegex|max:50|min:2",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="Last Name"
                  onChange={(e) => handleChange(e, 50)}
                  name="last_name"
                  value={formData?.last_name}
                />
                <TextCount wordCount={formData?.last_name?.length} total={50} />
                {validator.current &&
                  validator.current.message(
                    "Last Name",
                    formData?.last_name.trim(),
                    "required|nameRegex|max:50|min:2",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="Company Name"
                  onChange={(e) => handleChange(e, 100)}
                  name="company_name"
                  value={formData?.company_name}
                />
                <TextCount
                  wordCount={formData?.company_name?.length}
                  total={100}
                />
                {validator.current &&
                  validator.current.message(
                    "company Name",
                    formData?.company_name,
                    "required|string|max:100|min:3",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="Position / Role"
                  onChange={(e) => handleChange(e, 20)}
                  name="position"
                  value={formData?.position}
                />
                <TextCount wordCount={formData?.position?.length} total={20} />
                {validator.current &&
                  validator.current.message(
                    "Position / role",
                    formData?.position,
                    "required|string|max:20|min:2",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
              <div className="mb-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Industry or Category of work
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData?.industry}
                    label="Industry or Category of work"
                    name="industry"
                    onChange={(e) => handleChange(e, 50)}
                  >
                    {SELECT_LIST_ITEM?.map((itm) => (
                      <MenuItem value={itm} key={itm}>
                        {itm}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {validator.current &&
                  validator.current.message(
                    "industry",
                    formData?.industry,
                    "required|max:50|min:2|string",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Bio Details"
                  multiline
                  rows={3}
                  onChange={(e) => handleChange(e, 1000)}
                  name="bio_details"
                  value={formData?.bio_details}
                />
                <TextCount
                  wordCount={formData?.bio_details?.length}
                  total={1000}
                />
                {validator.current &&
                  validator.current.message(
                    "Bio Details",
                    formData?.bio_details,
                    "required|max:1000|min:3|string",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
            </div>
            <button className="primary-btn w-100" onClick={handleNextPage}>
              Next
            </button>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ShareYourDetail;
