import React from "react";

const PageNotFound = () => {
  return (
    <>
      <div className="h-100 d-flex justify-content-center py-5 mt-5 flex-column align-items-center">
        <img src="/images/404.jpg" alt="404" style={{ maxWidth: "550px" }} />
        <button
          className="primary-btn m-1 text-uppercase text-underline"
          onClick={() => (window.location.href = "/")}
        >
          {" "}
          <u> Bring me to home</u>
        </button>
      </div>
    </>
  );
};

export default PageNotFound;
