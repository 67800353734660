import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProfile_by_name } from "../Redux/Actions/userProfileAction";
import ProfileViewClassic from "./ProfileViewClassic";
import ProfileViewProfessional from "./ProfileViewProfessional";

const GetProfile = () => {
  const location = useParams();
  const name = location.name;
  const dispatch = useDispatch();
  const profileData = useSelector(
    (state) => state.userProfileReducer.profile_by_name
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getProfile_by_name(name, () => setLoading(false)));
  }, [dispatch, name]);


  return (
    <div>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          {profileData.settings?.theme === "professional" ? (
            <ProfileViewProfessional
              profileData={profileData}
              bgColor={profileData.settings?.theme_color}
              remove={false}
            />
          ) : (
            <ProfileViewClassic
              profileData={profileData}
              bgColor={profileData.settings?.theme_color}
              remove={false}
            />
          )}
        </>
      )}
    </div>
  );
};

export default GetProfile;
