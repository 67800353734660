import React from "react";
import { FaPencilAlt } from "react-icons/fa";
import { CheckWebsiteUrl } from "../../common/functions/checkWebSiteUrl";
import UpDownIcon from "./UpDownIcon";
import { modifyWhatsappLink } from "../../helper/Helper";

const EditSocialNetwork = ({
  handleShowSocialNetworkEditModal,
  filteredNetworks,
  activeUserData,
}) => {
  return (
    <div className="d-flex align-items-center jsutify-content-center">
      <UpDownIcon />
      <div className="card mt-4 w-100">
        <div className="d-flex align-items-center justify-content-between mb-5 px-md-3">
          <h2 className="card-heading me2">Social Channels</h2>
          <button
            className="edit-button"
            onClick={() => handleShowSocialNetworkEditModal("social")}
          >
            <FaPencilAlt className="me-2" />
            Edit
          </button>
        </div>
        {filteredNetworks?.length === 0 ? (
          <div className="nodata-found">No social networks</div>
        ) : (
          <div className="d-flex flex-wrap">
            {activeUserData?.links_set &&
              filteredNetworks.map((link) => {
                return (
                  <React.Fragment key={link.id}>
                    <div
                      className="social-icon"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(
                          CheckWebsiteUrl(
                            link.provider.title === "Whatsapp"
                              ? modifyWhatsappLink(link.url)
                              : link.url
                          )
                        )
                      }
                    >
                      <img
                        className="social_icon"
                        src={link.provider.icon}
                        alt="icon"
                      />
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditSocialNetwork;
