import { toast } from "react-toastify";

const errorMessageAutoCloseOff = (msg) => {
  toast.dismiss();
  toast.error(msg, { autoClose: false });
};

const successMessageAutoCloseOff = (msg) => {
  toast.dismiss();
  toast.success(msg, { autoClose: false });
}

export { errorMessageAutoCloseOff, successMessageAutoCloseOff };
