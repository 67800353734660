import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch, useSelector } from "react-redux";
import { shareProfile } from "../Redux/Actions/userProfileAction";
import {
  FIRST_NAME_REGEX,
  PHONE_NO_WITH_COUNTRY_CODE,
} from "../regexValidation/RegexValidation";
import { mobileNoValidation } from "../helper/Helper";

const ShareProfileModal = ({
  id,
  opensharedetail,
  handleCloseShareDetail,
  countryCodes,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    profile: "",
    name: "",
    email: "",
    contact_number: "",
    country_code: "+1",
    first_name: "",
    last_name: "",
    company_name: "",
  });
  const [error, setError] = useState({
    show: false,
    msg: "Enter valid phone number",
  });
  const validator = useRef(
    new SimpleReactValidator({
      validators: {
        nameRegex: {
          message: "The :attribute only contain letter space comma and period.",
          rule: (val, value, validator) => {
            return validator.helpers.testRegex(val, FIRST_NAME_REGEX);
          },
          required: true,
        },
        numberRegex: {
          message:
            "Enter a valid international phone number with the plus sign such as +15612284111",
          rule: (val, value, validator) => {
            return validator.helpers.testRegex(val, PHONE_NO_WITH_COUNTRY_CODE);
          },
          required: true,
        },
      },
    })
  );
  const [value, setValue] = useState(0);
  const [btnLoader, setBtnLoader] = useState(false);

  useEffect(() => {
    setFormData({ ...formData, profile: id });
  }, [id]);

  const handleChange = (event) => {
    if (event.target.name === "contact_number") {
      if (!mobileNoValidation(formData.country_code, event.target.value)) {
        setError({ ...error, show: true });
      } else {
        setError({ ...error, show: false });
      }
    }
    if (event.target.name === "country_code") {
      if (!mobileNoValidation(event.target.value, formData.contact_number)) {
        setError({ ...error, show: true });
      } else {
        setError({ ...error, show: false });
      }
    }
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const forceUpdate = () => {
    setValue(value + 1);
  };
  const handleCloseModal = () => {
    validator.current.hideMessages();
    handleCloseShareDetail();
    setBtnLoader(false);
    setError({ ...error, show: false });
    setFormData({
      profile: id,
      name: "",
      email: "",
      contact_number: "",
      country_code: "+1",
      first_name: "",
      last_name: "",
      company_name: "",
    });
  };
  const handleSubmit = () => {
    if (!mobileNoValidation(formData.country_code, formData.contact_number)) {
      setError({ ...error, show: true });
      return;
    }
    setError({ ...error, show: false });
    if (validator.current.allValid()) {
      setBtnLoader(true);
      let payload = { ...formData };
      payload.name = `${payload.first_name} ${payload.last_name}`;
      payload.contact_number = `${payload.country_code}${payload.contact_number}`;
      delete payload.first_name;
      delete payload.last_name;
      delete payload.country_code;
      dispatch(
        shareProfile(
          payload,
          () => {
            setFormData({ profile: id });
            handleCloseModal();
          },
          () => setBtnLoader(false)
        )
      );
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  return (
    <Modal
      //   hideBackdrop
      open={opensharedetail}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className="share-profile-modal">
        <h2 className="modal-title text-center mb-4" id="modal-title">
          Share Your Details
        </h2>
        <div className="" id="modal-description">
          <div className="mb-4">
            <TextField
              fullWidth
              id="outlined-required"
              label="First Name"
              name="first_name"
              value={formData?.first_name}
              onChange={handleChange}
              placeholder="Enter your full name"
            />
            {validator.current &&
              validator.current.message(
                "First Name",
                formData?.first_name,
                "required|nameRegex|max:50|min:2",
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )}
          </div>
          <div className="mb-4">
            <TextField
              fullWidth
              id="outlined-required"
              label="Last Name"
              name="last_name"
              value={formData?.last_name}
              onChange={handleChange}
              placeholder="Enter your last name"
            />
            {validator.current &&
              validator.current.message(
                "Last Name",
                formData?.last_name,
                "required|nameRegex|max:50|min:2",
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )}
          </div>
          <div className="mb-4">
            <TextField
              fullWidth
              id="outlined-required"
              label="Email Address"
              name="email"
              value={formData?.email}
              onChange={handleChange}
              placeholder="Enter your email address"
            />
            {validator.current &&
              validator.current.message(
                "Email Address",
                formData?.email,
                "required|email|max:50",
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )}
          </div>
          <div className="mb-4">
            <TextField
              id="outlined-select-currency"
              select
              fullWidth
              label="Country Code"
              value={formData?.country_code}
              name="country_code"
              onChange={handleChange}
            >
              {countryCodes?.length > 0 && countryCodes?.map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {validator.current &&
              validator.current.message(
                "Country Code",
                formData.country_code,
                "required",
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )}
          </div>
          <div className="mb-4">
            <TextField
              fullWidth
              id="outlined-required"
              label="Contact Number"
              name="contact_number"
              value={formData?.contact_number}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment className="me-0" position="start">
                    {formData?.country_code}
                  </InputAdornment>
                ),
              }}
            />
            {validator.current &&
              validator.current.message(
                "Contact Number",
                formData?.contact_number,
                "required",
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )}
            {error.show && (
              <span className="text-danger simple-react-validator-errors text-left">
                {error.msg}
              </span>
            )}
          </div>
          <div className="mb-4">
            <TextField
              fullWidth
              id="outlined-required"
              label="Company Name (Optional)"
              name="company_name"
              value={formData?.company_name}
              onChange={handleChange}
              placeholder="Enter your company name"
            />
            {validator.current &&
              validator.current.message(
                "Company Name",
                formData?.company_name,
                "string|max:100|min:3",
                {
                  className:
                    "text-danger simple-react-validator-errors text-left",
                }
              )}
          </div>
        </div>

        <button
          className="primary-btn w-100 mt-4"
          onClick={handleSubmit}
          disabled={btnLoader}
        >
          {btnLoader ? (
            <CircularProgress
              thickness={6.6}
              style={{
                width: "20px",
                height: "20px",
                color: "white",
              }}
            />
          ) : (
            "Share Contact"
          )}
        </button>
        <button
          className="secondary-btn w-100 mt-3"
          onClick={() => {
            setFormData({ profile: id });
            handleCloseModal();
          }}
        >
          Close
        </button>
      </Box>
    </Modal>
  );
};

export default ShareProfileModal;
