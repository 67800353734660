import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FormControl, OutlinedInput, InputAdornment } from "@mui/material";
import { MdSearch } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";
import { getProviders } from "../Redux/Actions/userProfileAction";
import Loader from "./loader/Loader";

const CommanNetworkAccounts = ({
  activeAccount,
  setActiveAccount,
  view,
  selectedLinks,
}) => {
  const dispatch = useDispatch();
  const providers = useSelector((state) => state.userProfileReducer.providers);
  const [loader, setLoader] = useState(true);
  const [providerData, setProviderData] = useState([]);
  const [cloneProvider, setCloneProvider] = useState([]);
  useEffect(() => {
    dispatch(getProviders(() => setLoader(false)));
  }, [dispatch]);

  useEffect(() => {
    providers.sort((a, b) => a.title.localeCompare(b.title));
    if (view == "reviewLinks") {
      const AvailbelSocailNetwork = [
        "BBB",
        "Facebook",
        "Google-My-Business",
        "Yelp",
      ];
      const networkHavingLinks = selectedLinks
        ?.filter((item) => AvailbelSocailNetwork?.includes(item.provider.title))
        .map((item) => item.provider.title);
      const networkWithoutLinks = AvailbelSocailNetwork?.filter((item) => {
        if (networkHavingLinks?.includes(item)) return null;
        else return item;
      });
      const filterProviders = providers.filter((acc) =>
        networkWithoutLinks.includes(acc.title)
      );
      setCloneProvider(filterProviders);
      setProviderData(filterProviders);
    } else {
      const selectedValue = selectedLinks?.map((vl) => vl.provider.title);
      const filterProviders = providers?.filter(
        (vl) => !selectedValue?.includes(vl.title)
      );
      setProviderData(filterProviders);
      setCloneProvider(filterProviders);
    }
  }, [providers]);

  const handleSearch = (searchedVal) => {
    const providerSearchedValue = cloneProvider?.filter(
      (itm) =>
        itm.title.includes(searchedVal) ||
        itm.title.includes(
          searchedVal.charAt(0).toUpperCase() + searchedVal.slice(1)
        )
    );
    setProviderData(providerSearchedValue);
  };
  const messageText = loader ? (
    <p className=" text-center">searching for availabel account.</p>
  ) : (
    <p className=" text-center">All accounts are in use.</p>
  );

  return (
    <>
      <div className="mb-4 px-md-3">
        <div className="mb-3">
          <FormControl className="mx-0" fullWidth sx={{ m: 1 }}>
            <OutlinedInput
              id="outlined-adornment-amount"
              startAdornment={
                <InputAdornment position="start">
                  <MdSearch />
                </InputAdornment>
              }
              placeholder="Search Social Networks"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </FormControl>
        </div>
        <div className="add-account-list">
          <Row>
            {loader && (
              <div className="text-center">
                <Loader />
              </div>
            )}
            {providerData.length > 0
              ? providerData.map((acc) => (
                  <Col md={6} key={acc.id}>
                    <div
                      className={`add-account-card ${
                        activeAccount.title === acc.title ? "active" : ""
                      }`}
                      onClick={() =>
                        setActiveAccount({
                          ...activeAccount,
                          title: acc.title,
                          imgUrl: acc.icon,
                          provider: acc.id,
                        })
                      }
                    >
                      <span className="me-3">
                        <img
                          className="social_network-icon"
                          src={acc.icon}
                          alt="icon"
                        ></img>
                      </span>
                      <p>{acc.title}</p>
                    </div>
                  </Col>
                ))
              : messageText}
          </Row>
        </div>
      </div>
    </>
  );
};

export default CommanNetworkAccounts;
