import { toast } from "react-toastify";
import { authAxios } from "../../config";

export const getNotification = (id, callback, loader) => (dispatch) => {
  authAxios
    .get(`user-settings/${id}/`)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        dispatch({
          type: "GET_NOTIFICATION",
          payload: res.data,
        });
        callback(res.data);
        loader();
      }
    })
    .catch((error) => {
      loader();
      toast.error("Something went wrong");
    });
};

export const updateNotification = (formData, id, loader) => (dispatch) => {
  authAxios
    .patch(`user-settings/${id}/`, formData)
    .then((res) => {
      dispatch(getNotification(id,loader,loader));
      if (res.status === 200 || res.status === 201) {
        toast.success("Setting updated successfully");
        loader();
      }
    })
    .catch((error) => {
      loader();
      toast.error("Something went wrong");
    });
};
