import React from "react";
import { Alert } from "react-bootstrap";

const ManageProfileAlert = ({ setShowAlert }) => {
  return (
    <Alert variant="light" onClose={() => setShowAlert(false)} dismissible>
      <Alert.Heading className="profile-card-heading">
        Switch Profile
      </Alert.Heading>
      <p>
        Your Cards are linked to your LIVE profile. To switch your profile,
        click into the check button.
      </p>
    </Alert>
  );
};

export default ManageProfileAlert;
