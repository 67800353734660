import Iframe from "./Iframe";

const VideoContent = ({ url, description, type }) => {
  return (
    <>
      <div className="mb-3">
        <h2 className="card-heading">Videos</h2>
      </div>
      <div className="me-2">
        <div className="mb-4">
          <Iframe url={url} type={type} />
          <p className="video-para">{description}</p>
        </div>
      </div>
    </>
  );
};

export default VideoContent;
