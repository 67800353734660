import React, { useState } from "react";
import {
  MdKeyboardBackspace,
  MdOutlineCall,
  MdOutlineEmail,
} from "react-icons/md";
import { BsFilterLeft } from "react-icons/bs";
import {
  MenuItem,
  TextField,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  Menu,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import { styled, Container } from "@mui/material";
import { TfiArrowCircleDown } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  deleteConnection,
  getConnection,
} from "../Redux/Actions/contactAction";
import { useNavigate } from "react-router";
import DeleteModal from "../common/DeleteModal";
import { getUsers } from "../Redux/Actions/userProfileAction";
import vCard from "vcards-js";
import { timeSince } from "../helper/Helper";
import { FiClock } from "react-icons/fi";
import { FaUser } from "react-icons/fa";
import Loader from "../common/loader/Loader";

const ContactDetail = () => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({
    id: "",
    name: "",
  });
  const [showModal, setShowModal] = useState({
    id: "",
    show: false,
  });
  const [loading, setLoading] = useState(true);
  const [modalBtnLoader, setModalBtnLoader] = useState(false);
  const [dropDownLoader, setDropDownLoader] = useState(false);
  const [selected, setSelected] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchedText, setSearchText] = useState("");
  const [afterSearchValue, setAfterSearchValue] = useState([]);
  const [sortBy, setSortBy] = useState("Name");

  const navigate = useNavigate();
  let connection = useSelector((state) => state.userProfileReducer.connection);
  const [prifileListVal, setProfileListVal] = useState([]);
  const [connectionList, setConnectionList] = useState([]);
  const option = ["Name", "Newest", "Oldest"];

  const profileList = useSelector((state) => state.userProfileReducer.user);
  useEffect(() => {
    dispatch(getUsers(() => setLoading(false)));
  }, [dispatch]);

  var vcf = vCard();

  useEffect(() => {
    if (profileList.length > 0) {
      setProfile({ id: profileList[0].id, name: profileList[0].profile_name });
    }
    setProfileListVal(profileList);
  }, [profileList]);

  useEffect(() => {
    if (profile.id && profile.id !== "") {
      setDropDownLoader(true);
      dispatch(getConnection(profile.id, () => setDropDownLoader(false)));
    }
  }, [profile, dispatch]);

  useEffect(() => {
    handleSorting("Name");
  }, [connection]);

  const handleChange = (e) => {
    if (profile.id !== e.target.value) {
      const i = profileList?.findIndex((itm) => itm.id === e.target.value);
      setProfile({ id: e.target.value, name: profileList[i].profile_name });
    }
  };

  const sortFunction = (value, connectionSortBy) => {
    if (value === "Name") {
      connectionSortBy.sort((a, b) => a.name.localeCompare(b.name));
    } else if (value === "Newest") {
      connectionSortBy.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    } else {
      connectionSortBy.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    }
  };

  const handleSorting = (value) => {
    let connectionValue = [];
    if (searchedText && searchedText.length > 0) {
      connectionValue = [...afterSearchValue];
    } else {
      connectionValue = [...connection];
    }
    sortFunction(value, connectionValue);
    const result = groupingAlphabetically(connectionValue);
    setConnectionList(result);
  };
  const searchConnection = (searchedVal) => {
    setSearchText(searchedVal);
    const connectionSearchedValue = connection.filter(
      (itm) => itm.name.includes(searchedVal) || itm.email.includes(searchedVal) || itm?.company_name?.includes(searchedVal)
    );
    setAfterSearchValue(connectionSearchedValue);
    sortFunction(sortBy, connectionSearchedValue);
    const result = groupingAlphabetically(connectionSearchedValue);
    setConnectionList(result);
  };

  const handleConfirmDelete = () => {
    dispatch(
      deleteConnection({
        id: showModal.id,
        callback: () => {
          setModalBtnLoader(false);
          setShowModal({ show: false });
        },
      })
    );
  };

  const chnageAccordiansId = (indx, i) => {
    const connectionValues = [...connectionList];
    connectionValues[indx].record[i].isOpen =
      !connectionValues[indx].record[i].isOpen;
    setConnectionList(connectionValues);
  };

  const downloadVCFfile = (conn, index, i) => {
    vcf.firstName = conn?.name;
    vcf.photo.base64 = true;
    vcf.photo.mediaType = "jpg";
    vcf.cellPhone = conn.contact_number;
    vcf.email = conn?.email;
    vcf.version = "3.0";
    const linkElement = document.createElement("a");
    linkElement.setAttribute("href", `data:,${vcf.getFormattedString()}`);
    linkElement.setAttribute("download", `${conn?.name}.vcf`);
    linkElement.style.display = "none";
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
    chnageAccordiansId(index, i);
  };

  const groupingAlphabetically = (connectionVal) => {
    let groupedValue = connectionVal.reduce((value, itm) => {
      let alphabet = itm.name[0];
      if (!value[alphabet]) value[alphabet] = { alphabet, record: [itm] };
      else value[alphabet].record.push(itm);
      return value;
    }, {});
    return Object.values(groupedValue);
  };
  const show = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `0px solid ${theme.palette.divider}`,
    background: `#0000`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  return (
    <>
      <Container className="mt-10">
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <Loader />
          </Backdrop>
        ) : (
          <div className="view-card-area">
            <div className="">
              <div className="d-flex justify-content-between align-items-center">
                <MdKeyboardBackspace
                  className="me-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/profile-edit-view")}
                />

                <div>
                  <List
                    component="nav"
                    className="py-0 rounded-lg"
                    aria-label="Device settings"
                    sx={{ bgcolor: "background.paper" }}
                  >
                    <ListItem
                      button
                      id="lock-button"
                      className="px-3"
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-label="when device is locked"
                      aria-expanded={show ? "true" : undefined}
                      onClick={handleClickListItem}
                    >
                      <ListItemText primary={option[selected]} />
                      <BsFilterLeft className="ms-2" />
                    </ListItem>
                  </List>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    open={show}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "lock-button",
                      role: "listbox",
                    }}
                  >
                    {option.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selected}
                        onClick={(e) => {
                          handleSorting(option);
                          setSelected(index);
                          setAnchorEl(null);
                          setSortBy(option);
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </div>
              <div className="mt-4">
                <h2 className="profile-card-heading mb-4">
                  Connections{" "}
                  <span className="">({connection.length})</span>
                </h2>
              </div>
              <div className="mt-4">
                <div className="mb-4">
                  <TextField
                    id="outlined-select-currency"
                    select
                    fullWidth
                    label="Select Profile"
                    onChange={(e) => handleChange(e)}
                    defaultValue={profile.id}
                    disabled={dropDownLoader}
                  >
                    {prifileListVal.length > 0 ? (
                      prifileListVal.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          name={option.profile_name}
                        >
                          {option.profile_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No profile available</MenuItem>
                    )}
                  </TextField>
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="Search"
                    onChange={(e) => searchConnection(e.target.value)}
                    name="first_name"
                    value={searchedText}
                  />
                </div>
                {dropDownLoader ? (
                  <div className="text-center">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {connectionList.length === 0 ? (
                      <div>No connections Found</div>
                    ) : (
                      connectionList.map((groupedValue, index) => (
                        <div className="mb-4" key={groupedValue.alphabet}>
                          <h1 className="groupAlphabet">
                            {groupedValue.alphabet}
                          </h1>
                          {groupedValue?.record?.map((conn, i) => (
                            <div className="card-2 mb-3 p-0" key={conn.id}>
                              <Accordion
                                expanded={conn.isOpen}
                                onChange={() => chnageAccordiansId(index, i)}
                              >
                                <AccordionSummary
                                  expandIcon={<TfiArrowCircleDown />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="sn-card-title">
                                      <div className="me-2">
                                        <h4 className="social-n-title">
                                          {conn.name} {conn.company_name && <span className="">({conn.company_name})</span>}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="px-md-5 px-2">
                                    <div className="d-flex align-items-center mb-3">
                                      <MdOutlineCall className="coantact-e-icon" />{" "}
                                      <span className="primary-text ms-3 text-break">
                                        {conn.contact_number}
                                      </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                      <MdOutlineEmail className="coantact-e-icon" />{" "}
                                      <span className="primary-text ms-3 text-break">
                                        {conn.email}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between mb-4 px-3">
                                    <span>
                                      <FiClock className="me-2" />
                                      <strong>
                                        {timeSince(new Date(conn.created_at))}
                                      </strong>
                                    </span>
                                    <span>
                                      <FaUser className="me-2" />
                                      <strong>/{profile.name}</strong>
                                    </span>
                                  </div>

                                  <div className="text-center">
                                    <button
                                      className="secondary-btn mx-1 mb-3"
                                      onClick={() =>
                                        setShowModal({
                                          show: true,
                                          id: conn.id,
                                        })
                                      }
                                    >
                                      Delete
                                    </button>
                                    <button
                                      className="primary-btn mx-1 mb-3"
                                      onClick={() =>
                                        downloadVCFfile(conn, index, i)
                                      }
                                    >
                                      Save
                                    </button>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          ))}
                        </div>
                      ))
                    )}
                  </>
                )}
              </div>

              {/* <button className="primary-btn w-100">Submit</button> */}
            </div>
            {showModal.show && (
              <DeleteModal
                show={showModal.show}
                setShow={setShowModal}
                handleDeleteProfile={handleConfirmDelete}
                modalBtnLoader={modalBtnLoader}
                setModalBtnLoader={setModalBtnLoader}
              />
            )}
          </div>
        )}
      </Container>
    </>
  );
};

export default ContactDetail;
