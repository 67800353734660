import React, { useState, useEffect, useRef } from "react";
import Drawer from "@mui/material/Drawer";
import { CircularProgress, TextField } from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import {
  getActiveUserProfile,
  updateUser,
} from "../Redux/Actions/userProfileAction";
import { useDispatch } from "react-redux";

export default function MuiAboutDrawer({
  open,
  handleShowAboutEditModal,
  userData,
  aboutUs,
  setAboutUs,
}) {
  // const [aboutUs, setAboutUs] = useState();
  const [value, setValue] = useState(0);
  const validator = useRef(new SimpleReactValidator());
  const [isValid, setIsValid] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) {
      setAboutUs(userData.bio_details?.replace(/\s+/g,' ').trim());
    }
  }, [userData]);

  const forceUpdate = () => {
    setValue(value + 1);
  };

  const onChange=(e) =>{
    if (aboutUs.length === 0 && e.target.value === " ") {
      return;
    }
    if (e.target.value.length > 1000) {
      return;
    }
    setAboutUs(e.target.value)
  }

  const updateAboutUs = () => {
    if (validator.current.allValid()) {
      if (userData.bio_details === aboutUs) {
        handleShowAboutEditModal();
        return;
      }
      let fd = new FormData();
      fd.append("bio_details", aboutUs.replace(/\s+/g,' ').trim());
      setBtnLoader(true);
      dispatch(
        updateUser({
          loader: () => setBtnLoader(false),
          id: userData.id,
          payload: fd,
          callback: () => {
            // dispatch(getUsers());
            handleShowAboutEditModal();
            dispatch(getActiveUserProfile(() => setIsValid(true)));
          },
        })
      );
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleShowAboutEditModal}
      hideBackdrop
    >
      <div className="custom-modal">
        <h3 className="modal-heading py-4 px-md-5 px-3">Edit About Me</h3>
        <div className="modal-inner px-md-5 px-3 py-3 modal-scroll">
          <div className="px-md-5">
            <div className="my-5">
              <div className="mb-4">
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="About"
                  multiline
                  rows={5}
                  value={aboutUs}
                  onChange={onChange}
                  // defaultValue="As the President & CEO of Oamii Digital Marketing Agency, I’m the driving force behind our major expansion and diversification initiatives. Our agency provides a comprehensive range of website design, digital marketing, CRM integration, cloud computing, and software engineering services. "
                />

                <p className="carect-lenth">{aboutUs?.length}/1000</p>
                {validator.current &&
                  validator.current.message(
                    "About",
                    aboutUs,
                    "required|max:1000|min:3|string",
                    {
                      className:
                        "text-danger simple-react-validator-errors text-left",
                    }
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="modal-button text-center mt-4">
          <button
            className="secondary-btn mx-1 mb-3"
            onClick={() => {
              handleShowAboutEditModal();
              validator.current.hideMessages();
              setAboutUs();
            }}
          >
            Cancel
          </button>
          <button
            disabled={btnLoader}
            className="primary-btn mx-1"
            onClick={updateAboutUs}
          >
            {btnLoader ? (
              <CircularProgress
                thickness={6.6}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "white",
                }}
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </Drawer>
  );
}
