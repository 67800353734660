import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { Container } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

const AccountSetting = () => {
  const navigate = useNavigate();

  return (
    <>
    <Container className="mt-10">
      <div className="view-card-area">
        <div className="">
          <h2 className="profile-card-heading d-flex align-items-center">
            <MdKeyboardBackspace
              className="me-3"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/profile-edit-view")}
            />
            Setting
          </h2>
          <div className="mt-4">
            <button
              className="primary-btn w-100 mb-3"
              onClick={() => navigate("/change-credentials")}
            >
              <span className="d-flex align-items-center ">
                <img
                  className="setting-btn-icon"
                  src="../images/add_moderator.svg"
                  alt="icon"
                />
                <span className="ms-3">Change Credentials</span>
              </span>
            </button>
            <button className="primary-btn w-100 mb-3" onClick={()=> navigate('/notification')} >
              <span className="d-flex align-items-center ">
                <img
                  className="setting-btn-icon"
                  src="../images/edit_notifications.svg"
                  alt="icon"
                />
                <span className="ms-3">Notifications</span>
              </span>
            </button>
            <button className="primary-btn w-100 mb-3" onClick={()=>navigate('/personalise')}>
              <span className="d-flex align-items-center ">
                <img
                  className="setting-btn-icon"
                  src="../images/palette.svg"
                  alt="icon"
                />
                <span className="ms-3">Personalization</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      </Container>
    </>
  );
};

export default AccountSetting;
