import React, { useEffect } from "react";
import {
  MenuItem,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import { styled } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";
import { getCountryCodes } from "../Redux/Actions/userProfileAction";
import { useDispatch, useSelector } from "react-redux";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  background: "#0000",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const phoneType = [
  {
    value: "work",
    label: "Work",
  },
  {
    value: "personal",
    label: "Personal",
  },
  {
    value: "other",
    label: "Other",
  },
];

const ContactInfo = ({
  addEmailField,
  addressDetails,
  phoneDetails,
  websitesDetails,
  emailDetails,
  phoneHandleChange,
  emailHandleChange,
  addressHandleChange,
  websiteHandleChange,
  addAddressField,
  deleteAddressField,
  deleteEmailField,
  addPhoneField,
  addWebsiteField,
  deleteWebsiteField,
  deletePhoneField,
  validator,
  error,
}) => {
  const dispatch = useDispatch();
  const countryCodes = useSelector((state) =>
    state.userProfileReducer.country_code.map((item) => {
      return { label: item.country, value: item.country_code };
    })
  );
  const [Phoneexpanded, setPhoneExpanded] = React.useState(true);
  const [Emailexpanded, setEmailExpanded] = React.useState(true);
  const [WebSiteexpanded, setWebSiteExpanded] = React.useState(true);
  const [Addressexpanded, setAddressExpanded] = React.useState(true);

  useEffect(() => {
    dispatch(getCountryCodes());
  }, [dispatch]);

  return (
    <div className="mb-4 px-md-5">
      {/* Phone Accordian */}
      <Accordion
        expanded={Phoneexpanded}
        onChange={() => setPhoneExpanded(!Phoneexpanded)}
      >
        <AccordionSummary
          expandIcon={<FaAngleDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h5 className="contact-info-heading">Phone Number</h5>
        </AccordionSummary>
        {phoneDetails.map((pho, index) => {
          return (
            <AccordionDetails>
              <div>
                <div className="mb-4">
                  <TextField
                    id="outlined-select-currency"
                    fullWidth
                    label="Phone"
                    name="phone_type"
                    select
                    value={pho.phone_type}
                    onChange={(e) =>
                      phoneHandleChange(e.target.name, e.target.value, index)
                    }
                  >
                    {phoneType.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {validator.current &&
                    validator.current.message(
                      "Phone Type",
                      pho.phone_type,
                      "required",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                <div className="mb-4">
                  <TextField
                    id="outlined-select-currency"
                    select
                    fullWidth
                    label="Country Code"
                    name="country_code"
                    value={pho.country_code}
                    onChange={(e) =>
                      phoneHandleChange(e.target.name, e.target.value, index)
                    }
                  >
                    {countryCodes.map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {validator.current &&
                    validator.current.message(
                      "Country Code",
                      pho.country_code,
                      "required",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="Contact Number"
                    name="contact_number"
                    value={pho.contact_number}
                    onChange={(e) =>
                      phoneHandleChange(e.target.name, e.target.value, index)
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment className="me-0" position="start">
                          {pho.country_code}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {validator.current &&
                    validator.current.message(
                      "Contact Number",
                      pho.contact_number,
                      "required",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                  {error.show && (
                    <span className="text-danger simple-react-validator-errors text-left">
                      {error.msg}
                    </span>
                  )}
                </div>
                <div className="text-center">
                  <button
                    onClick={() =>
                      phoneDetails.length > 1 && deletePhoneField(index)
                    }
                    className="third-btn mx-1 mb-3"
                  >
                    Delete
                  </button>

                  <button
                    onClick={() => addPhoneField(index)}
                    className="third-btn mx-1 mb-3"
                  >
                    Add Number
                  </button>
                </div>
              </div>
            </AccordionDetails>
          );
        })}
      </Accordion>

      {/* Email Accordian */}
      <Accordion
        expanded={Emailexpanded}
        onChange={() => setEmailExpanded(!Emailexpanded)}
      >
        <AccordionSummary
          expandIcon={<FaAngleDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <h5 className="contact-info-heading">Email</h5>
        </AccordionSummary>
        {emailDetails.map((ema, index) => {
          return (
            <AccordionDetails>
              <div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    type="email"
                    id="outlined-required"
                    label="Email Address"
                    name="email"
                    value={ema.email}
                    onChange={(e) =>
                      emailHandleChange(e.target.name, e.target.value, index)
                    }
                  />
                  {validator.current &&
                    validator.current.message(
                      "Email Address",
                      ema.email,
                      "required|email|max:50",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                <div className="text-center">
                  <button
                    onClick={() =>
                      emailDetails.length > 1 && deleteEmailField(index)
                    }
                    className="third-btn mx-1 mb-3"
                  >
                    Delete
                  </button>

                  <button
                    onClick={() => addEmailField(index)}
                    className="third-btn mx-1 mb-3"
                  >
                    Add Email
                  </button>
                </div>
              </div>
            </AccordionDetails>
          );
        })}
      </Accordion>

      {/* WebSite Accordian */}
      <Accordion
        expanded={WebSiteexpanded}
        onChange={() => setWebSiteExpanded(!WebSiteexpanded)}
      >
        <AccordionSummary
          expandIcon={<FaAngleDown />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <h5 className="contact-info-heading">Website</h5>
        </AccordionSummary>
        {websitesDetails.map((web, index) => {
          return (
            <AccordionDetails>
              <div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    type="url"
                    id="outlined-required"
                    label="Website Address"
                    name="website"
                    value={web.website}
                    onChange={(e) =>
                      websiteHandleChange(e.target.name, e.target.value, index)
                    }
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment className="me-0" position="start">
                    //       http://
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                  {validator.current &&
                    validator.current.message(
                      "Website",
                      web.website,
                      "required|webSiteURLRegex",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                <div className="text-center">
                  <button
                    onClick={() =>
                      websitesDetails.length > 1 && deleteWebsiteField(index)
                    }
                    className="third-btn mx-1 mb-3"
                  >
                    Delete
                  </button>

                  <button
                    onClick={() => addWebsiteField(index)}
                    className="third-btn mx-1 mb-3"
                  >
                    Add Website
                  </button>
                </div>
              </div>
            </AccordionDetails>
          );
        })}
      </Accordion>

      {/* Address Accordian */}
      <Accordion
        expanded={Addressexpanded}
        onChange={() => setAddressExpanded(!Addressexpanded)}
      >
        <AccordionSummary
          expandIcon={<FaAngleDown />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <h5 className="contact-info-heading">Address</h5>
        </AccordionSummary>
        {addressDetails.map((addr, index) => {
          return (
            <AccordionDetails>
              <div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="outlined-required"
                    select
                    label="Address Type"
                    name="address_type"
                    value={addr.address_type}
                    onChange={(e) =>
                      addressHandleChange(e.target.name, e.target.value, index)
                    }
                  >
                    {[
                      { label: "Home", value: "Home" },
                      { label: "Office", value: "Office" },
                      { label: "Other", value: "Other" },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {validator.current &&
                    validator.current.message(
                      "Address Type",
                      addr.address_type,
                      "required|max:50",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="Street line one"
                    name="street_line_one"
                    value={addr.street_line_one}
                    onChange={(e) =>
                      addressHandleChange(e.target.name, e.target.value, index)
                    }
                  />
                  {validator.current &&
                    validator.current.message(
                      "Street line one",
                      addr.street_line_one,
                      "required|max:50",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="Street line two"
                    name="street_line_two"
                    value={addr.street_line_two}
                    onChange={(e) =>
                      addressHandleChange(e.target.name, e.target.value, index)
                    }
                  />
                  {validator.current &&
                    validator.current.message(
                      "Street line two",
                      addr.street_line_one,
                      "required|max:50",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="City"
                    name="city"
                    value={addr.city}
                    onChange={(e) =>
                      addressHandleChange(e.target.name, e.target.value, index)
                    }
                  />
                  {validator.current &&
                    validator.current.message(
                      "City",
                      addr.city,
                      "required|max:50",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="State / Province"
                    name="state"
                    value={addr.state}
                    onChange={(e) =>
                      addressHandleChange(e.target.name, e.target.value, index)
                    }
                  />
                  {validator.current &&
                    validator.current.message(
                      "State / Province",
                      addr.state,
                      "required|max:50",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="Postal Code"
                    name="postal_code"
                    value={addr.postal_code}
                    onChange={(e) =>
                      addressHandleChange(e.target.name, e.target.value, index)
                    }
                  />
                  {validator.current &&
                    validator.current.message(
                      "Postal Code",
                      addr.postal_code,
                      "required|numeric|max:50",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>

                <div className="mb-4">
                  <TextField
                    id="outlined-required"
                    select
                    fullWidth
                    label="Country"
                    name="country"
                    value={addr.country}
                    onChange={(e) =>
                      addressHandleChange(e.target.name, e.target.value, index)
                    }
                  >
                    {countryCodes.map((option, i) => (
                      <MenuItem key={i} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {validator.current &&
                    validator.current.message(
                      "Country",
                      addr.country,
                      "required",
                      {
                        className:
                          "text-danger simple-react-validator-errors text-left",
                      }
                    )}
                </div>

                <div className="text-center">
                  <button
                    onClick={() =>
                      addressDetails.length > 1 && deleteAddressField(index)
                    }
                    className="third-btn mx-1 mb-3"
                  >
                    Delete
                  </button>

                  <button
                    onClick={() => addAddressField(index)}
                    className="third-btn mx-1 mb-3"
                  >
                    Add Address
                  </button>
                </div>
              </div>
            </AccordionDetails>
          );
        })}
      </Accordion>
    </div>
  );
};

export default ContactInfo;
