import React from "react";
import { FaGlobeAmericas } from "react-icons/fa";
import { CheckWebsiteUrl } from "../functions/checkWebSiteUrl";
import { modifyWhatsappLink } from "../../helper/Helper";

const LinkImage = ({ website, item, color, textColor }) => {
  return (
    <>
      {!website ? (
        <a
          href={CheckWebsiteUrl(
            item?.provider?.title === "Whatsapp"
              ? modifyWhatsappLink(item.url)
              : item.url
          )}
          target="_blank"
          rel="noopener"
          className="social-icon"
        >
          <img
            className="social_icon"
            src={item.provider?.icon}
            alt={item.provider?.title}
          />
        </a>
      ) : (
        <div
          style={{ backgroundColor: color }}
          className="website-link-list mb-2 w-100"
          key={item.id}
        >
          <a
            href={CheckWebsiteUrl(item.url)}
            target="_blank"
            className="d-flex align-items-center text-light cursor-pointer websites_link"
          >
            <FaGlobeAmericas className="glob-icon me-3" />
            <p style={{ color: textColor }} className="wesite_name">
              {item?.meta?.title}
            </p>
          </a>
        </div>
      )}
    </>
  );
};

export default LinkImage;
