import React from "react";
import { TfiExchangeVertical } from "react-icons/tfi";

const UpDownIcon = () => {
  return (
    <div className="move-icon me-md-3 me-2">
      <TfiExchangeVertical />
    </div>
  );
};

export default UpDownIcon;
