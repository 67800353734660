import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import {
  MdContacts,
} from "react-icons/md";
import ShareProfileModal from "../common/ShareProfileModal";
import ShowMoreText from "react-show-more-text";
import axios from "axios";
import { axiosWithoutAuth, baseURL } from "../config";
import VCardFileCreator from "./CreateVcardFile";
import { toast } from "react-toastify";
import ClassicViewLinkCard from "../common/linkView/ClassicViewLinkCard";
import VideoContent from "../common/video";
import { GET_YOUR_CARD_LINK } from "../constants/constants";
import ClassicViewContactContainer from "./classicViewContainer/ClassicViewContactContainer";
import { useSelector } from "react-redux";

const ProfileViewClassic = ({ profileData, bgColor, textColor, remove }) => {
  const [opensharedetail, setOpenShaeDetail] = React.useState(false);
  const [btnLoader, setBtnLoader] = React.useState(false);
  const color = bgColor ? bgColor : "#1C1B1F";
  const childRef = useRef();
  const [countryCodes, setCountryCodes] = useState([]);
  const [editSection, setEditSection] = useState([]);
  const profileCardsPosition = useSelector(
    (state) => state.userProfileReducer.profileCardsPosition
  );
  useEffect(() => {
    axiosWithoutAuth
      .get("country-code")
      .then((res) => {
        let country_codes = res.data.map((item) => {
          return { label: item.country, value: item.country_code };
        });
        setCountryCodes(country_codes);
      })
      .catch((err) => {
        // toast.error("Something went wrong");
      });
  }, []);

  useEffect(() => {
      const sectionItem = [
        {
          id: "about me",
          com: (
            <div className="mt-4">
              <div className="card ">
                <div className="mb-3">
                  <h2 className="card-heading">About Me</h2>
                </div>
                <ShowMoreText
                  /* Default options */
                  lines={3}
                  more={
                    <span>
                      Show More<FaAngleDown className="ms-2"></FaAngleDown>
                    </span>
                  }
                  less={
                    <span>
                      Show Less<FaAngleUp className="ms-2"></FaAngleUp>
                    </span>
                  }
                  className="content-css"
                  anchorClass="show-more-less-clickable text-center d-block show-more-btn"
                  expanded={false}
                >
                  {profileData?.bio_details}
                </ShowMoreText>
              </div>
            </div>
          ),
          show: true,
        },
        {
          id: "social links",
          com: (
            <ClassicViewLinkCard
              reviewItem={filteredNetworks}
              website={false}
              heading={"Social channels"}
            />
          ),
          show: filteredNetworks && filteredNetworks?.length > 0,
        },
        {
          id: "review links",
          com: (
            <ClassicViewLinkCard
              reviewItem={filteredReviews}
              website={false}
              heading={"REVIEW LINKS"}
            />
          ),
          show: filteredReviews && filteredReviews?.length > 0,
        },
        {
          id: "website links",
          com: (
            <ClassicViewLinkCard
              reviewItem={filteredWebsite}
              website={true}
              heading={"WEBSITE LINKS"}
              color={color}
              textColor={textColor}
            />
          ),
          show: filteredWebsite && filteredWebsite?.length > 0,
        },
        {
          id: "videos",
          com: (
            <div className="">
              <div className="mt-4 w-100">
                <VideoContent
                  url={profileData?.video?.video_url}
                  description={profileData?.video?.video_description}
                  type={profileData?.video?.video_source}
                />
              </div>
            </div>
          ),
          show: profileData?.video !== null,
        },
        {
          id: "contact information",
          com: (
            <ClassicViewContactContainer
              profileData={profileData}
              textColor={textColor}
              color={color}
            />
          ),
          show: true,
        },
      ];
    if (profileCardsPosition.length > 0) {
      let postionValue = [];
          postionValue = profileCardsPosition;
          postionValue.sort((a, b) => a.position - b.position);
          const cloneSectionItem = [];
          postionValue.forEach((element) => {
            let i = sectionItem.findIndex((itm) => itm.id === element.name);
            cloneSectionItem.push(sectionItem[i]);
          });
          setEditSection(cloneSectionItem);
    }
    else {
          setEditSection(sectionItem);
    }
  }, [profileCardsPosition]);

  const shareDetail = () => {
    setOpenShaeDetail(true);
  };
  const handleCloseShareDetail = () => {
    setOpenShaeDetail(false);
  };
  const filteredWebsite = profileData?.links_set?.filter((item) => {
    return item.types === "website";
  });
  const filteredNetworks = profileData?.links_set?.filter((item) => {
    return item.types === "social";
  });
  const filteredReviews = profileData?.links_set?.filter((item) => {
    return item.types === "review";
  });
  const saveContact = () => {
    let data = {
      event: "save_contact",
      profile: profileData.id,
    };
    axios
      .post(`${baseURL}/user-profile/contact-save/`, data)
      .then((res) => {
        toast.success("Contact saved successfully.");
        childRef.current.generateVCFFile();
        setBtnLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Container>
        {!remove && (
          <VCardFileCreator profileData={profileData} ref={childRef} />
        )}
        <div className="view-card-area">
          {!remove && (
            <div className="bg-white p-3">
              <div className="d-flex align-items-center justify-content-between">
                <img className="site-logo" src="/images/Logo.svg" alt="logo" />
                <button
                  className="get-your-card"
                  onClick={() => (window.location.href = GET_YOUR_CARD_LINK)}
                >
                  Get Your Card
                </button>
              </div>
            </div>
          )}

          {/* profile pic view */}
          <div className="classic-card-view">
            <div style={{ backgroundColor: color }} className="edt-pft-bg">
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6 ps-md-0">
                  <h4
                    style={{ color: textColor }}
                    className="profile-user-name2 text-capitalize d-md-block d-none"
                  >
                    {" "}
                    {profileData.first_name} {profileData?.last_name}
                  </h4>
                </div>
              </div>
            </div>
            <div className="px-2 mb-4">
              <div className="">
                <div className="row">
                  <div className="col-md-6">
                    <div className="view-profile-pic-classic">
                      <img
                        className="classic-d-view"
                        src={
                          profileData?.profile_picture
                            ? `${profileData?.profile_picture}`
                            : "/images/dummyUser.jpg"
                        }
                        alt="profile-pic"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ps-md-0">
                    <div className="profile-name2 mb-5 mt-3">
                      <h4 className="profile-user-name2 text-capitalize d-md-none d-block text-dark ">
                        {" "}
                        {profileData?.first_name} {profileData?.last_name}
                      </h4>
                      <p className="profile-user-designation2 m-0">
                        <span className="text-capitalize">
                          {" "}
                          {profileData?.position}
                        </span>{" "}
                        at{" "}
                        <span className="text-capitalize">
                          {profileData?.company_name}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {editSection.map((ele) => ele.show && <>{ele.com}</>)}
            {!remove && (
              <div className="view-profile-btn">
                <div className="text-center">
                  <button
                    className="secondary-btn mx-1 mb-3"
                    disabled={btnLoader}
                    onClick={() => {
                      setBtnLoader(true);
                      saveContact();
                    }}
                  >
                    <MdContacts className="sv-contact-icon me-2" />
                    Save Contact
                  </button>
                  <button className="primary-btn mx-1" onClick={shareDetail}>
                    <img
                      className="share-contact-icon me-2"
                      src="/images/exchange-white.svg"
                      alt=""
                    />
                    Share Your Details
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>

      {opensharedetail && (
        <ShareProfileModal
          countryCodes={countryCodes}
          id={profileData?.id}
          opensharedetail={opensharedetail}
          handleCloseShareDetail={handleCloseShareDetail}
        />
      )}
    </>
  );
};

export default ProfileViewClassic;
