import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineModeEdit } from "react-icons/md";
import { TfiExchangeVertical } from "react-icons/tfi";

export default function CardWithoutImage({
  link,
  index,
  setShowModal,
  editWebsiteLink,
}) {
  return (
    <div className="d-flex align-items-center">
      <span className="cursor-pointer">
        <TfiExchangeVertical />
      </span>
      <div className="social-n-card ms-2">
        <div className="px-3">
          <h4 className="social-n-title">{link.meta.title}</h4>
          <p className="social-n-link">{link.url}</p>
        </div>
        <div className="d-flex align-items-center justify-content-center mx-2">
          <MdOutlineModeEdit
            className="ed-btn"
            onClick={() => editWebsiteLink(index)}
          />
          <FaRegTrashAlt
            className="ed-btn"
            onClick={() => setShowModal({ show: true, id: link.id })}
          />
        </div>
      </div>
    </div>
  );
}
