import { CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { MdKeyboardBackspace } from "react-icons/md";
import SimpleReactValidator from "simple-react-validator";
import ContactInfo from "../common/ContactInfo";
import { URL_REGEX } from "../regexValidation/RegexValidation";
import { mobileNoValidation } from "../helper/Helper";

const AddContactDetail = ({
  setStep,
  formData,
  setFormData,
  createProfile,
  addressDetails,
  setaddressDetails,
  emailDetails,
  setEmailDetails,
  websitesDetails,
  setwebsitesDetails,
  phoneDetails,
  setphoneDetails,
}) => {
  const validator = useRef(
    new SimpleReactValidator({
      validators: {
        webSiteURLRegex: {
          message: "The :attribute format is incorrect.",
          rule: (val, value, validator) => {
            return validator.helpers.testRegex(val, URL_REGEX);
          },
          required: true,
        },
      },
    })
  );

  const [value, setValue] = useState(0);
  const [error, setError] = useState({
    show: false,
    msg: "Enter valid phone number",
  });
  const forceUpdate = () => {
    setValue(value + 1);
  };
  // Formdata

  const [btnLoader, setBtnLoader] = useState(false);

  // Handle Change
  const addressHandleChange = (key, value, index) => {
    const items = [...addressDetails];
    items[index] = { ...items[index], [key]: value };
    setaddressDetails(items);
    setFormData({ ...formData, address: JSON.stringify(items) });
  };
  const websiteHandleChange = (key, value, index) => {
    const items = [...websitesDetails];
    items[index] = { ...items[index], [key]: value };
    setwebsitesDetails(items);
  };
  const emailHandleChange = (key, value, index) => {
    const items = [...emailDetails];
    items[index] = { ...items[index], [key]: value };
    setEmailDetails(items);
  };
  const phoneHandleChange = (key, value, index) => {
    if (key === "contact_number") {
      if (!mobileNoValidation(phoneDetails[index].country_code, value)) {
        setError({ ...error, show: true });
      } else {
        setError({ ...error, show: false });
      }
    }
    if (key === "country_code") {
      if (!mobileNoValidation(value, phoneDetails[index].contact_number)) {
        setError({ ...error, show: true });
      } else {
        setError({ ...error, show: false });
      }
    }
    const items = [...phoneDetails];
    items[index] = { ...items[index], [key]: value };
    setphoneDetails(items);
    setFormData({ ...formData, phone: JSON.stringify(items) });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      email: JSON.stringify(emailDetails.map((item) => item.email)),
    });
  }, [emailDetails]);

  useEffect(() => {
    setFormData({
      ...formData,
      website: JSON.stringify(websitesDetails.map((item) => item.website)),
    });
  }, [websitesDetails]);

  // Add & Remove Address Field
  const addAddressField = (index) => {
    if (
      validator.current.fieldValid("Address Type") &&
      validator.current.fieldValid("Street line one") &&
      validator.current.fieldValid("Street line two") &&
      validator.current.fieldValid("City") &&
      validator.current.fieldValid("State / Province") &&
      validator.current.fieldValid("Postal Code") &&
      validator.current.fieldValid("Country")
    ) {
      const arr = [...addressDetails];
      const addressField = {
        address_type: "",
        street_line_one: "",
        street_line_two: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      };
      arr.splice(index + 1, 0, addressField);
      setaddressDetails(arr);
      validator.current.hideMessageFor("Address Type");
      validator.current.hideMessageFor("Street line one");
      validator.current.hideMessageFor("Street line two");
      validator.current.hideMessageFor("City");
      validator.current.hideMessageFor("State / Province");
      validator.current.hideMessageFor("Country");
      validator.current.hideMessageFor("Postal Code");
    } else {
      validator.current.showMessageFor("Address Type");
      validator.current.showMessageFor("Street line one");
      validator.current.showMessageFor("Street line two");
      validator.current.showMessageFor("City");
      validator.current.showMessageFor("State / Province");
      validator.current.showMessageFor("Country");
      validator.current.showMessageFor("Postal Code");
      forceUpdate();
    }
  };
  const deleteAddressField = (index) => {
    const rows = [...addressDetails];
    rows.splice(index, 1);
    setaddressDetails(rows);
  };

  // Add & Remove Email Field
  const addEmailField = (index) => {
    if (validator.current.fieldValid("Email Address")) {
      const arr = [...emailDetails];
      const emailField = {
        email: "",
      };
      arr.splice(index + 1, 0, emailField);
      setEmailDetails(arr);
      validator.current.hideMessageFor("Email Address");
    } else {
      validator.current.showMessageFor("Email Address");
      forceUpdate();
    }
  };
  const deleteEmailField = (index) => {
    const rows = [...emailDetails];
    rows.splice(index, 1);
    setEmailDetails(rows);
  };

  // Add & Remove Phone Field
  const addPhoneField = (index) => {
    if (
      !mobileNoValidation(
        phoneDetails[index].country_code,
        phoneDetails[index].contact_number
      )
    ) {
      setError({ ...error, show: true });
      return;
    }
    if (
      validator.current.fieldValid("Phone Type") &&
      validator.current.fieldValid("Country Code") &&
      validator.current.fieldValid("Contact Number")
    ) {
      const arr = [...phoneDetails];
      const phoneField = {
        country_code: "",
        phone_type: "",
        contact_number: "",
      };
      arr.splice(index + 1, 0, phoneField);
      setphoneDetails(arr);
      validator.current.hideMessageFor("Phone Type");
      validator.current.hideMessageFor("Country Code");
      validator.current.hideMessageFor("Contact Number");
    } else {
      validator.current.showMessageFor("Phone Type");
      validator.current.showMessageFor("Country Code");
      validator.current.showMessageFor("Contact Number");
      forceUpdate();
    }
  };
  const deletePhoneField = (index) => {
    const rows = [...phoneDetails];
    rows.splice(index, 1);
    setphoneDetails(rows);
  };

  // Add & Remove Website Field
  const addWebsiteField = (index) => {
    if (validator.current.fieldValid("Website")) {
      const arr = [...websitesDetails];
      const websiteField = {
        website: "",
      };
      arr.splice(index + 1, 0, websiteField);
      setwebsitesDetails(arr);
      validator.current.hideMessageFor("Website");
    } else {
      validator.current.showMessageFor("Website");
      forceUpdate();
    }
  };
  const deleteWebsiteField = (index) => {
    const rows = [...websitesDetails];
    rows.splice(index, 1);
    setwebsitesDetails(rows);
  };

  const handleSubmit = () => {
    if (error.show) {
      return;
    }
    if (validator.current.allValid()) {
      setBtnLoader(true);
      createProfile(() => setBtnLoader(false));
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  return (
    <>
      <div className="view-card-area mt-10">
        <Container>
          <div className="create-profile-progress wp-100 mb-2"></div>
          <div className="">
            <h2 className="profile-card-heading d-flex align-items-center py-4">
              <MdKeyboardBackspace
                className="me-3"
                style={{ cursor: "pointer" }}
                onClick={() => setStep(1)}
              />
              Add your contact details
            </h2>
            <ContactInfo
              addEmailField={addEmailField}
              addressDetails={addressDetails}
              phoneDetails={phoneDetails}
              websitesDetails={websitesDetails}
              emailDetails={emailDetails}
              phoneHandleChange={phoneHandleChange}
              emailHandleChange={emailHandleChange}
              addressHandleChange={addressHandleChange}
              websiteHandleChange={websiteHandleChange}
              addAddressField={addAddressField}
              deleteAddressField={deleteAddressField}
              deleteEmailField={deleteEmailField}
              addPhoneField={addPhoneField}
              addWebsiteField={addWebsiteField}
              deleteWebsiteField={deleteWebsiteField}
              deletePhoneField={deletePhoneField}
              validator={validator}
              error={error}
            />
          </div>

          <button
            type="submit"
            disabled={btnLoader}
            className="primary-btn w-100"
            onClick={handleSubmit}
          >
            {btnLoader ? (
              <CircularProgress
                thickness={6.6}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "white",
                }}
              />
            ) : (
              "Submit"
            )}
          </button>
        </Container>
      </div>
    </>
  );
};

export default AddContactDetail;
