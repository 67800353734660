import React, { useRef, useState } from "react";
import { CircularProgress, TextField } from "@mui/material";
import { MdOutlineMailOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../Redux/Actions/userProfileAction";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [value, setValue] = useState(0);
  const [btnLoader, setBtnLoader] = useState(false);
  const validator = useRef(new SimpleReactValidator());

  const forceUpdate = () => {
    setValue(value + 1);
  };

  const forgetPassword = () => {
    if (validator.current.allValid()) {
      setBtnLoader(true);
      const payload = {
        email: email,
      };
      dispatch(
        forgotPassword(payload, () => {
          setBtnLoader(false);
        })
      );
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };
  return (
    <>
      <div className="login-wrap px-3">
        <div className="login-page">
          <div className="text-center">
            <img className="site-logo-main mb-5" src="/images/Logo.svg" alt="logo" />
            <h1 className="login-title pt-5">Forgot Password</h1>
            <p className="py-4">
              Enter your registered email address, mobile number, or username to
              change your account password.
            </p>
          </div>
          <div className="">
            <div className="mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                name="email"
                label="Enter your Email Address"
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <MdOutlineMailOutline position="end"></MdOutlineMailOutline>
                  ),
                }}
              />
                {validator.current &&
                validator.current.message(
                  "email",
                  email,
                  "required|email",
                  {
                    className:
                      "text-danger simple-react-validator-errors text-left",
                  }
                )}
              <button
                disabled={btnLoader}
                className="login-btn mt-4"
                onClick={forgetPassword}
              >
                {btnLoader ? (
                  <CircularProgress
                    thickness={6.6}
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "white",
                    }}
                  />
                ) : (
                  " Send Email"
                )}
              </button>
            </div>
            <div className="text-center mt-5">
              <p>
                Already have an account?
                <Link className="ms-2" to="/login">
                  <strong>Login</strong>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
