const CheckUrl = (url, type) => {
  if (type === "youtube") {
    if (url && url.indexOf("&") !== -1) {
      return url.slice(0, url?.indexOf("&")).replace("watch?v=", "embed/");
    } else if (url?.includes("https://youtu.be")) {
      return url?.replace("https://youtu.be", "https://www.youtube.com/embed");
    } else {
      return url?.replace("watch?v=", "embed/");
    }
  } else {
    if (url) {
      let urlSplit = url?.split("/");
      let video_id = urlSplit[urlSplit?.length - 1];
      return `https://player.vimeo.com/video/${video_id}`;
    }
  }
};

export default CheckUrl;
