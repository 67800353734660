import React, { useRef, useState } from "react";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { MdOutlineMailOutline } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { API } from "../config";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { GET_YOUR_CARD_LINK } from "../constants/constants";
import { errorMessageAutoCloseOff } from "../common/showToasterMessage";
import {
  SOMETHING_WENT_WRONG,
  WRONG_CREDITIONALS,
} from "../common/constants/toastMessage";

const Login = () => {
  const validator = useRef(new SimpleReactValidator());
  const [loginForm, SetLoginForm] = useState({
    username: "",
    password: "",
  });
  const [value, setValue] = useState(0);
  const [showPassword, setShowPassword] = React.useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const forceUpdate = () => {
    setValue(value + 1);
  };

  const loginUser = () => {
    if (validator.current.allValid()) {
      setBtnLoader(true);
      toast.dismiss();
      API.post("api-token/", loginForm)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("token", res.data.token);
            window.location.href = "/profile-edit-view";
            setBtnLoader(false);
          }
        })
        .catch((err) => {
          setBtnLoader(false);
          if (err.response.status === 400) {
            errorMessageAutoCloseOff(WRONG_CREDITIONALS);
          } else {
            errorMessageAutoCloseOff(SOMETHING_WENT_WRONG);
          }
        });
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const handleChange = (e) => {
    SetLoginForm({
      ...loginForm,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <div className="login-wrap px-3">
        <div className="login-page">
          <div className="text-center">
            <img
              className="site-logo-main mb-5"
              src="/images/Logo.svg"
              alt="logo"
            />
            <h1 className="login-title py-5">Login</h1>
          </div>
          <div className="">
            <div className="mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Enter Your Email Address"
                name="username"
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <MdOutlineMailOutline
                      position="end"
                      style={{ fontSize: "22px" }}
                    ></MdOutlineMailOutline>
                  ),
                }}
              />

              {validator.current &&
                validator.current.message(
                  "Email Address",
                  loginForm.username,
                  "required",
                  {
                    className:
                      "text-danger simple-react-validator-errors text-left",
                  }
                )}
            </div>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                name="password"
                onChange={handleChange}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <div className="mb-4">
              {validator.current &&
                validator.current.message(
                  "Password",
                  loginForm.password,
                  "required",
                  {
                    className:
                      "text-danger simple-react-validator-errors text-left",
                  }
                )}
            </div>
            <p className="forgotlink">
              <Link to="/forgot-password">Forgot Password ?</Link>
            </p>
            <button
              className="login-btn mt-4"
              onClick={loginUser}
              disabled={btnLoader}
            >
              {btnLoader ? (
                <CircularProgress
                  thickness={6.6}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "white",
                  }}
                />
              ) : (
                "Login"
              )}
            </button>
          </div>
          <div className="text-center mt-5">
            <p>
              No Account?
              <a className="ms-2" href={GET_YOUR_CARD_LINK}>
                <strong className="ms-2">Get Your Card</strong>
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
